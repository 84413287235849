const API_USER = {
  GET_ALL: '/admin/users',
  GET_DETAIL: '/admin/users/detail',
  GET_CONTENT: '/admin/users',
  RESTORE_USER: '/admin/users/restore',
  DELETE_USER: '/admin/users/delete',
  GET_ALL_REVENUES: '/admin/revenues/users',
  GET_USER_REVENUES: '/admin/revenues/user',
  GET_DOWNLOAD_CONTENT: '/admin/revenues/user/download-contents',
  GET_REVENUES_CLAIMS: '/admin/revenues/user/claims',
  CHANGE_EMAIL_REQUEST: '/admin/change-email/request',
  CHANGE_EMAIL_VERIFY: '/admin/change-email/verify',
  CHANGE_EMAIL_RESEND_CODE: '/admin/change-email/resend-code',
  CHANGE_EMAIL_CANCEL: '/admin/change-email/cancel',
  CHANGE_PASSWORD: '/admin/change-password',
  GET_REVENUES_SETTING_CURRENT: '/admin/revenues/setting/current',
  UPDATE_REVENUES_SETTING: '/admin/revenues/setting/update',
  UPDATE_REVENUES_SYSTEM_ADS: '/admin/revenues/update/system/ads-revenue',
  GET_REVENUES_SYSTEM_ADS: '/admin/revenues/system/ads-revenue',
  CREATE_MOD: '/admin/users/create-mod',
  EDIT_MOD: '/admin/users/update-mod',
  GET_TRANSFER_FEE: '/admin/system-setting/transfer-fee',
  UPDATE_TRANSFER_FEE: '/admin/system-setting/transfer-fee/update',
  GET_TAX_FEE: '/admin/system-setting/tax',
  UPDATE_TAX_FEE: '/admin/system-setting/tax/update',
};

export { API_USER };
