import React, { useCallback, useEffect, useState, KeyboardEvent } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import Title from 'components/title/title';
import ButtonCustomer from 'components/button';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import { getRevenues, exportCsvRequestClaim } from 'handler/revenue';
import Pagination from 'components/pagination';
import Table from './table';
import './index.scss';
import { IRequestRevenue, RequestRevenueStatus } from 'handler/revenue/typings';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { handleTextToJPOnlyText, saveFile } from 'utils';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const LIMIT = 10;

const UserRequest = () => {
  const dispatch = useAppDispatch();
  const [dataUserRequest, setDataUserRequest] = useState<IRequestRevenue[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [params, setParams] = useState<any>({
    page: 1,
    search_key: '',
    isGetAll: false,
    status: ' ',
    start_date: null,
    end_date: null,
  });

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setPage(1);
    setParams({ ...params, page: 1, status: event.target.value });
  };

  const handleChangeStartDate = (data: any) => {
    setPage(1);
    setParams({ ...params, page: 1, start_date: dayjs(data).format('YYYY-MM-DD') });
  };

  const handleChangeEndDate = (data: any) => {
    setPage(1);
    setParams({ ...params, page: 1, end_date: String(dayjs(data).format('YYYY-MM-DD')) });
  };

  const getRevenuesApi = useCallback(async () => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await getRevenues(
        params.page,
        LIMIT,
        params.search_key || search,
        params.status,
        params.start_date,
        params.end_date
      );

      setDataUserRequest(response?.data?.data);
      setTotal(response?.data?.total);
      setLastPage(response?.data?.last_page);
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  }, [dispatch, params.page, params.search_key, params.status, params.start_date, params.end_date]);

  useEffect(() => {
    getRevenuesApi();
  }, [getRevenuesApi, params]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
    await setSearch(params.search_key);
    await setParams({ ...params, ...{ page: newPage } });
  };

  const onChangeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClickSearch = (event?: React.KeyboardEvent<HTMLDivElement>) => {
    if (event?.key === 'Enter' || !event) {
      setPage(1);
      setParams({
        ...params,
        search_key: search,
        page: 1,
        status: params.status,
        start_date: params.start_date,
        end_date: params.end_date,
      });
    }
  };

  const onClickExport = async (event?: React.KeyboardEvent<HTMLDivElement>) => {
    const currentTime = new Date().toLocaleString().replace(',', '').replaceAll(' ', '_');
    const fileName = 'report_' + currentTime + '.csv';
    const dataExportRequestClaim = await exportCsvRequestClaim(
      search,
      params.status,
      params.start_date,
      params.end_date
    );
    saveFile(dataExportRequestClaim, fileName);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLImageElement>) => {
    e.preventDefault();
  };

  return (
    <div className="w-full h-main bg-white user-container">
      <div className="main-header">
        <Title title="引き出し申請一覧" />
        <div className="header-actions flex justify-end items-center ">
          <div className="flex gap-4 justify-end items-end">
            <div className="w-[200px] flex flex-col items-start">
              <label className="text-[13px] pb-1">開始日</label>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                <DatePicker
                  value={params.start_date}
                  inputFormat="YYYY/MM/DD"
                  onChange={(data: any) => {
                    handleChangeStartDate(data);
                  }}
                  maxDate={new Date()}
                  renderInput={(props: any) => <TextField onKeyDown={onKeyDown} {...props} />}
                />
              </LocalizationProvider>
            </div>
            <div className="w-[200px] flex flex-col items-start">
              <label className="text-[13px] pb-1">終了日</label>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                <DatePicker
                  value={params.end_date}
                  inputFormat="YYYY/MM/DD"
                  onChange={(data: any) => {
                    handleChangeEndDate(data);
                  }}
                  maxDate={new Date()}
                  renderInput={(props: any) => <TextField onKeyDown={onKeyDown} {...props} />}
                />
              </LocalizationProvider>
            </div>
            <div className="flex flex-col items-start">
              <label className="text-[13px] pb-1">スターテス</label>
              <Select
                value={params.status}
                onChange={handleChangeStatus}
                style={{ minWidth: '100px' }}
              >
                <MenuItem value=" ">全て</MenuItem>
                <MenuItem value={RequestRevenueStatus.PENDING}>
                  {handleTextToJPOnlyText(RequestRevenueStatus.PENDING)}
                </MenuItem>
                <MenuItem value={RequestRevenueStatus.IN_PROGRESS}>
                  {handleTextToJPOnlyText(RequestRevenueStatus.IN_PROGRESS)}
                </MenuItem>
                <MenuItem value={RequestRevenueStatus.COMPLETED}>
                  {handleTextToJPOnlyText(RequestRevenueStatus.COMPLETED)}
                </MenuItem>
                <MenuItem value={RequestRevenueStatus.REJECTED}>
                  {handleTextToJPOnlyText(RequestRevenueStatus.REJECTED)}
                </MenuItem>
                <MenuItem value={RequestRevenueStatus.CANCELED}>
                  {handleTextToJPOnlyText(RequestRevenueStatus.CANCELED)}
                </MenuItem>
              </Select>
            </div>

            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="w-[300px]"
              onChange={onChangeSearchKey}
              value={search}
              onKeyDown={(e) => onClickSearch(e)}
              placeholder="ユーザー名、質問など"
              sx={{
                input: {
                  '&::placeholder': {
                    fontSize: 13,
                  },
                },
              }}
            />
            <ButtonCustomer
              name="検索"
              size="medium"
              rounded={true}
              className="bg-blue text-white"
              onClick={() => onClickSearch()}
            />
            <ButtonCustomer
              name="Export data"
              size="medium"
              rounded={true}
              className="bg-blue text-white"
              onClick={() => onClickExport()}
            />
          </div>
        </div>
      </div>
      <div className="main-body">
        <Table dataUserRequest={dataUserRequest} onRestoreSuccess={() => getRevenuesApi()} />

        <div className="pagination">
          <Pagination
            limit={LIMIT}
            total={total}
            onChange={onChangePaginate}
            page={page}
            hidden={dataUserRequest.length === 0}
            lastPage={lastPage}
          />
        </div>
      </div>
    </div>
  );
};

export default UserRequest;
