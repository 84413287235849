import React, { useEffect, useState } from 'react';
import Title from 'components/title/title';
import { Select, MenuItem, TextField, InputAdornment, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getListCollections } from 'handler/collections';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import Table from './table';
import { ICollections } from 'handler/collections/typings';
import SearchIcon from '@mui/icons-material/Search';
import ButtonCustomer from 'components/button';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import Pagination from 'components/pagination';

interface Props {}
const Collections = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [dataCollections, setDataCollections] = useState<ICollections[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const limit = 10;

  const [sort, setSort] = useState<number>(-1);
  const [search, setSearch] = useState<string>('');

  const getListCollectionApi = async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response: any = await getListCollections(sort, search, page, limit);
      setDataCollections(response?.data?.data);
      setTotal(response?.data?.total);
      dispatch({ type: SET_LOADING, payload: false });
      setLastPage(response?.data?.last_page);
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  useEffect(() => {
    getListCollectionApi();
  }, [page, sort]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
  };

  const onDeleteSuccess = () => {
    if (page === 1) {
      getListCollectionApi();
      return;
    }
    setPage(1);
  };

  const onChangeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClickSearch = (event?: React.KeyboardEvent<HTMLDivElement>) => {
    if (event?.key === 'Enter' || !event) {
      setPage(1);
      getListCollectionApi();
    }
  };

  return (
    <div className="w-full h-main bg-white collections-container">
      <div className="main-header">
        <Title title="特集一覧" />
        <div className="header-actions flex justify-between items-center">
          <Select
            value={sort}
            onChange={(e: any) => {
              setSort(e.target.value);
              setPage(1);
            }}
            className="w-40"
          >
            <MenuItem value={-1}>全て</MenuItem>
            <MenuItem value={0}>特集</MenuItem>
            <MenuItem value={1}>常設特集</MenuItem>
          </Select>
          <div className="flex items-center gap-2">
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="w-[300px]"
              onChange={onChangeSearchKey}
              onKeyDown={(e) => onClickSearch(e)}
              value={search}
              placeholder="特集タイトルで検索してください"
              sx={{
                input: {
                  '&::placeholder': {
                    fontSize: 15,
                  },
                },
                marginRight: 1,
              }}
            />
            <ButtonCustomer
              name="検索"
              size="medium"
              rounded={true}
              className="bg-blue text-white"
              onClick={() => onClickSearch()}
            />

            <div>
              <Button
                variant="contained"
                className="w-32 h-[41px]"
                startIcon={<AddIcon />}
                color="primary"
                onClick={() => {
                  navigate('/create-collection');
                }}
              >
                新しい作成
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <Table dataCollections={dataCollections} onDeleteSuccess={onDeleteSuccess} />

        <div className="pagination">
          <Pagination
            limit={limit}
            total={total}
            onChange={onChangePaginate}
            page={page}
            hidden={dataCollections.length === 0}
            lastPage={lastPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Collections;
