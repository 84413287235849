import React, { useCallback, useEffect, useState } from 'react';
import Title from 'components/title/title';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import CreateBanner from './create';
import Item from './item';
import { IBanner, IUpdatePosition } from 'handler/banner/typing';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import { getListBanner, updatePosition } from 'handler/banner';
import { toast } from 'react-toastify';
import NoData from 'components/no-data';

const BannerSettings = (props: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [listBanner, setListBanner] = useState<IBanner[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState<any>();
  const [listBannerTemp, setListBannerTemp] = useState<IBanner[]>([]);
  const dispatch = useAppDispatch();

  const callListBanner = useCallback(async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response: any = await getListBanner();
      setListBanner(response?.data);
      setListBannerTemp(response?.data);
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  }, [dispatch]);

  useEffect(() => {
    callListBanner();
  }, [callListBanner]);

  const handleEdit = (id: number) => {
    setIsEdit(true);
    setOpenDialog(true);
    setDataEdit(listBanner.find((item) => item.id === id));
  };

  const onSuccessBanner = () => {
    setOpenDialog(false);
    setIsEdit(false);
    onReload();
  };

  const onReload = () => {
    callListBanner();
  };

  const reorder = (list: IBanner[], startIndex: number, endIndex: number): IBanner[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(listBanner, result.source.index, result.destination.index);

    setListBanner(quotes);
  };
  const onUpdatePosition = async () => {
    dispatch({ type: SET_LOADING, payload: true });
    const data: IUpdatePosition = {
      banner_ids: [],
    };
    for (let i = 0; i < listBanner.length; i++) {
      data.banner_ids.push(listBanner[i].id);
    }
    const response = await updatePosition(data);

    if (response) {
      setTimeout(() => {
        toast.success('位置の更新が成功しました。!');
        onReload();
        dispatch({ type: SET_LOADING, payload: false });
      }, 2000);

      return;
    }
  };

  const checkChangeOrNot = (): boolean => {
    let isChange = false;
    for (let i = 0; i < listBannerTemp.length; i++) {
      if (listBannerTemp[i].id !== listBanner[i].id) {
        isChange = true;
        break;
      }
    }
    return isChange;
  };
  return (
    <div className="w-full h-main">
      <div className="main-header">
        <Title title="バナー設定" subTitle="バナーサイト登録可能" />

        <div className="header-actions">
          <div className="flex justify-between">
            <p className="text-16">バナー一覧</p>
            <div className="space-x-2">
              <Button
                variant="contained"
                startIcon={<UpdateIcon />}
                color="primary"
                onClick={onUpdatePosition}
                disabled={!checkChangeOrNot()}
              >
                更新
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                color="primary"
                className="h-[41px]"
                onClick={() => {
                  setOpenDialog(true);
                  setDataEdit(null);
                  setIsEdit(false);
                }}
              >
                新しい作成
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {listBanner.map((item, indexA) => (
                  <Draggable draggableId={item.id.toString()} index={indexA} key={item.id}>
                    {(providedDrag) => (
                      <div
                        ref={providedDrag.innerRef}
                        {...providedDrag.draggableProps}
                        {...providedDrag.dragHandleProps}
                      >
                        <Item
                          imageUrl={item.image.path}
                          url={item.url}
                          id={item.id}
                          disabled_at={item.disabled_at}
                          onEdit={handleEdit}
                          onDeleteSuccess={onReload}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {listBanner.length === 0 && (
          <div>
            <NoData />
          </div>
        )}
      </div>

      <CreateBanner
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        isEdit={isEdit}
        dataEdit={dataEdit}
        successBanner={onSuccessBanner}
      />
    </div>
  );
};

export default BannerSettings;
