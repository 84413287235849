import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, RadioGroup, FormControlLabel, Radio, Select, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { IContent } from 'handler/user-management/typings';
import CropImage from 'components/image-crop';
import Title from 'components/title/title';
import ButtonCustomer from 'components/button';
import Tag from 'components/tag';
import { ICreateCollectionRequest } from 'handler/collections/typings';
import { createCollections, getCollectionDetail, updateCollections } from 'handler/collections';
import { uploadImage } from 'handler/banner';
import ContentList from './content-list';
import './index.scss';

const INPUT_MAX_LENGTH = 50;
const TIME_OUT = 1000;

function CreateCollection() {
  const locale = useLocation();
  const navigate = useNavigate();
  const id = locale.pathname.split('/')[2];

  const [image, setImage] = useState<File | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [tagAdd, setTagAdd] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [contents, setContents] = useState<IContent[]>([]);
  const [preImage, setPreImage] = useState<any>(null);
  const [prevImageId, setPrevImageId] = useState('');

  const [loading, setIsLoading] = useState(false);
  const [validateError, setValidateError] = useState('');

  const [collection, setCollection] = useState<ICreateCollectionRequest>({
    title: '',
    thumbnail: {
      extension: '',
      id: 0,
      name: '',
      path: '',
    },
    longterm: '0',
    content_ids: [],
    tags: [],
  });
  const [type, setType] = useState('mannual');

  useEffect(() => {
    if (id) {
      getContentDetailApi(id);
      setIsEdit(true);
    }
  }, [id]);

  const getContentDetailApi = async (id: string) => {
    setIsLoading(true);
    try {
      const res = await getCollectionDetail(id);
      setCollection(res.data);
      setPreImage(res.data.thumbnail.path);
      setPrevImageId(res.data.thumbnail.id);
      if (res.data.tags) {
        const array_tag = res.data.tags.split(',');
        setTags(array_tag);
        setType('hashtag');
      } else {
        setType('mannual');
        setContents(res.data.contents);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.string()
    .trim()
    .required()
    .max(INPUT_MAX_LENGTH, '50文字以内を入力してください。');

  const handleSubmit = async () => {
    setIsLoading(true);
    if (collection.title === '') {
      toast.error('Title is required');
      setIsLoading(false);
      return;
    }
    if (!isEdit && image === null) {
      toast.error('Thumbnail is required');
      setIsLoading(false);
      return;
    }
    if (type === 'hashtag' && tags.length === 0) {
      toast.error('Must have at least 1 tag');
      setIsLoading(false);
      return;
    }

    if (type === 'mannual' && contents.length === 0) {
      toast.error('Must have at least 1 content');
      setIsLoading(false);
      return;
    }

    try {
      if (!isEdit) {
        if (collection.thumbnail === null) {
          toast.error('Image is required');
          setIsLoading(false);
          return;
        }
        await onCreateCollections();
      } else {
        await onUpdateCollections();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onCreateCollections = async () => {
    const formDataImage = new FormData();
    formDataImage.append('type', 'collection');
    formDataImage.append('file', image as Blob);

    try {
      const resImage = await uploadImage(formDataImage);

      if (!resImage) {
        setIsLoading(false);
        return;
      }

      const formDataCollection = new FormData();
      formDataCollection.append('title', collection.title);
      formDataCollection.append('longterm', collection.longterm as string);
      formDataCollection.append('thumbnail_file_id', resImage.data.data.id);
      if (type === 'hashtag') {
        for (let i = 0; i < tags.length; i++) {
          formDataCollection.append(`tags[${i}]`, tags[i]);
        }
      } else {
        for (let i = 0; i < contents.length; i++) {
          formDataCollection.append(`content_ids[${i}]`, contents[i].id.toString());
        }
      }

      if (collection.title.trim().length === 0) {
        return;
      }
      validationSchema.validateSync(collection.title);

      await createCollections(formDataCollection);
      toast.success('特集が作成されました');

      setTimeout(() => {
        navigate('/collections');
        setIsLoading(false);
      }, TIME_OUT);
    } catch (error) {
      if (error instanceof Yup.ValidationError) setValidateError(error.message);
      setIsLoading(false);
      return;
    }
  };

  const onUpdateCollections = async () => {
    try {
      let imageId = prevImageId;

      if (image) {
        const formDataImage = new FormData();
        formDataImage.append('type', 'collection');
        formDataImage.append('file', image as Blob);
        const resImage = await uploadImage(formDataImage);
        imageId = resImage.data.data.id;
      }

      if (!imageId) {
        setIsLoading(false);
        return;
      }

      const form = new FormData();
      form.append('title', collection.title);
      form.append('longterm', collection.longterm as string);
      form.append('thumbnail_file_id', imageId);

      if (type === 'hashtag') {
        for (let i = 0; i < tags.length; i++) {
          form.append(`tags[${i}]`, tags[i]);
        }
      } else {
        for (let i = 0; i < contents.length; i++) {
          form.append(`content_ids[${i}]`, contents[i].id.toString());
        }
      }

      await updateCollections(form, id);
      toast.success('特集が更新されました。');

      setTimeout(() => {
        navigate('/collections');
      }, TIME_OUT);
    } catch (error) {
      if (error instanceof Yup.ValidationError) setValidateError(error.message);
      setIsLoading(false);
    }
  };

  const handleUploadFile = (file: File) => {
    setImage(file);
  };

  const onAddTag = () => {
    if (tagAdd.replace(/\s/g, '').length === 0) {
      return;
    }
    tags.push(tagAdd);
    setTagAdd('');
  };

  const removeTag = (index: number) => {
    tags.splice(index, 1);
    setTags([...tags]);
  };

  const handleAddContent = (contents: IContent[]) => {
    setContents(contents);
  };

  const handleDeleteContent = (content: IContent) => {
    setContents(contents.filter((item) => item.id !== content.id));
  };

  const checkDisable = () => {
    if (
      collection.title === '' ||
      (type === 'hashtag' && tags.length === 0) ||
      (type === 'mannual' && contents.length === 0) ||
      (!isEdit && !image) ||
      loading
    )
      return true;
    return false;
  };

  return (
    <div className="h-main p-4 collection-detail-container pr-20">
      <Title title={isEdit ? '特集編集' : '特集作成'} />
      <div className="py-4 text-left">
        <div className="space-y-4">
          <p className="mb-1 font-bold">特集サムネイル</p>
          <CropImage onSave={handleUploadFile} preImage={preImage} />
          <div>
            <p className="mb-2 font-bold">特集タイトル</p>
            <TextField
              id="title"
              fullWidth
              placeholder="タイトルを入力してください"
              onChange={(e) => {
                setCollection({ ...collection, title: e.target.value });
                if (validateError) {
                  setValidateError('');
                }
              }}
              value={collection.title}
              error={validateError !== ''}
              helperText={validateError !== '' ? validateError : ''}
            />
          </div>

          <div>
            <p className="mb-2 font-bold">特集タイプ</p>
            <RadioGroup
              row
              id="longterm"
              name="longterm"
              onChange={(e: any) => {
                setCollection({ ...collection, longterm: e.target.value });
              }}
              value={collection.longterm}
            >
              <FormControlLabel value="0" control={<Radio />} label="特集" />
              <FormControlLabel value="1" control={<Radio />} label="常設特集" />
            </RadioGroup>
          </div>
          <div>
            <p className="mb-2 font-bold">コンテンツ定義</p>
            <Select
              id="type"
              name="type"
              className="w-full"
              value={type}
              onChange={(e: any) => {
                setType(e.target.value as string);
              }}
            >
              <MenuItem value={'hashtag'}>ハッシュタグ利用</MenuItem>
              <MenuItem value={'mannual'}>コンテンツマニュアル追加</MenuItem>
            </Select>
          </div>
          <div>
            {type === 'hashtag' ? (
              <div className="">
                <div className="flex gap-2">
                  <TextField
                    value={tagAdd}
                    className="w-full"
                    onChange={(e) => {
                      setTagAdd(e.target.value);
                    }}
                    placeholder="Add hashtag"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onAddTag();
                      }
                    }}
                    disabled={tags.length >= 5}
                  />
                </div>

                <div className="flex gap-1 mt-3 flex-wrap">
                  {tags &&
                    tags.map((tag: string, index: number) => {
                      return (
                        <Tag
                          key={index}
                          name={tag}
                          onClick={() => {
                            removeTag(index);
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            ) : (
              <ContentList
                contents={contents}
                saveContent={handleAddContent}
                onDeleteContent={handleDeleteContent}
              />
            )}
          </div>
        </div>

        <div className="text-center mt-8 space-x-2">
          <ButtonCustomer
            onClick={() => {
              navigate(-1);
            }}
            rounded
            className="bg-red text-white"
          >
            キャンセル
          </ButtonCustomer>
          <ButtonCustomer
            onClick={handleSubmit}
            disabled={checkDisable()}
            rounded
            className="bg-blue text-white"
          >
            保存
          </ButtonCustomer>
        </div>
      </div>
    </div>
  );
}

export default CreateCollection;
