// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-container .MuiInputBase-input {
  padding: 9px 0px;
}

.span-cell {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1824px) {
  .span-cell {
    width: 56rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/inquiry-management/index.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;;AAIA;EACE,qBAAA;EACA,mBAAA;EACA,2BAAA;EACA,uBAAA;AADF;;AAIA;EACE;IACE,uBAAA;EADF;AACF","sourcesContent":[".user-container {\n  .MuiInputBase-input {\n    padding: 9px 0px;\n  }\n}\n\n.span-cell {\n  display: inline-block;\n  white-space: nowrap;\n  overflow: hidden !important;\n  text-overflow: ellipsis;\n}\n\n@media only screen and (min-width : 1824px) {\n  .span-cell {\n    width: 56rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
