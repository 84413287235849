import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
interface Props {
  name: string;
  onClick?: () => void;
  isShowIconClose?: boolean;
}
function Tag(props: Props) {
  const { name, onClick, isShowIconClose = true } = props;
  return (
    <div
      className={`px-2 flex items-center text-blue py-1 bg-grey relative w-fit  border border-blue bg-dark_blue`}
    >
      {name}
      {isShowIconClose && (
        <CloseIcon
          onClick={onClick}
          className="-right-2 cursor-pointer -top-2 hover:opacity-50"
          style={{ width: 20 }}
        />
      )}
    </div>
  );
}

export default Tag;
