import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Checkbox,
  Select,
  MenuItem,
} from '@mui/material';
import { IContent } from 'handler/user-management/typings';
import { NavLink } from 'react-router-dom';
import ContentType from 'components/content-type';
import { getListContentForCollection } from 'handler/content/intex';
import SearchIcon from '@mui/icons-material/Search';
import ButtonCustomer from 'components/button';
import NoData from 'components/no-data';
import Pagination from 'components/pagination';
import CONST_DATA from '../../constant/data';
interface Props {
  contentSelected?: IContent[];
  onSaveAdd: (contents: IContent[]) => void;
  onClose: () => void;
}

function ContentTable(props: Props) {
  const { contentSelected, onSaveAdd, onClose } = props;
  const [dataContent, setDataContent] = useState<IContent[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const limit = 10;
  const [total, setTotal] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(1);
  const [contentSelectedLocal, setContentSelectedLocal] = useState<IContent[]>([]);
  const [type, setType] = useState(-1);

  useEffect(() => {
    getListContentApi();
  }, [page, type]);

  useEffect(() => {
    setContentSelectedLocal(contentSelected || []);
  }, [contentSelected]);

  const getListContentApi = async () => {
    const response = await getListContentForCollection(searchKey, page, limit, type, 0);
    await setDataContent(response.data.data);
    setTotal(response?.data?.total);
    setLastPage(response?.data?.last_page);
  };

  const isSelected = (content: IContent) => {
    if (contentSelectedLocal) {
      return contentSelectedLocal.some((item) => item.id === content.id);
    }
    return false;
  };

  const onChangeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
  };

  const onClickSearch = (event?: React.KeyboardEvent<HTMLDivElement>) => {
    if (event?.key === 'Enter' || !event) {
      getListContentApi();
    }
  };

  return (
    <div className="content-table">
      <div className="flex justify-between items-center gap-2 mb-5">
        <div>
          <Select
            id="type"
            name="type"
            className="w-full"
            value={type}
            onChange={(e: any) => {
              setType(e.target.value as number);
              setPage(1);
            }}
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={0}>PDF</MenuItem>
            <MenuItem value={1}>MIDI</MenuItem>
            <MenuItem value={2}>AUDIO</MenuItem>
            <MenuItem value={3}>VIDEO</MenuItem>
          </Select>
        </div>
        <div>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            className="w-[300px]"
            onChange={onChangeSearchKey}
            onKeyDown={(e) => onClickSearch(e)}
            value={searchKey}
            placeholder={'コンテンツタイプ、コンテンツなど'}
            sx={{
              input: {
                '&::placeholder': {
                  fontSize: 13,
                },
              },
              marginRight: 1,
            }}
          />
          <ButtonCustomer
            name="検索"
            onClick={() => onClickSearch()}
            size="medium"
            rounded={true}
            className="bg-blue text-white"
          />
        </div>

        {/* <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          className="w-full mb-5"
          onChange={onChangeSearchKey}
          value={searchKey}
        /> */}
      </div>
      <Table>
        <TableHead>
          <TableRow className="flex">
            <TableCell>コンテンツコード</TableCell>
            <TableCell>メインカテゴリー</TableCell>
            <TableCell>サブカテゴリー</TableCell>
            <TableCell>コンテンツタイプ</TableCell>
            <TableCell>コンテンツ名</TableCell>
            <TableCell>オーナー</TableCell>
            <TableCell align="center">閲覧数</TableCell>
            <TableCell>選択</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataContent.length > 0 &&
            dataContent.map((content: IContent) => {
              const mainInCate = CONST_DATA.instrument_main_categories.find(
                (item) => item.id === content.instrument_main_category_id
              );
              const subInCate = CONST_DATA.instrument_sub_categories.find(
                (item) => item.id === content.instrument_sub_category_id
              );

              return (
                <TableRow key={content.id}>
                  <TableCell>{content.content_code}</TableCell>
                  <TableCell>{mainInCate?.ja_name}</TableCell>
                  <TableCell>{subInCate?.ja_name}</TableCell>
                  <TableCell>
                    <ContentType type={content.content_type} />
                  </TableCell>
                  <TableCell className="max-w-[250px] text-ellipsis overflow-hidden whitespace-nowrap">
                    <NavLink
                      className={'text-blue hover:underline font-bold'}
                      to={`/content-detail/` + content.id}
                      style={{
                        overflowWrap: 'anywhere',
                      }}
                    >
                      {content.display_name}
                    </NavLink>
                  </TableCell>
                  <TableCell className="max-w-[150px] text-ellipsis overflow-hidden whitespace-nowrap">
                    {content?.user?.name}
                  </TableCell>

                  <TableCell align="center">
                    {content.content_view ? content.content_view : 0}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={isSelected(content)}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setContentSelectedLocal([...contentSelectedLocal, content]);
                        } else {
                          setContentSelectedLocal(
                            contentSelectedLocal.filter((item) => item.id !== content.id)
                          );
                        }
                      }}
                      className="h-[28px]"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {dataContent.length === 0 && (
        <div>
          <NoData />
        </div>
      )}
      <div className="flex gap-2 justify-between mt-5 items-center pb-5">
        <Pagination
          limit={limit}
          total={total}
          page={page}
          hidden={dataContent.length === 0}
          onChange={onChangePaginate}
          lastPage={lastPage}
        />

        <div />

        {dataContent.length !== 0 && (
          <div className="space-x-2">
            <ButtonCustomer
              onClick={onClose}
              className="w-32 border-2 border-gray rounded-3xl"
              rounded
            >
              キャンセル
            </ButtonCustomer>
            <ButtonCustomer
              onClick={() => {
                onSaveAdd(contentSelectedLocal);
              }}
              rounded
              type="submit"
              className="w-32 rounded-3xl bg-main_1 text-white"
              disabled={contentSelectedLocal?.length === 0}
            >
              OK
            </ButtonCustomer>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContentTable;
