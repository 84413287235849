// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-customer .MuiSvgIcon-root {
  margin-bottom: 3px;
}
.button-customer:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/button/index.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEE;EACE,2CAAA;AAAJ","sourcesContent":[".button-customer {\n  .MuiSvgIcon-root {\n    margin-bottom: 3px;\n  }\n  &:hover {\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
