const CONTENT_API = {
  GET_CONTENT: '/admin/content/detail',
  GET_LIST: '/admin/content',
  DISABLE: '/admin/content/disable',
  ENABLE: '/admin/content/enable',
  UPDATE_COPYRIGHT_CONTENT: '/admin/content/update-copyright',
  GET_LIST_FOR_COLLECTION: '/admin/content/collection',
  POST_FILE_CSV: '/admin/content/upload',
};

export default CONTENT_API;
