import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import counters from '../features/counter/counterSlice';
import auth from 'stores/user/reducer';
import app from 'stores/app/reducer';
import { history } from 'utils';
// red

const rootReducer = combineReducers({
  router: connectRouter(history),
  counters,
  authSaga: auth,
  app,
});

export default rootReducer;
