// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__J6mDr {
  background-color: white;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.style_content__K8\\+eg {
  padding: 20px 0;
  text-align: left;
}
.style_content__K8\\+eg p {
  padding: 5px 0;
}

.style_formChangeEmail__UmahC {
  width: 350px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.style_formChangeEmail__UmahC .style_btnSubmit__AfurI {
  margin-top: 35px;
}
.style_formChangeEmail__UmahC .style_inputOldpass__P371a {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/change-password/style.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,YAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;AACJ;;AACA;EACI,eAAA;EACA,gBAAA;AAEJ;AADI;EACI,cAAA;AAGR;;AAAA;EACI,YAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;AAGJ;AAFI;EACI,gBAAA;AAIR;AAFI;EACI,gBAAA;AAIR","sourcesContent":[".container{\n    background-color: white;\n    height: 100%;\n    display: flex;\n    justify-content: flex-start;\n    flex-direction: column;\n}\n.content{\n    padding: 20px 0;\n    text-align: left;\n    p{\n        padding: 5px 0;\n    }\n}\n.formChangeEmail{\n    width: 350px;\n    display: flex;\n    justify-content: flex-start;       \n    flex-direction: column; \n    .btnSubmit{\n        margin-top: 35px;\n    }\n    .inputOldpass{\n        margin-top: 20px;\n    }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__J6mDr`,
	"content": `style_content__K8+eg`,
	"formChangeEmail": `style_formChangeEmail__UmahC`,
	"btnSubmit": `style_btnSubmit__AfurI`,
	"inputOldpass": `style_inputOldpass__P371a`
};
export default ___CSS_LOADER_EXPORT___;
