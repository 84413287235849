import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { AutoCompleteCustom } from 'components/AutoCompleteCustom';
import CKEditorCommon from 'components/CKEditorCommon';
import Card from 'components/card';
import { getTemplate, replyInquiry } from 'handler/inquiry';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';

type prop = {
  dataInquiry: any;
  replyAction: () => void;
  cancelAction: () => void;
};
type TemplateProp = { id: number; title: string; content: string; label?: string };

const INPUT_MAX_LENGTH = 1000;

function InquiryReply({ dataInquiry, cancelAction }: prop) {
  const [validateError, setValidateError] = useState<string | null>(null);
  const [valueEditor, setValueEditor] = useState('');
  const [title, setTitle] = useState<string>('');
  const [templates, setTemplates] = useState<TemplateProp[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [params, setParams] = useState<{ limit: number; page: number; search_key: string }>({
    limit: 10,
    page: 1,
    search_key: '',
  });
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await replyInquiry(dataInquiry.id, valueEditor);
      toast.success(`${dataInquiry.email} メールアドレスにメールが送信されました。`);
      setTimeout(() => {
        navigate('/inquiry');
      }, 1000);
    } catch (error: any) {
      toast.error(error?.response?.data?.error?.content || 'Something went wrong');
    }
  };

  const onGetTemplates = async () => {
    try {
      const result = await getTemplate(params);
      if (isSearch) {
        setTemplates(result.data.data);
      } else {
        setTemplates(templates.concat(result.data.data));
      }
      setPage(result.data.current_page);
      setHasMore(result.data.current_page <= result.data.last_page);
      setIsSearch(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.error?.content ?? '');
    }
  };

  useEffect(() => {
    onGetTemplates();
  }, [params]);

  const checkCanSubmit = () => {
    const dataLength = ignoreHtmlTags(valueEditor.replaceAll('<p>&nbsp;</p>', '')).length;
    return valueEditor.trim().length > 0 && dataLength < 10001;
  };

  const getContentTemplate = (id: number) => {
    const content = templates.find((t) => t.id === id);
    setTitle(content?.title ?? '');
    return content?.content ?? '';
  };

  const handleOptionChange = (event: any) => {
    setValueEditor(getContentTemplate(event));
  };

  const onLoadMoreTemplate = debounce(() => {
    setParams({
      ...params,
      page: page + 1,
    });
  }, 500);

  const onSearchTemplate = debounce((event) => {
    setIsSearch(true);
    setParams({
      ...params,
      page: 1,
      search_key: event,
    });
  }, 500);

  const ignoreHtmlTags = (inputString: string) => {
    var tempElement = document.createElement('div');
    tempElement.innerHTML = inputString;
    return tempElement.textContent || tempElement.innerText;
  };

  const handleDataContent = (data: string) => {
    const dataLength = ignoreHtmlTags(data.replaceAll('<p>&nbsp;</p>', '')).length;
    if (dataLength > 10000) {
      setValidateError('半角10000文字以内で入力してください。');
    } else {
      setValidateError(null);
    }
    setValueEditor(data);
  };

  return (
    <>
      <Card>
        <div className={`mt-1 py-1`}>
          <p className="text-18 font-medium mb-2">受取人</p>
          <div className="text-18 font-medium p-[10px] rounded-[5px] bg-[#D9D9D9A3]/60 border border-[#C4C4C4]">
            {dataInquiry.email}
          </div>
        </div>
      </Card>

      <Card className="mt-[16px]">
        <div>
          <div className="flex">
            <p className="text-18 font-medium mb-2">テンプレート</p>
          </div>

          <div className="mb-2">
            <AutoCompleteCustom
              options={templates}
              next={onLoadMoreTemplate}
              hasMore={hasMore}
              onClickOption={handleOptionChange}
              onChange={onSearchTemplate}
              value={title}
              placeholder="テンプレート"
              setValue={setTitle}
            />
          </div>
          <div className="flex">
            <p className="text-18 font-medium mb-2">回答</p>
            <span className="text-red ml-1">*</span>
          </div>

          {/* <TextField
            placeholder="回答を入力してくください"
            id="answer"
            name="answer"
            multiline
            minRows={6}
            fullWidth
            onChange={(e) => {
              setAnswer(e.target.value);
              if (validateError) {
                setValidateError('');
              }
            }}
            value={answer}
            error={validateError !== ''}
            helperText={validateError !== '' ? validateError : ''}
          /> */}
          <CKEditorCommon
            valueEditor={valueEditor}
            onHandleData={(data) => handleDataContent(data)}
          />
          {validateError && <p className="text-red text-[12px] mt-1">{validateError}</p>}
        </div>
      </Card>

      <div className="flex justify-center mt-10">
        <div className="mr-10">
          <button
            className="border-[#2E253D]/20 border p-[5px] w-[100px] rounded-[8px]"
            onClick={cancelAction}
          >
            <span>キャンセル</span>
          </button>
        </div>
        <div>
          <button
            className={`bg-[#585EF4] p-[5px] rounded-[8px] w-[100px] ${
              checkCanSubmit() ? '' : 'opacity-50 cursor-not-allowed'
            }`}
            onClick={onSubmit}
            disabled={!checkCanSubmit() || isSubmitting}
          >
            <span className="text-white">送信</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default InquiryReply;
