import React from 'react';

const PublicRouters = ({ component: Component, ...rest }: any) => {
  return (
    <React.Fragment>
      <div className="pt-24">
        <Component />
      </div>
    </React.Fragment>
  );
};

export default PublicRouters;
