// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__tsP05 {
  background-color: white;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.style_content__aIjDD {
  padding: 20px 0;
  text-align: left;
}
.style_content__aIjDD p {
  padding: 5px 0;
}

.style_formChangeEmail__Of5kF {
  width: 350px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.style_formChangeEmail__Of5kF .style_btnSubmit__\\+xkqM {
  margin-top: 35px;
}
.style_formChangeEmail__Of5kF .style_boxInputCode__6ZU2t {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.style_formChangeEmail__Of5kF .style_boxInputCode__6ZU2t span {
  text-align: right;
  font-size: 14px;
  color: rgb(81, 81, 255);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/update-new-email/style.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,YAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;AACJ;;AACA;EACI,eAAA;EACA,gBAAA;AAEJ;AADI;EACI,cAAA;AAGR;;AAAA;EACI,YAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;AAGJ;AAFI;EACI,gBAAA;AAIR;AAAI;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;AAER;AADQ;EACI,iBAAA;EACA,eAAA;EACA,uBAAA;EACA,eAAA;AAGZ","sourcesContent":[".container{\n    background-color: white;\n    height: 100%;\n    display: flex;\n    justify-content: flex-start;\n    flex-direction: column;\n}\n.content{\n    padding: 20px 0;\n    text-align: left;\n    p{\n        padding: 5px 0;\n    }\n}\n.formChangeEmail{\n    width: 350px;\n    display: flex;\n    justify-content: flex-start;       \n    flex-direction: column; \n    .btnSubmit{\n        margin-top: 35px;\n    }\n    .inputCode{\n    }\n    .boxInputCode{\n        margin-top: 20px;\n        display: flex;\n        flex-direction: column;\n        span{\n            text-align: right;\n            font-size: 14px;\n            color: rgb(81, 81, 255);\n            cursor: pointer;\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__tsP05`,
	"content": `style_content__aIjDD`,
	"formChangeEmail": `style_formChangeEmail__Of5kF`,
	"btnSubmit": `style_btnSubmit__+xkqM`,
	"boxInputCode": `style_boxInputCode__6ZU2t`
};
export default ___CSS_LOADER_EXPORT___;
