import { API_USER_REVENUE } from 'constant/api/revenue';
import { IResponse } from 'models/common';
import ApiUtils from 'utils/api/api.utils';

export const getRevenues = async (
  page: number,
  limit: number,
  searchKey: string,
  status: string,
  start_date: string | null,
  end_date: string | null
) => {
  const params = {
    page: page,
    limit: limit,
    search_key: searchKey,
    status: status,
    start_date: start_date,
    end_date: end_date,
  };
  const response: IResponse = await ApiUtils.get(API_USER_REVENUE.GET_ALL, params, {
    isAuth: true,
  });

  return response?.data;
};

export const exportCsvRequestClaim = async (
  searchKey: string,
  status: string,
  start_date: string | null,
  end_date: string | null
) => {
  const params = {
    search_key: searchKey,
    status: status,
    start_date: start_date,
    end_date: end_date,
  };
  const response: IResponse = await ApiUtils.get(API_USER_REVENUE.EXPORT_REQUEST_CLAIM, params, {
    isAuth: true,
  });

  return response?.data;
};

export const putChangeStatusToInProgress = async (ids: number[], reason?: string) => {
  const params = {
    ids,
    reason,
  };
  const response: IResponse = await ApiUtils.put(API_USER_REVENUE.PUT_STATUS_IN_PROGRESS, params, {
    isAuth: true,
  });

  return response?.data;
};

export const putChangeStatusToPending = async (ids: number[], reason?: string) => {
  const params = {
    ids,
    reason,
  };
  const response: IResponse = await ApiUtils.put(API_USER_REVENUE.PUT_STATUS_PENDING, params, {
    isAuth: true,
  });

  return response?.data;
};

export const putChangeStatusToComplete = async (ids: number[], reason?: string) => {
  const params = {
    ids,
    reason,
  };
  const response: IResponse = await ApiUtils.put(API_USER_REVENUE.PUT_STATUS_COMPLETED, params, {
    isAuth: true,
  });

  return response?.data;
};

export const putChangeStatusToReject = async (ids: number[], reason: string) => {
  const params = {
    ids,
    reason: reason,
  };
  const response: IResponse = await ApiUtils.put(API_USER_REVENUE.PUT_STATUS_REJECT, params, {
    isAuth: true,
  });

  return response?.data;
};
