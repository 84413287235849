import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import moment from 'moment';
import * as Yup from 'yup';

import { IRequestRevenue, RequestRevenueStatus } from 'handler/revenue/typings';
import NoData from 'components/no-data';
import SimpleDialog from 'components/dialog-simple';
import {
  putChangeStatusToInProgress,
  putChangeStatusToComplete,
  putChangeStatusToReject,
  putChangeStatusToPending,
} from 'handler/revenue';
import ButtonCustomer from 'components/button';
import { handleTextToJP } from 'utils';

interface Props {
  dataUserRequest: IRequestRevenue[];
  onRestoreSuccess: () => void;
}

const statusColor = {
  pending: '#CA3031',
  in_progress: '#F1A200',
  completed: '#000000',
  rejected: '#8A8A8A',
  canceled: '#909090',
};

export enum Level {
  PENDING,
  IN_PROGRESS,
  COMPLETED,
  CANCELED,
  REJECTED,
  LIST,
}

const statusOptions = [
  {
    value: RequestRevenueStatus.PENDING,
    label: '保留中',
    level: Level.PENDING,
  },
  {
    value: RequestRevenueStatus.IN_PROGRESS,
    label: '処理中',
    level: Level.IN_PROGRESS,
  },
  {
    value: RequestRevenueStatus.COMPLETED,
    label: '引き出し済み',
    level: Level.COMPLETED,
  },
  {
    value: RequestRevenueStatus.CANCELED,
    label: 'キャンセル',
    level: Level.CANCELED,
  },
  {
    value: RequestRevenueStatus.REJECTED,
    label: '却下',
    level: Level.REJECTED,
  },
];

const statusOptionsSelect = [
  {
    value: RequestRevenueStatus.PENDING,
    label: '保留中',
  },
  {
    value: RequestRevenueStatus.IN_PROGRESS,
    label: '処理中',
  },
  {
    value: RequestRevenueStatus.COMPLETED,
    label: '引き出し済み',
  },
  {
    value: RequestRevenueStatus.REJECTED,
    label: '却下',
  },
];

const statusOptionsSelectMultiple = [
  {
    value: RequestRevenueStatus.COMPLETED,
    label: '引き出し済み',
  },
  // {
  //   value: RequestRevenueStatus.REJECTED,
  //   label: '却下',
  // },
];

const defaultSelected = {
  id: 0,
  value: '',
};

const INPUT_MAX_LENGTH = 255;

function TableUser(props: Props) {
  const { dataUserRequest } = props;
  const [openDialog, setOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<any>(defaultSelected);
  const [openDialogRejected, setOpenDialogRejected] = useState(false);
  const [reason, setReason] = useState('');
  const [validateError, setValidateError] = useState('');
  const disableChangeStatusList = [RequestRevenueStatus.CANCELED];

  const onChangeStatus = async (reason?: string) => {
    setOpen(false);
    switch (selectedRequest.value) {
      case handleTextToJP(RequestRevenueStatus.IN_PROGRESS):
        await putChangeStatusToInProgress(selectedRequest.ids, reason);
        toast.success('正常に変更されました');
        props.onRestoreSuccess();
        break;
      case handleTextToJP(RequestRevenueStatus.COMPLETED):
        await putChangeStatusToComplete(selectedRequest.ids, reason);
        toast.success('正常に変更されました');
        props.onRestoreSuccess();
        break;
      case handleTextToJP(RequestRevenueStatus.REJECTED):
        await putChangeStatusToReject(selectedRequest.ids, reason ?? '');
        toast.success('正常に変更されました');
        props.onRestoreSuccess();
        break;
      case handleTextToJP(RequestRevenueStatus.PENDING):
        await putChangeStatusToPending(selectedRequest.ids, reason);
        toast.success('正常に変更されました');
        props.onRestoreSuccess();
        break;
    }
  };

  // if (selectedRequest.currentLevel > selectedRequest.level) {

  // } else {
  // }

  const handleChangeStatus = async () => {
    try {
      switch (true) {
        case selectedRequest.level === Level.REJECTED:
        case selectedRequest.level < selectedRequest.currentLevel:
        case selectedRequest.currentLevel === Level.REJECTED &&
          selectedRequest.level === Level.COMPLETED:
        case [Level.REJECTED, Level.COMPLETED].includes(selectedRequest.currentLevel) &&
          selectedRequest.level <= Level.COMPLETED:
          setOpen(false);
          setOpenDialogRejected(true);
          break;
        default:
          await onChangeStatus();
          break;
      }
    } catch (error) {
      toast.error('Error');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (
    valueText: string,
    ids: number[],
    level: string | number,
    currentLevel: string | number
  ) => {
    setOpen(true);
    setSelectedRequest({
      ids,
      value: handleTextToJP(valueText),
      level,
      currentLevel,
    });
  };

  //handle logic for reason rejected
  const onClose = () => {
    setOpenDialogRejected(false);
    setReason('');
    setValidateError('');
  };

  const checkCanSubmit = () => {
    return reason.trim().length > 0;
  };

  const validate = () => {
    try {
      validationSchema.validateSync(reason);
      onSubmit();
    } catch (error) {
      if (error instanceof Yup.ValidationError) setValidateError(error.message);
    }
  };

  const validationSchema = Yup.string()
    .trim()
    .required()
    .max(INPUT_MAX_LENGTH, '255文字以内を入力してください。');

  const getOption = (status: any, key: 'label' | 'level' | 'value') => {
    return statusOptions.find((opt) => opt.value === status)?.[key] ?? '';
  };

  const onSubmit = async () => {
    try {
      await onChangeStatus(reason);
      onClose();
    } catch (error) {
      toast.error('Error');
    }
  };
  const [selectedRequests, setSelectedRequests] = useState<number[]>([]);

  const checkCanSelect = (status: RequestRevenueStatus) =>
    [RequestRevenueStatus.IN_PROGRESS, RequestRevenueStatus.PENDING].includes(status);

  const requestCanChooseList = dataUserRequest
    ?.filter((request) => checkCanSelect(request.status))
    ?.map((request) => request.id);
  useEffect(() => {
    setSelectedRequests([]);
  }, [dataUserRequest]);

  const onSetSelectedAll = () => {
    setSelectedRequests(requestCanChooseList);
  };

  const onSetUnSelectedAll = () => {
    setSelectedRequests([]);
  };

  const onCheckedItem = (id: number) => {
    setSelectedRequests((p) => [...p, id]);
  };

  const onUnCheckedItem = (id: number) => {
    setSelectedRequests((p) => p.filter((requestId) => requestId !== id));
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell className="w-[150px]"></TableCell>
            <TableCell className="w-[150px]"></TableCell>
            <TableCell align="center">
              <Select
                disabled={selectedRequests.length === 0}
                id="type"
                name="type"
                className="w-full border-none"
                value={'default'}
                onChange={(e: any) => {
                  onChange(
                    e.target.value,
                    selectedRequests,
                    getOption(e.target.value, 'level'),
                    getOption(Level.LIST, 'level')
                  );
                }}
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  color: '#000',
                }}
              >
                <MenuItem value={'default'}>ステータス</MenuItem>
                {statusOptionsSelectMultiple.map((statusOption) => (
                  <MenuItem key={statusOption.value} value={statusOption.value}>
                    {statusOption.label}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={
                  selectedRequests.length > 0 &&
                  selectedRequests.length === requestCanChooseList.length
                }
                onChange={(e: any) => {
                  if (e.target.checked) {
                    onSetSelectedAll();
                  } else {
                    onSetUnSelectedAll();
                  }
                }}
                className="h-[28px]"
              />
            </TableCell>
            <TableCell>ユーザー名</TableCell>
            <TableCell>メールアドレス</TableCell>
            <TableCell>日付け</TableCell>
            <TableCell className="w-[150px]">収入引き出し申請</TableCell>
            <TableCell className="w-[150px]">送金手数料差し引き後の金額</TableCell>
            <TableCell align="center">ステータス</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataUserRequest.length > 0 &&
            dataUserRequest.map((request: IRequestRevenue) => (
              <TableRow key={request.id} sx={{ height: 70 }}>
                <TableCell>
                  {checkCanSelect(request.status) ? (
                    <Checkbox
                      checked={selectedRequests.includes(request.id)}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          onCheckedItem(request.id);
                        } else {
                          onUnCheckedItem(request.id);
                        }
                      }}
                      className="h-[28px]"
                    />
                  ) : null}
                </TableCell>
                <TableCell>{request.user?.name}</TableCell>
                <TableCell>{request.user?.email}</TableCell>
                <TableCell>{moment(request.created_at).format('YYYY/MM/DD HH:mm')}</TableCell>
                <TableCell align="center" className="w-[150px]">
                  {`￥${request.amount}`}
                </TableCell>
                <TableCell align="center" className="w-[150px]">
                  {`￥${request.amount_after_fee}`}
                </TableCell>

                <TableCell align="center" sx={{ color: statusColor[request.status] }}>
                  {disableChangeStatusList.includes(request.status) ? (
                    getOption(request.status, 'label')
                  ) : (
                    <Select
                      id="type"
                      name="type"
                      className="w-full border-none"
                      value={request.status}
                      onChange={(e: any) => {
                        onChange(
                          e.target.value,
                          [request.id],
                          getOption(e.target.value, 'level'),
                          getOption(request.status, 'level')
                        );
                      }}
                      sx={{
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        color: statusColor[request.status],
                      }}
                    >
                      {statusOptionsSelect.map((statusOption) => (
                        <MenuItem key={statusOption.value} value={statusOption.value}>
                          {statusOption.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {dataUserRequest.length === 0 && (
        <div>
          <NoData />
        </div>
      )}
      <SimpleDialog
        open={openDialog}
        onClose={handleClose}
        onOk={handleChangeStatus}
        content={`${selectedRequest.value}という状態に変更してもよろしいですか？`}
      />

      <Dialog open={openDialogRejected} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle className="text-center">理由却下</DialogTitle>
        <DialogContent>
          <div className="my-4">
            <TextField
              label="理由"
              placeholder="理由"
              id="Reason"
              name="Reason"
              multiline
              minRows={6}
              fullWidth
              onChange={(e) => {
                setReason(e.target.value);
              }}
              value={reason}
              error={validateError !== ''}
              helperText={validateError !== '' ? validateError : ''}
            />
            <div className="text-center mt-8 space-x-4">
              <ButtonCustomer
                onClick={onClose}
                className="w-32 border-2 border-gray rounded-3xl"
                rounded
              >
                キャンセル
              </ButtonCustomer>
              <ButtonCustomer
                disabled={!checkCanSubmit()}
                rounded
                onClick={validate}
                className="w-32 rounded-3xl bg-main_1 text-white"
              >
                送信
              </ButtonCustomer>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TableUser;
