import React, { useState, KeyboardEvent, useEffect } from 'react';
import s from './style.module.scss';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import 'dayjs/locale/ja';
import dayjs from 'dayjs';
import { getReportJasrac, getReportNextone, getContentCountAndView } from 'handler/statistic';
import { saveFile } from 'utils';
import CONST_DATA from 'constant/data';

enum StatisticType {
  JAS_PDF_HAS_VIEW = 'jas_pdf_has_view',
  JAS_PDF_HAS_DOWNLOAD = 'jas_pdf_has_download',
  JAS_OTHER_TYPE_HAS_VIEW = 'jas_other_type_has_view',
  NXT_PDF_HAS_VIEW = 'nxt_pdf_has_view',
  NXT_PDF_HAS_DOWNLOAD = 'nxt_pdf_has_download',
  NXT_OTHER_TYPE_HAS_VIEW = 'nxt_other_type_has_view',
}

interface CountAndViewInfo {
  total_content: number;
  total_view: number;
  total_download: number;
  total_download_free: number;
}

const defaultCountAndView: Record<StatisticType, CountAndViewInfo> = {
  jas_pdf_has_view: {
    total_content: 0,
    total_view: 0,
    total_download: 0,
    total_download_free: 0,
  },
  jas_pdf_has_download: {
    total_content: 0,
    total_view: 0,
    total_download: 0,
    total_download_free: 0,
  },
  jas_other_type_has_view: {
    total_content: 0,
    total_view: 0,
    total_download: 0,
    total_download_free: 0,
  },
  nxt_pdf_has_view: {
    total_content: 0,
    total_view: 0,
    total_download: 0,
    total_download_free: 0,
  },
  nxt_pdf_has_download: {
    total_content: 0,
    total_view: 0,
    total_download: 0,
    total_download_free: 0,
  },
  nxt_other_type_has_view: {
    total_content: 0,
    total_view: 0,
    total_download: 0,
    total_download_free: 0,
  },
};

const Statistic = () => {
  const date = new Date();
  const currentMonth = date.getMonth();
  const defaultDate = date.setMonth(currentMonth - 1);
  const [selectDate, setSelectDate] = useState(defaultDate);
  const [countInfo, setCountInfo] =
    useState<Record<StatisticType, CountAndViewInfo>>(defaultCountAndView);

  const onKeyDown = (e: KeyboardEvent<HTMLImageElement>) => {
    e.preventDefault();
  };

  const reportJasracPdfHasView = async () => {
    const dataPDFNoPrice = await getReportJasrac(`${dayjs(selectDate).format('YYYY-MM-DD')}`, 1);
    saveFile(dataPDFNoPrice, `${CONST_DATA.license_code.JASRAC_PDF_VIEW}${dayjs(selectDate).format('YYYYMM')}.txt`);
  };

  const reportJasracPdfHasDownload = async () => {
    const dataPDFPrice = await getReportJasrac(`${dayjs(selectDate).format('YYYY-MM-DD')}`, 2);
    saveFile(dataPDFPrice, `${CONST_DATA.license_code.JASRAC_PDF_DOWNLOAD}${dayjs(selectDate).format('YYYYMM')}.txt`);
  };

  const reportJasracOtherTypeHasView = async () => {
    const dataOrther = await getReportJasrac(`${dayjs(selectDate).format('YYYY-MM-DD')}`, 3);
    saveFile(dataOrther, `${CONST_DATA.license_code.JASRAC_OTHER_TYPES_VIEW}${dayjs(selectDate).format('YYYYMM')}.txt`);
  };

  const reportNextonePdfHasView = async () => {
    const dataPDFNoPrice = await getReportNextone(`${dayjs(selectDate).format('YYYY-MM-DD')}`, 4);
    saveFile(dataPDFNoPrice, `${CONST_DATA.license_code.NEXTONE_PDF_VIEW}-${dayjs(selectDate).format('YYYYMM')}.txt`);
  };

  const reportNextonePdfHasDownload = async () => {
    const dataPDFPrice = await getReportNextone(`${dayjs(selectDate).format('YYYY-MM-DD')}`, 5);
    saveFile(dataPDFPrice, `${CONST_DATA.license_code.NEXTONE_PDF_DOWNLOAD}-${dayjs(selectDate).format('YYYYMM')}.txt`);
  };

  useEffect(() => {
    getContentCountAndView(`${dayjs(selectDate).format('YYYY-MM-DD')}`).then((data) =>
      setCountInfo(data.data)
    );
  }, [selectDate]);

  return (
    <div className={s.container}>
      <div className={s.boxselectDate}>
        <label>日付を選択</label>
        <div className={s.boxDate}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <DatePicker
              value={selectDate}
              inputFormat="MM-YYYY"
              onChange={(data: any) => {
                setSelectDate(data);
              }}
              views={['month', 'year']}
              maxDate={defaultDate}
              renderInput={(props) => <TextField onKeyDown={onKeyDown} {...props} />}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className={s.boxDownload}>
        <div className={s.itemFile}>
          <div className={s.itemName}>Jasrac ファイル:</div>
          <div className={s.listFileDownload}>
            <ul>
              <li>
                <span className={`pr-5 ${!countInfo.jas_pdf_has_view.total_content ? "!cursor-not-allowed !text-gray !no-underline" : ""}`}
                  onClick={reportJasracPdfHasView}>
                  可視サービス
                </span>
                <div className={s.countInfo}>
                  <span>件数： {countInfo.jas_pdf_has_view.total_content}</span>
                  <span>総閲覧数： {countInfo.jas_pdf_has_view.total_view}</span>
                </div>
              </li>
              <li>
                <span className={`pr-5 ${!countInfo.jas_pdf_has_download.total_content ? "!cursor-not-allowed !text-gray !no-underline" : ""}`}
                  onClick={reportJasracPdfHasDownload}>
                  ダウンロードサービス
                </span>
                <div className={s.countInfo}>
                  <span>件数： {countInfo.jas_pdf_has_download.total_content}</span>
                  <span>
                    <span>ダウンロード数： {countInfo.jas_pdf_has_download.total_download}</span>
                    <span>（再ダウンロード数含め）： {countInfo.jas_pdf_has_download.total_download_free}</span>
                  </span>
                </div>
              </li>
              <li>
                <span className={`pr-5 ${!countInfo.jas_other_type_has_view.total_content ? "!cursor-not-allowed !text-gray !no-underline" : ""}`}
                  onClick={reportJasracOtherTypeHasView}>
                  音声サービス・動画サービス
                </span>
                <div className={s.countInfo}>
                  <span>件数： {countInfo.jas_other_type_has_view.total_content}</span>
                  <span>総閲覧数： {countInfo.jas_other_type_has_view.total_view}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={s.itemFile}>
          <div className={s.itemName}>Nextone ファイル:</div>
          <div className={s.listFileDownload}>
            <ul>
              <li>
                <span className={`pr-5 ${!countInfo.nxt_pdf_has_view.total_content ? "!cursor-not-allowed !text-gray !no-underline" : ""}`} 
                  onClick={reportNextonePdfHasView}>
                  可視サービス（音声動画含む）
                </span>
                <div className={s.countInfo}>
                  <span>件数： {countInfo.nxt_pdf_has_view.total_content}</span>
                  <span>総閲覧数： {countInfo.nxt_pdf_has_view.total_view}</span>
                </div>
              </li>
              <li>
                <span className={`pr-5 ${!countInfo.nxt_pdf_has_download.total_content ? "!cursor-not-allowed !text-gray !no-underline" : ""}`}
                  onClick={reportNextonePdfHasDownload}>
                  ダウンロードサービス
                </span>
                <div className={s.countInfo}>
                  <span>件数： {countInfo.nxt_pdf_has_download.total_content}</span>
                  <span>
                    <span>ダウンロード数： {countInfo.nxt_pdf_has_download.total_download}</span>
                    <span>（再ダウンロード数含め）： {countInfo.nxt_pdf_has_download.total_download_free}</span>
                  </span>
                </div>
              </li>
              <li>
                <span className={`pr-5 !cursor-not-allowed !text-gray !no-underline`} >
                  音声サービス・動画サービス
                </span>
                <div className={s.countInfo}>
                  <span>件数： {countInfo.nxt_other_type_has_view.total_content}</span>
                  <span>総閲覧数： {countInfo.nxt_other_type_has_view.total_view}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistic;
