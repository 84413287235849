import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React from 'react';

interface Props {
  valueEditor: string;
}

const ViewCkEditor = ({ valueEditor }: Props) => {
  return (
    <div className="article-content">
      <CKEditor
        editor={DecoupledEditor}
        disabled
        data={valueEditor}
        onReady={(editor: any) => {
          const toolbarElement: any = document.querySelector('.ck-editor__top');
          console.log('toolbarElement', toolbarElement);
          if (editor.isReadOnly && toolbarElement) {
            toolbarElement.style.display = 'none';
          } else if (toolbarElement && !editor.isReadOnly) {
            toolbarElement.style.display = '';
          }
          editor.ui.view.editable.element.parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.view.editable.element
          );
        }}
      />
    </div>
  );
};

export default ViewCkEditor;
