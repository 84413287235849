// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-container .MuiInputBase-input {
  padding: 9px 12px;
}

.revenue-dialog .MuiDialogContent-root {
  padding: 0px !important;
  background-color: white;
  overflow: hidden !important;
}
.revenue-dialog .MuiTableCell-head {
  background-color: white;
  color: #DAB67F;
}
.revenue-dialog .MuiPaper-root {
  overflow: hidden !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/bonus-management/index.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;;AAME;EACE,uBAAA;EACA,uBAAA;EACA,2BAAA;AAHJ;AAKE;EACE,uBAAA;EACA,cAAA;AAHJ;AAME;EACE,2BAAA;AAJJ","sourcesContent":[".user-container {\n  .MuiInputBase-input {\n    padding: 9px 12px;\n  }\n}\n\n\n.revenue-dialog {\n  .MuiDialogContent-root {\n    padding: 0px !important;\n    background-color: white;\n    overflow: hidden !important;\n  }\n  .MuiTableCell-head {\n    background-color: white;\n    color: #DAB67F;\n  }\n\n  .MuiPaper-root {\n    overflow: hidden !important;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
