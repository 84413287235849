// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_textLink__Salu0 {
  color: rgb(71, 71, 255);
  font-size: 0.75rem;
  line-height: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/content-detail/style.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".textLink{\n    color: rgb(71, 71, 255);\n    font-size: 0.75rem;\n    line-height: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textLink": `style_textLink__Salu0`
};
export default ___CSS_LOADER_EXPORT___;
