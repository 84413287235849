import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import Title from 'components/title/title';
import { getUserContentById, getUserDetail } from 'handler/user-management';
import { IContent } from 'handler/user-management/typings';
import Table from 'components/content-table';
import ButtonCustomer from 'components/button';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import Pagination from 'components/pagination';
import './index.scss';
import { toast } from 'react-toastify';

const LIMIT = 10;

const UserManagement = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const [dataContent, setDataContent] = useState<IContent[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [lastPage, setLastPage] = useState<number>(1);
  const [type, setType] = useState(-1);
  const [username, setUsername] = useState('');

  const getAllUserApi = async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await getUserContentById(id, page, LIMIT, search, type);
      console.log('response', response);
      setDataContent(response?.data?.data);
      setTotal(response?.data?.total);
      setLastPage(response?.data?.last_page);

      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  const getUserDetailApi = useCallback(async () => {
    try {
      const response = await getUserDetail(id);
      setUsername(response?.data?.name);
    } catch (error: any) {
      toast.error(error?.message);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!id) return;
    getUserDetailApi();
  }, [id]);

  useEffect(() => {
    getAllUserApi();
  }, [page, type]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
  };

  const onChangeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClickSearch = (event?: React.KeyboardEvent<HTMLDivElement>) => {
    if (event?.key === 'Enter' || !event) {
      setPage(1);
      getAllUserApi();
    }
  };

  return (
    <div className="w-full h-main bg-white p-4 user-container pb-32">
      <div className="main-header">
        <Title title={`${username}のコンテンツ一覧`} />
        <div className="header-actions flex items-center gap-2 justify-between">
          <div className="w-[120px]">
            <Select
              id="type"
              name="type"
              className="w-full"
              value={type}
              onChange={(e: any) => {
                setType(e.target.value as number);
                setPage(1);
              }}
            >
              <MenuItem value={-1}>All</MenuItem>
              <MenuItem value={0}>PDF</MenuItem>
              <MenuItem value={1}>MIDI</MenuItem>
              <MenuItem value={2}>AUDIO</MenuItem>
              <MenuItem value={3}>VIDEO</MenuItem>
            </Select>
          </div>
          <div>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="w-[300px]"
              onChange={onChangeSearchKey}
              onKeyDown={(e) => onClickSearch(e)}
              value={search}
              placeholder="コンテンツタイプ、コンテンツなど"
              sx={{
                input: {
                  '&::placeholder': {
                    fontSize: 15,
                  },
                },
                marginRight: 1,
              }}
            />
            <ButtonCustomer
              name="検索"
              size="medium"
              rounded={true}
              className="bg-blue text-white"
              onClick={() => onClickSearch()}
            />
          </div>
        </div>
      </div>
      <div className="main-body">
        <Table dataContents={dataContent} onDisableSuccess={getAllUserApi} />
        <div className="pagination">
          <Pagination
            limit={LIMIT}
            total={total}
            onChange={onChangePaginate}
            page={page}
            hidden={dataContent.length === 0}
            lastPage={lastPage}
          />
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
