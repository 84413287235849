import Card from 'components/card';
import { getInquiryUnreadCount, putInquiryRead } from 'handler/inquiry';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saveTotalUnreadInquiry } from 'stores/user/reducer';
import { linkify } from 'utils/common';

type prop = {
  dataInquiry: any;
  replyAction: () => void;
  cancelAction?: () => void;
};

const blockInfo = [
  { title: 'メールアドレス', key: 'email' },
  { title: '日付け', key: 'created_at' },
  { title: '質問', key: 'content' },
];

function InquiryDetail({ dataInquiry, replyAction }: prop) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (dataInquiry?.id) {
      callApiReadInquiry();
    }
  }, [dataInquiry]);

  const callApiReadInquiry = async () => {
    try {
      const data = await putInquiryRead(dataInquiry?.id);
      if (data?.success) {
        callApiUnreadInquiry();
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const callApiUnreadInquiry = async () => {
    try {
      const data = await getInquiryUnreadCount();
      dispatch(saveTotalUnreadInquiry(data?.data?.count));
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Card>
        {blockInfo.map((item, index) => (
          <div
            className={`mt-1 py-1 ${
              index !== blockInfo.length - 1 ? 'border-b border-[#F1A200]/20' : ''
            }`}
            key={index}
          >
            <p className="text-18 font-bold ">{item.title}</p>
            {item.key === 'created_at' && (
              <p className="mt-2 break-words whitespace-pre-line break-all">
                {moment(dataInquiry?.[item.key]).format('YYYY/MM/DD HH:mm')}
              </p>
            )}

            {item.key === 'content' && (
              <p
                className="mt-2 break-words whitespace-pre-line break-all"
                dangerouslySetInnerHTML={{
                  __html: linkify(dataInquiry?.[item.key]),
                }}
              ></p>
            )}

            {item.key === 'email' && (
              <p
                className="mt-2 break-words whitespace-pre-line break-all"
                dangerouslySetInnerHTML={{
                  __html: linkify(dataInquiry?.[item.key]),
                }}
              ></p>
            )}
          </div>
        ))}
      </Card>

      {dataInquiry?.answer && (
        <Card className="mt-[16px]">
          {/* className="mt-1 border-b border-[#F1A200]/20 py-1 mb-[11px]" */}
          <div>
            <p className="text-18 font-bold">回答</p>
            <p className="mt-2 break-words text-13 text-[#8A8A83]">
              {moment(dataInquiry?.updated_at).format('DD/MM/YYYY HH:mm')}
            </p>
            <p
              className="mt-2 break-words whitespace-pre-line break-all"
              dangerouslySetInnerHTML={{
                __html: linkify(dataInquiry?.answer),
              }}
            ></p>
          </div>
        </Card>
      )}

      <div className="flex justify-center mt-10">
        <div className="mr-10">
          <Link to="/inquiry">
            <button className="border-[#2E253D]/20 border p-[5px] w-[100px] rounded-[8px]">
              <span>キャンセル</span>
            </button>
          </Link>
        </div>
        {!dataInquiry?.answer && (
          <div>
            <button className="bg-[#585EF4] p-[5px] rounded-[8px] w-[100px]" onClick={replyAction}>
              <span className="text-white">返信</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default InquiryDetail;
