// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__Zy-DZ {
  background-color: #FFF;
  height: 100%;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
}

.style_boxUploadFile__BEJXs {
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.style_btnUpload__3OjJy {
  border-radius: 8px;
  background: #585EF4;
  color: #FFF;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}

.style_btnSave__WF\\+vI {
  color: #FFFFFF !important;
  margin-top: 30px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/upload-content/style.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,2BAAA;AACJ;;AACA;EACI,iBAAA;EACA,aAAA;EACA,2BAAA;EACA,oBAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;AAGJ;;AADA;EACI,yBAAA;EACA,2BAAA;AAIJ","sourcesContent":[".container{\n    background-color: #FFF;\n    height: 100%;\n    width: 100%;\n    padding: 20px 30px;\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n    justify-content: flex-start;\n}\n.boxUploadFile{\n    padding-top: 20px;\n    display: flex;\n    justify-content: flex-start;\n    padding-bottom: 20px;\n}\n.btnUpload{\n    border-radius: 8px;\n    background: #585EF4;\n    color: #FFF;\n    font-size: 16px;\n    font-family: Inter;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    text-transform: lowercase;\n    width: 120px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    margin-left: 10px;\n}\n.btnSave{\n    color: #FFFFFF !important;\n    margin-top: 30px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__Zy-DZ`,
	"boxUploadFile": `style_boxUploadFile__BEJXs`,
	"btnUpload": `style_btnUpload__3OjJy`,
	"btnSave": `style_btnSave__WF+vI`
};
export default ___CSS_LOADER_EXPORT___;
