// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-container .MuiInputBase-input {
  padding: 9px 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/user-request/index.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ","sourcesContent":[".user-container {\n  .MuiInputBase-input {\n    padding: 9px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
