import React from 'react';
import NoDataImage from 'assets/images/no-data.png';
function NoData() {
  return (
    <div className="h-60 mt-48">
      <img src={NoDataImage} alt="no data" className="text-center mx-auto" />
      <p style={{ color: '#0000003D', textAlign: 'center' }}>表示するデータ無し</p>
    </div>
  );
}

export default NoData;
