import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { publicRouters, privateRouters, IRouter } from './const';
import PrivateRouter from './privateRouter';
import PublicRouters from './publicRouter';
const RoutersComponent = () => {
  return (
    <Routes>
      {publicRouters.map((route: IRouter) => (
        <Route
          key={route.path}
          path={route.path}
          element={<PublicRouters component={route.component} />}
        />
      ))}
      {privateRouters.map((route: IRouter) => (
        <Route
          key={route.path}
          path={route.path}
          element={<PrivateRouter component={route.component} />}
        />
      ))}
    </Routes>
  );
};

export default RoutersComponent;
