import { RequestRevenueStatus } from 'handler/revenue/typings';

export const capitalizeString = (str: string): string => {
  if (!str) return '';

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const getMarkColor = (mark: number): string => {
  if (mark >= 8) return 'green';
  if (mark >= 4) return 'goldenrod';
  return 'red';
};

export const linkify = (inputText: string) => {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;
  if (inputText?.indexOf('<a ')) {
    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    replacedText = inputText.replace(
      replacePattern1,
      '<div contentEditable="false" style="display: inline-block; color: #0066FF;"><a href="$1" target="_blank">$1</a></div>'
    );

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(
      replacePattern2,
      '$1<div contentEditable="false" style="display: inline-block; color: #0066FF;"><a href="http://$2" target="_blank">$2</a></div>'
    );

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(
      replacePattern3,
      '<div contentEditable="false" style="display: inline-block; color: #0066FF;"><a href="mailto:$1">$1</a></div>'
    );
  } else {
    replacedText = inputText;
  }
  return replacedText;
};

export const JASRAC_SEARCH_URL = 'https://www2.jasrac.or.jp/eJwid/';
export const NEXTONE_SEARCH_URL =
  'https://search.nex-tone.co.jp/terms;jsessionid=9A940822DD778A4CB7CEF4A93307D06D?0';

export const saveFile = (data: any, saveName: string) => {
  if(!data) return;
  const type = data.headers ? data.headers.get('content-type') : 'application/txt';
  const url = window.URL.createObjectURL(new Blob([data.data || data], { type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', saveName);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};

export const handleTextToJP = (valueText: string) => {
  switch (valueText) {
    case RequestRevenueStatus.PENDING:
      return `「保留中」`;
    case RequestRevenueStatus.IN_PROGRESS:
      return `「処理中」`;
    case RequestRevenueStatus.COMPLETED:
      return `「完了」`;
    case RequestRevenueStatus.REJECTED:
      return `「拒否」`;
  }
};

export const handleTextToJPOnlyText = (valueText: string) => {
  switch (valueText) {
    case RequestRevenueStatus.PENDING:
      return `保留中`;
    case RequestRevenueStatus.IN_PROGRESS:
      return `処理中`;
    case RequestRevenueStatus.COMPLETED:
      return `引き出し済み`;
    case RequestRevenueStatus.REJECTED:
      return `却下 `;
    case RequestRevenueStatus.CANCELED:
      return `キャンセル`;
  }
};

export function removeTags(str: string) {
  if (str === null || str === '') return false;
  else str = str.toString();

  return str.replace(/(<([^>]+)>)/gi, '');
}

export const convertDate = (dateTime: string) => {
  if (!dateTime) return;
  return dateTime.slice(0, 10).replaceAll('-', '/');
};

export function addDots(nStr: string) {
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{2})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + '.' + '$2'); // changed comma to dot here
  }
  return x1 + x2;
}

export const REGEX_PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;

export const REGEX_ONLY_SPACES = /^(?!\s+$).*/;
export const REGEX_STRING_NORMALLY =
  // eslint-disable-next-line no-control-regex, no-irregular-whitespace
  /^[\u0000-\u00ff\u3040-\u309f\u30a0-\u30ff\u4e00-\u9faf\u3400-\u4dbf\u3000-\u303f\uff00-\uffee\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\uffea-\uffee\u2502\u25a0\u25cb\u2985\u2986\u20a9\u203B]*$/;
export const REGEX_EMAIL = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const ignoreHtmlTags = (inputString: string) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = inputString;
  return tempElement.textContent || tempElement.innerText;
};
