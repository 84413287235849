import { put, takeEvery, call } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { SET_LOADING } from 'stores/const/app';
import { setLoading } from './reducer';

function* setLoadingSaga(payload: PayloadAction<boolean>) {
  yield put(setLoading(payload.payload));
}

export default function* appSaga() {
  yield takeEvery(SET_LOADING, setLoadingSaga);
}
