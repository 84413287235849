import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { IContent } from 'handler/user-management/typings';
import { NavLink } from 'react-router-dom';
import ContentType from 'components/content-type';
import moment from 'moment';
import ButtonCustomer from 'components/button';
import { disableContent } from 'handler/content/intex';
import { toast } from 'react-toastify';
import NoData from 'components/no-data';

interface Props {
  dataContents: IContent[];
  canEdit: 0 | 1 | undefined;
  canDelete: 0 | 1 | undefined;
  setPermissionUserContent: (
    can_delete: 0 | 1 | undefined,
    can_edit: 0 | 1 | undefined,
    id?: number,
    isSetAll?: 0 | 1
  ) => void;
}
function TableContents(props: Props) {
  const { dataContents, canEdit, canDelete, setPermissionUserContent } = props;
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>コンテンツタイプ</TableCell>
            <TableCell>コンテンツ名</TableCell>
            <TableCell align="center" size="small">
              コンテンツナンバー
            </TableCell>
            <TableCell size="small">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      const isSetAll = e.target.checked ? 1 : 0;
                      setPermissionUserContent(isSetAll, undefined, undefined, 1);
                    }}
                    checked={canEdit === 1}
                  />
                }
                label="編集"
              />
            </TableCell>
            <TableCell size="small">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={canDelete === 1}
                    onChange={(e) => {
                      const isSetAll = e.target.checked ? 1 : 0;
                      setPermissionUserContent(undefined, isSetAll, undefined, 1);
                    }}
                  />
                }
                label="消去"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataContents.length > 0 &&
            dataContents.map((content: IContent) => (
              <TableRow key={content.id}>
                <TableCell>
                  <ContentType type={content.content_type} />
                </TableCell>
                <TableCell>
                  <NavLink
                    className={'text-blue hover:underline font-bold '}
                    to={`/content-detail/` + content.id}
                  >
                    <Tooltip title={content.display_name}>
                      <div className="w-32 truncate">
                        {content.display_name}
                      </div>
                    </Tooltip>
                  </NavLink>
                </TableCell>
                <TableCell align="center" size="small">
                  {content?.content_code}
                </TableCell>
                <TableCell size="small">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={content.can_edit === 1}
                        onChange={(e) => {
                          const can_edit = e.target.checked ? 1 : 0;
                          const id = content.id;
                          setPermissionUserContent(can_edit, undefined, id);
                        }}
                      />
                    }
                    label=""
                  />
                </TableCell>
                <TableCell size="small">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={content.can_delete === 1}
                        onChange={(e) => {
                          const can_delete = e.target.checked ? 1 : 0;
                          const id = content.id;
                          setPermissionUserContent(undefined, can_delete, id);
                        }}
                      />
                    }
                    label=""
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {dataContents.length === 0 && (
        <div>
          <NoData />
        </div>
      )}
    </div>
  );
}

export default TableContents;
