import STATISTIC_API from 'constant/api/statistic';
import { IResponse } from 'models/common';
import ApiUtils from 'utils/api/api.utils';

export const getReportNextone = async (date: string, report_type: number) => {
  const params = {
    date: date,
    report_type: report_type,
  };
  const response: IResponse = await ApiUtils.get(STATISTIC_API.GET_REPORT_NEXTONE, params, {
    isAuth: true,
  });
  return response?.data;
};

export const getReportJasrac = async (date: string, report_type: number) => {
  const params = {
    date: date,
    report_type: report_type,
  };
  const response: IResponse = await ApiUtils.get(STATISTIC_API.GET_REPORT_JASRAC, params, {
    isAuth: true,
  });
  return response?.data;
};

export const getContentCountAndView = async (date: string) => {
  const params = {
    date: date,
  };
  const response: IResponse = await ApiUtils.get(STATISTIC_API.GET_CONTENT_COUNT_AND_VIEW, params, {
    isAuth: true,
  });
  return response?.data;
};
