import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CKEditorCommon from 'components/CKEditorCommon';
import ViewCkEditor from 'components/ViewCKEditor';
import ButtonCustomer from 'components/button';
import { useFormik } from 'formik';
import { createTemplate, updateTemplate } from 'handler/inquiry';
import { ICreateTemplateRequest, ITemplate } from 'handler/inquiry/typings';
import { ignoreHtmlTags } from 'utils';
import * as Yup from 'yup';

interface Props {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  dataEdit?: ITemplate;
  onSuccess: () => void;
}

function CreateTemplate(props: Props) {
  const { open, handleClose, isEdit, dataEdit, onSuccess } = props;
  const [valueEditor, setValueEditor] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isShowPrev, setIsShowPrev] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const initialValues = {
    title: '',
    content: '',
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .max(255, 'タイトル255文字以内を入力してください。'),
  });

  const handleSubmit = async (value: any) => {
    if (!isEdit) {
      handleCreateTemplate(value);
      return;
    }
    handleUpdateTemplate(value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    initialErrors: {},
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (dataEdit) {
      formik.setValues({
        title: dataEdit.title,
        content: dataEdit.content,
      });
      setValueEditor(dataEdit.content);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const success = () => {
    toast.success(
      `${
        isEdit ? '返信用テンプレートを更新しました。' : '返信用テンプレートをスが作成されました。'
      }`
    );
    onSuccess();
  };

  const handleCreateTemplate = async (value: any) => {
    const data: ICreateTemplateRequest = {
      title: value.title,
      content: valueEditor,
    };
    try {
      const response = await createTemplate(data);
      if (response) {
        success();
      }
    } catch (error) {
      return error;
    }
  };

  const handleUpdateTemplate = async (value: any) => {
    const data: ICreateTemplateRequest = {
      title: value.title,
      content: valueEditor,
    };
    const response = await updateTemplate(data, dataEdit?.id);

    if (response) {
      success();
    }
  };

  const checkCanSubmit = () => {
    const title = formik.values.title;
    const content = valueEditor;
    const dataLength = ignoreHtmlTags(content.replaceAll('<p>&nbsp;</p>', '')).length;
    return title && content && dataLength <= 10000;
  };

  const handlePreview = () => {
    setIsShowPrev(!isShowPrev);
  };

  const handleDataEditor = (data: string) => {
    const dataLength = ignoreHtmlTags(data.replaceAll('<p>&nbsp;</p>', '')).length;
    if (dataLength > 10000) {
      setError('半角10000文字以内で入力してください。');
    } else {
      setError(null);
    }
    setValueEditor(data);
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle className="text-center">
          {isEdit ? '問い合わせ回答テンプレート編集' : '問い合わせ回答テンプレート作成'}
        </DialogTitle>
        <DialogContent>
          {!isShowPrev ? (
            <form onSubmit={formik.handleSubmit} className="space-y-4 py-3">
              <TextField
                label="タイトル"
                id="title"
                name="title"
                className="w-[850px] bg-white"
                onChange={formik.handleChange}
                value={formik.values.title}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />

              <CKEditorCommon
                valueEditor={valueEditor}
                onHandleData={(data) => handleDataEditor(data)}
              />
              {error && <p className="text-red text-[12px]">{error}</p>}
              <div className="space-x-4 mx-auto mt-8 font-bold text-center">
                <ButtonCustomer
                  onClick={handleClose}
                  className="w-32 border-2 border-gray rounded-3xl"
                  rounded
                >
                  キャンセル
                </ButtonCustomer>
                <ButtonCustomer
                  onClick={handlePreview}
                  className="w-32 border-2 bg-green text-white rounded-3xl border-gray"
                  rounded
                  type="button"
                >
                  プレビュー
                </ButtonCustomer>
                <ButtonCustomer
                  disabled={!checkCanSubmit()}
                  rounded
                  type="submit"
                  className="w-32 rounded-3xl bg-main_1 text-white"
                >
                  保存
                </ButtonCustomer>
              </div>
            </form>
          ) : (
            <div className=" min-h-[50px] min-w-[850px] max-w-[850px] ">
              <ViewCkEditor valueEditor={valueEditor} />
              <div className="flex justify-center w-[100%]">
                <ButtonCustomer
                  onClick={handlePreview}
                  className="w-40 mt-2 border-2 bg-green text-white rounded-3xl border-gray"
                  rounded
                  type="button"
                >
                  プレビューを閉じる
                </ButtonCustomer>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateTemplate;
