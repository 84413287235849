/* eslint-disable no-empty */
export enum KeyStorage {
  LOCALE = 'locale',
  X_TOKEN = 'x_token',
  AUTH_DATA = 'authData',
  MESSAGE = 'message',
  COLAPSED = 'colapsed',
  SAVE_PASSWORD = 'savePassword',
}

const localStorageHelper = {
  set: (key: KeyStorage, value: string, isLocal = true): boolean => {
    try {
      if (isLocal) {
        localStorage.setItem(key, value);
        return true;
      } else {
        sessionStorage.setItem(key, value);
        return true;
      }
    } catch (error) {}
    return false;
  },
  setObject: (key: KeyStorage, value: unknown, isLocal = true): boolean => {
    try {
      if (isLocal) {
        const newValue = JSON.stringify(value);
        localStorage.setItem(key, newValue);
        return true;
      } else {
        const newValue = JSON.stringify(value);
        sessionStorage.setItem(key, newValue);
        return true;
      }
    } catch (error) {}
    return false;
  },
  get: (key: KeyStorage, defaultValue: string | null = null, isLocal = true): string | null => {
    try {
      let value;
      if (isLocal) {
        value = localStorage.getItem(key);
      } else {
        value = sessionStorage.getItem(key);
      }
      if (value) {
        return value;
      }
    } catch (error) {}
    return defaultValue;
  },

  getObject: (key: KeyStorage, defaultValue: unknown = {}, isLocal = true): any => {
    try {
      let value;
      if (isLocal) {
        value = localStorage.getItem(key);
        if (value) {
          const object = JSON.parse(value);
          return object || defaultValue;
        }
      } else {
        if (isLocal) {
          value = sessionStorage.getItem(key);
          if (value) {
            const object = JSON.parse(value);
            return object || defaultValue;
          }
        }
      }
    } catch (error) {}
    return defaultValue;
  },

  remove: (key: KeyStorage, isLocal = true) => {
    if (isLocal) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  },

  clear: () => {
    localStorage.clear();
    sessionStorage.clear();
  },
};

export default localStorageHelper;
