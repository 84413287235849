import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ButtonCustomer from 'components/button';
import InforIcon from 'assets/icons/infor-icon.svg';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  title?: string;
  content?: string;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onOk, onClose, open } = props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '22px',
        },
      }}
    >
      {props.title && <DialogTitle>警告</DialogTitle>}
      <DialogContent
        style={{
          paddingTop: '60px',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <img
          src={InforIcon}
          alt="default"
          className="mx-auto mb-8 rounded-md"
          style={{ width: '125px', height: '100px' }}
        />
        <div className="text-center text-22 font-normal">
          {props.content ?? 'このアカウントを無効にしてもよろしいですか?'}
        </div>
      </DialogContent>
      <DialogActions
        className="mx-auto"
        style={{
          paddingBottom: '60px',
        }}
      >
        <ButtonCustomer
          color="w-[150px] h-[42px] rounded-[30px] border-2 border-black/10"
          rounded
          onClick={onClose}
        >
          キャンセル
        </ButtonCustomer>
        <ButtonCustomer
          color="bg-main_1 w-[150px] h-[42px] rounded-[30px] text-white"
          rounded
          onClick={onOk}
        >
          OK
        </ButtonCustomer>
      </DialogActions>
    </Dialog>
  );
}

export default SimpleDialog;
