import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { IContent } from 'handler/user-management/typings';
import CloseIcon from '@mui/icons-material/Close';
import ContentType from 'components/content-type';
import ContentTable from './content-table';

const ContentItem = (props: {
  content: IContent;
  onDelete: () => void;
  onlyViewList?: boolean;
}) => {
  const { content, onDelete, onlyViewList = false } = props;

  return (
    <div className="flex items-center border-b h-16 gap-2">
      <div className="w-2/12">
        <ContentType type={content?.content_type} />
      </div>
      <p className="w-3/12 truncate">{''}</p>
      <p className="w-3/12 truncate">{content?.display_name}</p>
      <p className="w-2/12">{content?.user.name}</p>
      {!onlyViewList && (
        <div className="w-2/12 text-right">
          <CloseIcon className="cursor-pointer hover:opacity-80" onClick={onDelete} />
        </div>
      )}
    </div>
  );
};

interface Props {
  contents?: IContent[];
  saveContent?: (contents: IContent[]) => void;
  onDeleteContent?: (content: IContent) => void;
  onlyViewList?: boolean;
}

function ContentList(props: Props) {
  const { contents, saveContent, onDeleteContent, onlyViewList = false } = props;
  const [open, setOpen] = useState(false);

  const handleAddContent = () => {
    setOpen(true);
  };

  const onDelete = (content: IContent) => {
    if (!onDeleteContent) return;
    onDeleteContent(content);
  };

  const onSaveAdd = (contentSelected: IContent[]) => {
    if (saveContent) saveContent(contentSelected);
    setOpen(false);
  };
  return (
    <div>
      {!onlyViewList && (
        <div className="flex justify-between">
          {
            <>
              <p className="font-bold">コンテンツ一覧</p>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                color="primary"
                onClick={handleAddContent}
              >
                コンテンツ追加
              </Button>
            </>
          }
        </div>
      )}

      <div className="mt-1">
        {contents &&
          contents.map((content) => (
            <ContentItem
              key={content.id}
              content={content}
              onDelete={() => {
                onDelete(content);
              }}
              onlyViewList={onlyViewList}
            />
          ))}
      </div>

      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <p className="text-24 font-bold">コンテンツ追加</p>
        </DialogTitle>
        <DialogContent>
          <div className="h-[70vh] py-4">
            <ContentTable
              contentSelected={contents}
              onSaveAdd={onSaveAdd}
              onClose={() => {
                setOpen(false);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ContentList;
