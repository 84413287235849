import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useAppDispatch } from 'stores/hooks';
import { IContent } from 'handler/user-management/typings';
import { SET_LOADING } from 'stores/const/app';
import { editPermission, getListContent } from 'handler/content/intex';
import Table from './user-content-table';
import Pagination from 'components/pagination';
import { toast } from 'react-toastify';
interface Props {
  userId?: number;
  open: boolean;
  handleClose: () => void;
}
const ContentModalComponent = ({ userId, open, handleClose }: Props) => {
  const dispatch = useAppDispatch();
  const [dataContents, setDataContent] = useState<IContent[]>([]);
  const [canEdit, setCanEdit] = useState<0 | 1>(0);
  const [canDelete, setCanDelete] = useState<0 | 1>(0);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPageCount, setLastPageCount] = useState<number>(1);
  const limit = 8;
  const [search, setSearch] = useState<string>('');
  const [type, setType] = useState(-1);

  const getAllUserApi = async () => {
    dispatch({ type: SET_LOADING, payload: true });
    const response = await getListContent(search, page, limit, type, 1, userId);
    setDataContent(response?.data?.data);
    setLastPageCount(response?.data?.last_page);
    setCanDelete(response?.data?.is_delete_all);
    setCanEdit(response?.data?.is_edit_all);
    setTotal(response?.data?.total);
    dispatch({ type: SET_LOADING, payload: false });
  };

  const setPermissionUserContent = async (
    can_edit?: 0 | 1,
    can_delete?: 0 | 1,
    id?: number,
    isSetAll?: 0 | 1
  ) => {
    dispatch({ type: SET_LOADING, payload: true });
    const params = {
      set_all: isSetAll,
      content_id: id,
      can_edit: can_edit,
      can_delete: can_delete,
    };
    try {
      const response = await editPermission(params);
      getAllUserApi();
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error: any) {
      toast.error(error);
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
  useEffect(() => {
    getAllUserApi();
  }, [userId, page]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
  };

  return (
    <div>
      <Dialog className="p-4" open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          コンテンツに対する権限設定
        </DialogTitle>
        <DialogContent>
          <Table
            dataContents={dataContents}
            canDelete={canDelete}
            canEdit={canEdit}
            setPermissionUserContent={setPermissionUserContent}
          />
          <div className="pagination">
            <Pagination
              limit={limit}
              total={total}
              onChange={onChangePaginate}
              page={page}
              hidden={dataContents.length === 0}
              lastPage={lastPageCount}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContentModalComponent;
