import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import ButtonCustomer from 'components/button';
import InforIcon from 'assets/icons/infor-icon.svg';
import { useState } from 'react';
import * as Yup from 'yup';

export interface DeleteUserDialogProps {
  open: boolean;
  onClose: () => void;
  onHandlerDelete: (reason: string) => void;
  title?: string;
  content?: string;
}

const INPUT_MAX_LENGTH = 255;

function DeleteUserDialog(props: DeleteUserDialogProps) {
  const { onHandlerDelete, onClose, open } = props;
  const [reason, setReason] = useState('');
  const [validateError, setValidateError] = useState('');

  const checkCanSubmit = () => {
    return reason.length > 0;
  };

  const closeDialog = () => {
    setReason('');
    onClose();
    setValidateError('');
  };

  const submitDelete = () => {
    console.log(reason);
    onHandlerDelete(reason);
    setReason('');
  };

  const validationSchema = Yup.string()
    .trim()
    .required()
    .max(INPUT_MAX_LENGTH, '255文字以内を入力してください。');

  const validate = () => {
    try {
      console.log('dss');
      validationSchema.validateSync(reason);
      console.log(reason);
      submitDelete();
    } catch (error) {
      if (error instanceof Yup.ValidationError) setValidateError(error.message);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '22px',
        },
      }}
    >
      {props.title && <DialogTitle>警告</DialogTitle>}
      <DialogContent
        style={{
          paddingTop: '60px',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <img
          src={InforIcon}
          alt="default"
          className="mx-auto mb-8 rounded-md"
          style={{ width: '125px', height: '100px' }}
        />
        <div className="text-center text-22 font-normal">
          {props.content ??
            'このアカウントを無効にしてもよろしいですか? 同意する場合は、理由を入力してOKボタンを押してください。'}
        </div>
        <div className="mb-8"></div>
        <TextField
          label="Reason"
          placeholder="Reason"
          id="Reason"
          name="Reason"
          multiline
          minRows={6}
          fullWidth
          onChange={(e) => {
            setReason(e.target.value);
          }}
          value={reason}
          error={validateError !== ''}
          helperText={validateError !== '' ? '理由が必要です。' : ''}
        />
      </DialogContent>
      <DialogActions
        className="mx-auto"
        style={{
          paddingBottom: '60px',
        }}
      >
        <ButtonCustomer
          color="w-[150px] h-[42px] rounded-[30px] border-2 border-black/10"
          rounded
          onClick={closeDialog}
        >
          キャンセル
        </ButtonCustomer>
        <ButtonCustomer
          disabled={!checkCanSubmit()}
          color="bg-main_1 w-[150px] h-[42px] rounded-[30px] text-white"
          rounded
          onClick={validate}
        >
          OK
        </ButtonCustomer>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteUserDialog;
