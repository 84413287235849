import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import ViewCkEditor from 'components/ViewCKEditor';
import ButtonCustomer from 'components/button';
import SimpleDialog from 'components/dialog-simple';
import NoData from 'components/no-data';
import { deleteTemplate } from 'handler/inquiry';
import { ITemplate } from 'handler/inquiry/typings';
import moment from 'moment';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { removeTags } from 'utils';

interface Props {
  dataTemplate: ITemplate[];
  onEdit: (id: number) => void;
  onDeleteSuccess: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function Item(props: Props) {
  const [openDialog, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const { dataTemplate, onEdit, onDeleteSuccess } = props;
  const [isShowPre, setIsShowPre] = useState(false);
  const [dataPreview, setDataPreview] = useState<ITemplate | null>(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteTemplate = async () => {
    await deleteTemplate(deleteId);
    setOpen(false);
    toast.success('ニュースが削除されました。');
    onDeleteSuccess();
  };

  const onShowPre = (data: ITemplate) => {
    setDataPreview(data);
    setIsShowPre(true);
  };

  const handleClosePreview = () => {
    setIsShowPre(false);
    setDataPreview(null);
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="w-72">タイトル</TableCell>
            <TableCell className="truncate w-72">コンテンツ</TableCell>
            <TableCell align="left">作成日時</TableCell>
            <TableCell align="left">公開日時</TableCell>
            <TableCell align="center" className="w-[200px]">
              アクション
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTemplate.length > 0 &&
            dataTemplate.map((template: ITemplate) => (
              <TableRow key={template.id}>
                <TableCell>
                  <p className="w-72 truncate"> {template.title} </p>
                </TableCell>
                <TableCell>
                  <p className="truncate w-72">{removeTags(template.content)} </p>
                </TableCell>
                <TableCell align="left" size="small">
                  {moment(template.created_at).format('YYYY/MM/DD HH:mm')}
                </TableCell>
                <TableCell align="left" size="small">
                  {moment(template.updated_at).format('YYYY/MM/DD HH:mm')}
                </TableCell>
                <TableCell>
                  <div className="flex gap-2 justify-end w-[200px] items-center">
                    <ButtonCustomer
                      size="small"
                      rounded={true}
                      className="bg-green text-white "
                      onClick={() => onShowPre(template)}
                    >
                      プレビュー
                    </ButtonCustomer>

                    <ButtonCustomer
                      onClick={() => {
                        onEdit(template.id);
                      }}
                      size="small"
                      rounded={true}
                      className="bg-blue text-white "
                    >
                      <EditIcon fontSize="small" /> 編集
                    </ButtonCustomer>

                    <ButtonCustomer
                      onClick={() => {
                        setOpen(true);
                        setDeleteId(template.id);
                      }}
                      size="small"
                      rounded={true}
                      className="bg-red text-white"
                    >
                      削除
                    </ButtonCustomer>
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {dataTemplate.length === 0 && (
        <div>
          <NoData />
        </div>
      )}
      <SimpleDialog
        open={openDialog}
        onClose={handleClose}
        onOk={handleDeleteTemplate}
        content="この応答テンプレートを無効にしてもよろしいですか?"
      />

      {/* Modal preview  */}
      <BootstrapDialog
        onClose={handleClosePreview}
        aria-labelledby="customized-dialog-title"
        open={isShowPre}
        maxWidth="lg"
      >
        <div className="min-w-[30rem] min-h-[100px] p-10">
          <div className="flex flex-row-reverse">
            <HighlightOffIcon className="cursor-pointer" onClick={handleClosePreview} />
          </div>
          <div className="flex justify-center pb-10">
            <b className="text-[25px]">問い合わせ回答テンプレートプレビュー</b>
          </div>
          <div className="flex min-h-[50px] pb-10">
            <b className="min-w-[100px]">タイトル:</b>
            <p>{dataPreview?.title}</p>
          </div>
          <div className="flex min-h-[50px] pb-10">
            <b className="min-w-[100px]">コンテンツ:</b>
            <div className="min-w-[850px] max-w-[850px]  ">
              <ViewCkEditor valueEditor={dataPreview?.content || ''} />
            </div>
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
}

export default Item;
