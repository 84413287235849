import React, { useEffect, useState } from 'react';
import Title from 'components/title/title';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import Pagination from 'components/pagination';
import { getTemplate } from 'handler/inquiry';
import Table from './table';
import Create from './create';
import { ITemplate } from 'handler/inquiry/typings';
export const InquiryTemplateComponent = () => {
  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [dataTemplate, setDataTemplate] = useState<ITemplate[]>([]); //need set type
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const limit = 10;
  const [isEdit, setIsEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState<any>();

  const getListTemplateApi = async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response: any = await getTemplate({ page: page, limit: limit });
      setDataTemplate(response?.data?.data);
      setTotal(response?.data?.total);
      setLastPage(response?.data?.last_page);
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
  useEffect(() => {
    getListTemplateApi();
  }, [page]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
  };
  const onSuccess = () => {
    getListTemplateApi();
    onCloseDialog();
  };
  const handleCreateNew = () => {
    setOpenDialog(true);
    setIsEdit(false);
    setDataEdit(null);
  };
  const handleEdit = (id: number) => {
    setIsEdit(true);
    setOpenDialog(true);
    setDataEdit(dataTemplate.find((item) => item.id === id));
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
    setIsEdit(false);
    setDataEdit(null);
  };

  const onDeleteSuccess = () => {
    if (dataTemplate.length === 1 && page > 1) {
      setPage(page - 1);
      return;
    }
    getListTemplateApi();
  };

  return (
    <div>
      <div className="w-full h-main ">
        <div className="main-header">
          <Title title="問い合わせ回答テンプレート一覧" subTitle="" />
          <div className="header-actions">
            <div className="flex justify-between">
              <p className="text-16">問い合わせ回答テンプレート一覧</p>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                color="primary"
                onClick={handleCreateNew}
              >
                新規作成
              </Button>
            </div>
          </div>
        </div>
        <div className="main-body">
          <Table
            dataTemplate={dataTemplate}
            onEdit={handleEdit}
            onDeleteSuccess={onDeleteSuccess}
          />

          <div className="pagination">
            <Pagination
              limit={limit}
              total={total}
              onChange={onChangePaginate}
              page={page}
              hidden={dataTemplate.length === 0}
              lastPage={lastPage}
            />
          </div>
        </div>
        {openDialog && (
          <Create
            open={openDialog}
            handleClose={onCloseDialog}
            isEdit={isEdit}
            dataEdit={dataEdit}
            onSuccess={onSuccess}
          />
        )}
      </div>
    </div>
  );
};
