import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { getTokenInfo } from 'utils/auth/auth.utils';
import { ResponseCode } from './api.types';
import { toast } from 'react-toastify';
import qs from 'qs';
import localStorageHelper, { KeyStorage } from 'utils/localStorage';
interface CustomHeaders {
  isAuth: boolean;
}

const REQ_TIMEOUT = 25 * 1000;
export const __DEV__ = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  timeout: REQ_TIMEOUT,
});

instance.interceptors.request.use((_config) => requestHandler(_config));

const initHeader: CustomHeaders = { isAuth: true };

export const getAccessToken = async () => {
  const tokenInfo = await getTokenInfo();
  if (tokenInfo) {
    return tokenInfo?.accessToken;
  }
  return null;
};

export const getHeader = async (customHeaders?: CustomHeaders) => {
  const header: any = customHeaders || {};
  const initCustomHeader = customHeaders ? customHeaders : initHeader;
  if (!initCustomHeader?.isAuth) {
    delete header.Authorization;
  } else {
    const authToken = await getAccessToken();
    header.Authorization = `Bearer ${authToken}`;
  }
  return { ...header };
};

const requestHandler = (request: AxiosRequestConfig) => {
  return request;
};

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

const errorHandler = (error: AxiosError | any) => {
  const resError: any = null;

  const errorCode = error?.response?.status;

  if (errorCode === ResponseCode.UNAUTHORIZED) {
    // Unauthorized
    localStorageHelper.remove(KeyStorage.X_TOKEN);
    window.location.href = `/login`;
  } else if (
    // resError?.data?.code === ResponseCode.BAD_REQUEST ||
    errorCode === ResponseCode.NOT_FOUND ||
    errorCode === ResponseCode.PERMISSION
  ) {
    // Show notifications
  }
  if (error && error.message && !error?.response?.data?.data?.invalid_content) {
    toast.error(
      error?.response?.data?.error?.content ||
        error?.response?.data?.message ||
        'Something went wrong'
    );
  }

  if (error?.response?.data?.data?.invalid_content) {
    const listError = error?.response?.data;
    const listRowError = listError?.data?.invalid_content
      .map((item: any) => {
        let textError = '';
        for (const errorName in item?.errors) {
          textError = textError + item?.errors[errorName][0] + '\n';
        }
        return ` ライン: ${item.row} \n ${textError}`;
      })
      .join(' ');

    toast.error(`アップロードエラー: \n ${listRowError} `, {
      autoClose: 3000,
      style: {
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'center',
        width: '500px',
        marginTop: '30%',
        maxHeight: '400px',
        overflowY: 'scroll',
      },
      position: 'top-center',
      progress: '0',
      icon: false,
    });

    toast.error('ファイルのアップロードに失敗しました');
  }

  return Promise.reject({ ...error?.response });
};

const successHandler = async (response: AxiosResponse) => {
  const data: any = response.data;
  if (!data || data.status === 'INVALID_TOKEN' || data.code === ResponseCode.UNAUTHORIZED) {
    return;
  }
  return response;
};

async function get<ReqType, ResType>(
  url: string,
  params?: ReqType,
  customHeaders?: CustomHeaders,
  responseType?: ResponseType
): Promise<ResType> {
  const headers = await getHeader(customHeaders);

  return instance.get(url, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers,
    responseType,
  });
}

async function post<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: CustomHeaders
): Promise<ResType> {
  const headers = await getHeader(customHeaders);

  return instance.post(url, { ...data }, { headers });
}

async function postForm<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: CustomHeaders
): Promise<ResType> {
  const headers = await getHeader(customHeaders);
  return instance.post(url, data, { headers });
}

async function put<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: CustomHeaders
): Promise<ResType> {
  const headers = await getHeader(customHeaders);
  return instance.put(url, { ...data }, { headers });
}

async function remove<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: CustomHeaders
): Promise<ResType> {
  const headers = await getHeader(customHeaders);
  return instance.delete(url, { data: { ...data }, headers: { ...headers } });
}

const ApiUtils = { get, post, put, postForm, remove };
export default ApiUtils;
