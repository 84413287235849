import { CSSProperties } from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import { useAppSelector } from 'stores/hooks';
import { RootState } from 'stores/store';
const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

export default function Loading() {
  const loadingState = useAppSelector((state: RootState) => state.app.loading);

  // useEffect(() => {}, [loadingState]);
  return (
    <div className="">
      {loadingState && (
        <div>
          <div className="h-screen w-screen bg-gray fixed top-0 opacity-50"></div>
          <div className="z-50 text-red fixed top-1/2 left-1/2 right-1/2">
            <FadeLoader color={'#2E253D'} loading={loadingState} cssOverride={override} />
          </div>
        </div>
      )}
    </div>
  );
}
