import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import NoData from 'components/no-data';
import RevenueDetail from './revenue-detail';
import { IUserRevenue } from 'handler/bonus-management/typings';

interface Props {
  dataUser: IUserRevenue[];
  onRestoreSuccess: () => void;
}

function TableUser(props: Props) {
  const { dataUser } = props;
  const [userId, setUserId] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ユーザー名</TableCell>
            <TableCell>広告収入</TableCell>
            <TableCell>ダウンロード収入</TableCell>
            <TableCell>総収入</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataUser.length > 0 &&
            dataUser.map((user: IUserRevenue) => (
              <TableRow key={user.id}>
                <TableCell className="w-64 bigDesktop:w-96">
                  <div
                    className="text-blue hover:underline font-bold cursor-pointer"
                    onClick={() => {
                      setUserId(user.user_id);
                      setModalVisible(true);
                    }}
                  >
                    {user?.name}
                  </div>
                </TableCell>
                <TableCell>￥{user.total_ads_revenue}</TableCell>
                <TableCell className="w-64 bigDesktop:w-96">
                  ￥{user.total_download_revenue}
                </TableCell>
                <TableCell>￥{user.total_revenue}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {dataUser.length === 0 && (
        <div>
          <NoData />
        </div>
      )}

      <Dialog
        onClose={() => {
          setModalVisible(false);
        }}
        open={modalVisible}
        fullWidth
        maxWidth="md"
        className="revenue-dialog"
      >
        <DialogContent>
          <div className="h-[82vh] bigDesktop:h-[73vh]">
            <RevenueDetail
              userId={userId}
              onClose={() => {
                setModalVisible(false);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TableUser;
