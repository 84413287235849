import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';
import PercentIcon from '@mui/icons-material/Percent';
import Input from '@mui/joy/Input';
import ButtonCustomer from 'components/button';
import Title from 'components/title/title';
import { getRevenuesSettingCurrent, updateRevenuesSetting } from 'handler/bonus-management';
import { ICostSetting } from 'handler/bonus-management/typings';
import React, { useEffect, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { toast } from 'react-toastify';
import { addDots } from 'utils';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatAdapter(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        max={100}
        min={0}
        isAllowed={(values) => {
          const floatValue = values.floatValue ?? 0;
          return floatValue <= 100;
        }}
      />
    );
  }
);
const CostSetting = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      operating_cost: '0',
      jasrac_ads_royalty_fee: '0',
      nextone_ads_royalty_fee: '0',
      brokerage_fee: '0',
      jasrac_foreign_music_royalty_fee: '0',
      nextone_foreign_music_royalty_fee: '0',
      jasrac_domestic_music_royalty_fee: '0',
      nextone_domestic_music_royalty_fee: '0',
      transfer_fee: '0',
      tax: '0',
    },
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    callApiDetail();
  }, []);

  const callApiDetail = async () => {
    try {
      const { data } = await getRevenuesSettingCurrent();
      console.log('data', data);
      const formData = getValues();
      Object.keys(formData).forEach((element) => {
        setValue(element as keyof typeof formData, data[element as keyof ICostSetting]);
      });
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const callApiUpdate = async (dataUpdate: object) => {
    try {
      const data = await updateRevenuesSetting(dataUpdate);
      if (data?.success) {
        toast.success('情報が更新されました');
        setIsEdit(false);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const onSubmit = (data: any) => {
    callApiUpdate(getValues());
  };

  const changeFormatNumber = (e: any) => {
    const onlyNumber = e.target.value.replace(/\D/g, '');
    return addDots(onlyNumber);
  };

  const checkValidate = () => {
    if (
      getValues().jasrac_ads_royalty_fee === '' ||
      getValues().nextone_ads_royalty_fee === '' ||
      getValues().brokerage_fee === '' ||
      getValues().jasrac_domestic_music_royalty_fee === '' ||
      getValues().nextone_domestic_music_royalty_fee === '' ||
      getValues().jasrac_foreign_music_royalty_fee === '' ||
      getValues().nextone_foreign_music_royalty_fee === '' ||
      String(getValues().operating_cost) === ''
    ) {
      return false;
    } else {
      return true;
    }
  };

  watch([
    'jasrac_ads_royalty_fee',
    'nextone_ads_royalty_fee',
    'brokerage_fee',
    'jasrac_foreign_music_royalty_fee',
    'nextone_foreign_music_royalty_fee',
    'jasrac_domestic_music_royalty_fee',
    'nextone_domestic_music_royalty_fee',
    'operating_cost',
  ]);

  return (
    <div className="w-full h-main bg-white user-container">
      <div className="main-header">
        <Title title="コスト設定" />
      </div>
      <div className="flex justify-between">
        <div className="main-body pt-10 pl-20">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col max-w-[360px]">
              <div className="pb-4">
                <p className="text-left pb-2">運営経費</p>
                <Input
                  {...register('operating_cost')}
                  onChange={(e) => setValue('operating_cost', e.target.value.replace(/\D/g, ''))}
                  endDecorator={<CurrencyYenIcon />}
                  disabled={!isEdit}
                />
              </div>
              <div className="pb-4">
                <p className="text-left pb-2">広告に対する著作権使用料（Jasracコンテンツ用）</p>
                <Input
                  {...register('jasrac_ads_royalty_fee')}
                  onChange={(e) => {
                    setValue('jasrac_ads_royalty_fee', e.target.value);
                  }}
                  endDecorator={<PercentIcon />}
                  slotProps={{
                    input: {
                      component: NumericFormatAdapter,
                      value: getValues().jasrac_ads_royalty_fee,
                    },
                  }}
                  disabled={!isEdit}
                />
              </div>
              <div className="pb-4">
                <p className="text-left pb-2">広告に対する著作権使用料（Nextoneコンテンツ用）</p>
                <Input
                  {...register('nextone_ads_royalty_fee')}
                  onChange={(e) => setValue('nextone_ads_royalty_fee', e.target.value)}
                  endDecorator={<PercentIcon />}
                  disabled={!isEdit}
                  slotProps={{
                    input: {
                      component: NumericFormatAdapter,
                      value: getValues().nextone_ads_royalty_fee,
                    },
                  }}
                />
              </div>
              <div className="pb-4">
                <p className="text-left pb-2">売買仲介手数料</p>
                <Input
                  {...register('brokerage_fee')}
                  onChange={(e) => setValue('brokerage_fee', e.target.value.replace(/\D/g, ''))}
                  endDecorator={<PercentIcon />}
                  disabled={!isEdit}
                />
              </div>
              <div className="pb-4">
                <p className="text-left pb-2">ダウンロードJasracコンテンツの著作権使用料（国内）</p>
                <Input
                  {...register('jasrac_domestic_music_royalty_fee')}
                  onChange={(e) => setValue('jasrac_domestic_music_royalty_fee', e.target.value)}
                  endDecorator={<PercentIcon />}
                  slotProps={{
                    input: {
                      component: NumericFormatAdapter,
                      value: getValues().jasrac_domestic_music_royalty_fee,
                    },
                  }}
                  disabled={!isEdit}
                />
              </div>
              <div className="pb-4">
                <p className="text-left pb-2">
                  ダウンロードNextoneコンテンツの著作権使用料（国内）
                </p>
                <Input
                  {...register('nextone_domestic_music_royalty_fee')}
                  onChange={(e) => setValue('nextone_domestic_music_royalty_fee', e.target.value)}
                  endDecorator={<PercentIcon />}
                  disabled={!isEdit}
                  slotProps={{
                    input: {
                      component: NumericFormatAdapter,
                      value: getValues().nextone_domestic_music_royalty_fee,
                    },
                  }}
                />
              </div>
              <div className="pb-4">
                <p className="text-left pb-2">ダウンロードJasracコンテンツの著作権使用料（海外）</p>
                <Input
                  {...register('jasrac_foreign_music_royalty_fee')}
                  onChange={(e) => setValue('jasrac_foreign_music_royalty_fee', e.target.value)}
                  endDecorator={<PercentIcon />}
                  disabled={!isEdit}
                  slotProps={{
                    input: {
                      component: NumericFormatAdapter,
                      value: getValues().jasrac_foreign_music_royalty_fee,
                    },
                  }}
                />
              </div>
              <div className="pb-4">
                <p className="text-left pb-2">
                  ダウンロードNextoneコンテンツの著作権使用料（海外）
                </p>
                <Input
                  {...register('nextone_foreign_music_royalty_fee')}
                  onChange={(e) => setValue('nextone_foreign_music_royalty_fee', e.target.value)}
                  endDecorator={<PercentIcon />}
                  disabled={!isEdit}
                  slotProps={{
                    input: {
                      component: NumericFormatAdapter,
                      value: getValues().nextone_foreign_music_royalty_fee,
                    },
                  }}
                />
              </div>
              <div className="pb-4">
                <p className="text-left pb-2">送金手数料</p>
                <Input
                  {...register('transfer_fee')}
                  onChange={(e) => setValue('transfer_fee', e.target.value.replace(/\D/g, ''))}
                  endDecorator={<CurrencyYenIcon />}
                  disabled={!isEdit}
                />
              </div>
              <div className="pb-4">
                <p className="text-left pb-2">消費税</p>
                <Input
                  {...register('tax')}
                  onChange={(e) => setValue('tax', e.target.value.replace(/\D/g, ''))}
                  endDecorator={<PercentIcon />}
                  disabled={!isEdit}
                />
              </div>

              <div className="flex justify-center w-[360px] py-5">
                {isEdit ? (
                  <>
                    <ButtonCustomer
                      onClick={() => setIsEdit(false)}
                      className="mx-2 w-32 border-2 border-gray rounded-3xl"
                      rounded
                      type="button"
                    >
                      キャンセル
                    </ButtonCustomer>
                    <ButtonCustomer
                      rounded
                      type="submit"
                      className="mx-2 w-32 rounded-3xl bg-main_1 text-white"
                      disabled={!checkValidate()}
                    >
                      保存
                    </ButtonCustomer>
                  </>
                ) : (
                  <ButtonCustomer
                    onClick={() => setIsEdit(true)}
                    rounded={true}
                    className="mx-2 w-32 rounded-3xl bg-blue text-white "
                    type="button"
                  >
                    編集
                  </ButtonCustomer>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CostSetting;
