import Input from '@mui/joy/Input';
import Title from 'components/title/title';
import React, { useState } from 'react';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateRevenuesSystemAds } from 'handler/bonus-management';
import ButtonCustomer from 'components/button';

const AdsRevenueSetting = () => {
  const [price, setPrice] = useState('0');
  const date = new Date();
  const currentMonth = date.toISOString().slice(0, 7).replace('-', '/');
  const lastMonth = date.getFullYear() + '/' + date.getMonth();
  const isDisableInput = Number(date.getDate()) > 20 ? true : false;

  const callApiUpdate = async (dataUpdate: object) => {
    try {
      const data = await updateRevenuesSystemAds(dataUpdate);
      toast.success('正常に更新されました');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const onSave = () => {
    const time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

    callApiUpdate({
      ads_revenue: price,
      time: time,
    });
  };

  return (
    <div className="w-full h-main bg-white user-container">
      <div className="main-header">
        <Title title="広告収入の設定" />
      </div>
      <div className="main-body">
        <div className="flex flex-col items-start mt-5">
          <p>{lastMonth} 収益:</p>
          <div className="pl-5">
            <ul className="list-disc">
              <li>
                {date.getMonth()} 月の収益は {currentMonth}
                /20 までに入力する必要があります
              </li>
            </ul>
          </div>
          <div className="my-5">
            <Input
              value={price}
              onChange={(e) => setPrice(e.target.value.replace(/\D/g, ''))}
              endDecorator={<CurrencyYenIcon />}
              className="w-[400px]"
              disabled={isDisableInput}
            />
          </div>
          <p>ご注意:</p>
          <div className="pl-5">
            <ul className="list-disc">
              <li>
                {date.getMonth()} 月の収益は {currentMonth}
                /20 までに入力する必要があります
              </li>
            </ul>
          </div>
          <Link to="/ads-revenue-setting/history" className="text-blue underline mt-5">
            詳しい広告収益履歴はこちらからご覧ください
          </Link>
          <div className="w-[400px] ">
            <ButtonCustomer
              rounded
              type="submit"
              className="mx-2 w-32 rounded-3xl bg-main_1 text-white mt-16"
              onClick={onSave}
              disabled={price.length === 0 || isDisableInput}
            >
              保存
            </ButtonCustomer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsRevenueSetting;
