import { API_USER } from 'constant/api/user';
import { IResponse, IResponseBase } from 'models/common';
import ApiUtils from 'utils/api/api.utils';
import { ICostSetting, IFee } from './typings';

export const getListUserRevenue = async (page: number, limit: number, searchKey: string) => {
  const params = {
    page: page,
    limit: limit,
    search_key: searchKey,
  };
  const response: IResponse = await ApiUtils.get(API_USER.GET_ALL_REVENUES, params, {
    isAuth: true,
  });

  return response?.data;
};

export const getUserRevenues = async (
  page: number,
  limit: number,
  searchKey: string,
  userId: number
) => {
  const params = {
    page: page,
    limit: limit,
    search: searchKey,
    user_id: userId,
  };
  const response: IResponse = await ApiUtils.get(API_USER.GET_USER_REVENUES, params, {
    isAuth: true,
  });

  return response?.data;
};

export const getDownloadContent = async (
  page: number,
  limit: number,
  searchKey: string,
  date: string,
  userId: number
) => {
  const params = {
    page: page,
    limit: limit,
    search: searchKey,
    date: date,
    user_id: userId,
  };
  const response: IResponse = await ApiUtils.get(API_USER.GET_DOWNLOAD_CONTENT, params, {
    isAuth: true,
  });

  return response?.data;
};

export const getDownloadContentHistories = async (
  page: number,
  limit: number,
  searchKey: string,
  contentId: number,
  date: string
) => {
  const params = {
    page: page,
    limit: limit,
    search: searchKey,
    date: date,
  };
  const response: IResponse = await ApiUtils.get(
    `/admin/content/${contentId}/download-history`,
    params,
    {
      isAuth: true,
    }
  );

  return response?.data;
};

export const getRevenuesClaims = async (
  page: number,
  limit: number,
  searchKey: string,
  userId: number
) => {
  const params = {
    page: page,
    limit: limit,
    search: searchKey,
    user_id: userId,
  };
  const response: IResponse = await ApiUtils.get(API_USER.GET_REVENUES_CLAIMS, params, {
    isAuth: true,
  });

  return response?.data;
};

export const getRevenuesSettingCurrent = async () => {
  const response: IResponse = await ApiUtils.get(API_USER.GET_REVENUES_SETTING_CURRENT, {
    isAuth: true,
  });

  return response?.data as IResponseBase<ICostSetting>;
};

export const updateRevenuesSetting = async (data: object) => {
  const response: IResponse = await ApiUtils.put(API_USER.UPDATE_REVENUES_SETTING, data, {
    isAuth: true,
  });

  return response?.data;
};

export const updateRevenuesSystemAds = async (data: object) => {
  const response: IResponse = await ApiUtils.put(API_USER.UPDATE_REVENUES_SYSTEM_ADS, data, {
    isAuth: true,
  });

  return response?.data;
};

export const getRevenuesSystemAds = async (params: object) => {
  const response: IResponse = await ApiUtils.get(API_USER.GET_REVENUES_SYSTEM_ADS, params, {
    isAuth: true,
  });

  return response?.data;
};
