import NEWS_API from 'constant/api/news';
import { IResponse } from 'models/common';
import ApiUtils from 'utils/api/api.utils';
import { ICreateNewsRequest } from './typings';

export const getListNews = async (page: number, limit: number) => {
  const params = {
    page: page,
    limit: limit,
    order_by: 'id',
    order_direction: 'desc',
  };
  const response: IResponse = await ApiUtils.get(NEWS_API.GET, params, {
    isAuth: true,
  });
  return response.data;
};

export const createNews = async (data: ICreateNewsRequest) => {
  const response: IResponse = await ApiUtils.post(NEWS_API.CREATE, data, {
    isAuth: true,
  });
  return response.data;
};

export const updateNews = async (data: ICreateNewsRequest, id: number | undefined) => {
  const response: IResponse = await ApiUtils.put(NEWS_API.UPDATE + '/' + id, data, {
    isAuth: true,
  });
  return response.data;
};

export const deleteNews = async (id: number | undefined) => {
  const response: IResponse = await ApiUtils.remove(NEWS_API.DELETE + '/' + id, {
    isAuth: true,
  });
  return response.data;
};

export const toggleNews = async (id: number | undefined) => {
  const response: IResponse = await ApiUtils.put(NEWS_API.PUT_TOGGLE_NEW + '/' + id, {
    isAuth: true,
  });
  return response.data;
};
