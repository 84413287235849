import { NavLink, useLocation, useParams, useRoutes } from 'react-router-dom';
import React, { useEffect, useReducer, useState } from 'react';
import { privateRouters } from 'routers/const';
import { List, ListItemButton, ListItemIcon, Collapse } from '@mui/material';
import { IRouter } from 'routers/const';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DotIcon from 'assets/icons/dot.svg';
import './index.scss';
import { getInquiryUnreadCount } from 'handler/inquiry';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { saveTotalUnreadInquiry } from 'stores/user/reducer';
import { useSelector } from 'react-redux';
import useIdleTimer from 'hooks/useIdleTimer';

const SidebarItem = (props: {
  route: IRouter;
  isChildren: boolean;
  totalUnreadInquiry?: number;
}) => {
  const { route, isChildren, totalUnreadInquiry } = props;

  return (
    <ListItemButton className="">
      <NavLink
        key={route.path}
        to={route.path}
        className={(navData) => (navData.isActive ? 'router-link active-router' : 'router-link')}
      >
        {route.icon ? (
          <img src={route.icon} alt="" width={20} />
        ) : (
          <img src={DotIcon} alt="" width={5} className="ml-7" />
        )}
        <span>{route.title}</span>
        {route?.iconNotify && !!totalUnreadInquiry && (
          <div className="text-white text-[12px] bg-red w-[30px] h-[30px] border-re flex justify-center items-center rounded-full">
            {totalUnreadInquiry}
          </div>
        )}
      </NavLink>
    </ListItemButton>
  );
};

const SidebarItemCollapse = (props: { route: IRouter; children: IRouter[] }) => {
  const { children, route } = props;
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div className="overflow-hidden sidebar-collapse">
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <div className={`router-link`}>
            <img src={route.icon} alt="" width={20} />
            <span className="">{route.title}</span>
          </div>
        </ListItemIcon>
        <div className="absolute right-6">{open ? <ExpandLess /> : <ExpandMore />}</div>
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        <List component="div" disablePadding>
          {children.map((route, index) => (
            <SidebarItem route={route} key={index} isChildren={true} />
          ))}
        </List>
      </Collapse>
    </div>
  );
};

const Sidebar = () => {
  useIdleTimer();
  const location = useLocation();
  const dispatch = useDispatch();
  const totalUnreadNotify = useSelector((state: any) => state?.authSaga?.totalUnreadNotify);

  useEffect(() => {
    callApiUnreadInquiry();
  }, [location?.pathname]);

  useEffect(() => {
    if (totalUnreadNotify !== 0) {
      toast.success('ユーザーからの通知があります');
    }
  }, [totalUnreadNotify]);

  const callApiUnreadInquiry = async () => {
    try {
      const data = await getInquiryUnreadCount();
      dispatch(saveTotalUnreadInquiry(data?.data?.count));
    } catch (error: any) {
      // toast.error(error.message);
    }
  };

  return (
    <>
      <div className={`w-2/12 h-auto pt-28 sidebar-container`}>
        <div className="bg-white h-main">
          <List component={'nav'}>
            {privateRouters.map((route, index) => {
              if (route.hidden) return null;
              if (route.children) {
                return <SidebarItemCollapse children={route.children} key={index} route={route} />;
              }

              return (
                <SidebarItem
                  totalUnreadInquiry={totalUnreadNotify}
                  route={route}
                  key={index}
                  isChildren={false}
                />
              );
            })}
          </List>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
