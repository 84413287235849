import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { toggleBanner } from 'handler/banner';
import { toast } from 'react-toastify';
import ButtonCustomer from 'components/button';
import SimpleDialog from 'components/dialog-simple';
import s from './create.module.scss';
interface Props {
  id: number;
  imageUrl: string;
  url: string;
  disabled_at: string | null;
  onEdit: (id: number) => void;
  onDeleteSuccess: () => void;
}

function Item(props: Props) {
  const [openDialog, setOpen] = React.useState(false);

  const handleStop = async () => {
    await toggleBanner(props.id);
    setOpen(false);
    toast.success('バナーが停止されました。');
    props.onDeleteSuccess();
  };

  const handlePublic = async () => {
    await toggleBanner(props.id);
    toast.success('バナーが公開されました。');
    props.onDeleteSuccess();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex my-1 gap-2 py-2 items-center border-t border-yellow">
      <div className={s.boxItemListBanner}>
        <img src={props.imageUrl} alt="banner" className="rounded-md" />
      </div>
      <a
        href={props.url}
        target="_blank"
        rel="noopener noreferrer"
        className="w-6/12 text-left text-11 hover:underline "
      >
        {props.url}
      </a>
      <div className="flex gap-2 items-center w-4/12 justify-end">
        <ButtonCustomer
          onClick={() => {
            props.onEdit(props.id);
          }}
          size="small"
          rounded={true}
          className="bg-blue text-white "
        >
          <EditIcon fontSize="small" /> 編集
        </ButtonCustomer>

        {props?.disabled_at === null ? (
          <ButtonCustomer
            onClick={() => {
              setOpen(true);
            }}
            size="small"
            rounded={true}
            className="bg-red text-white"
          >
            停止
          </ButtonCustomer>
        ) : (
          <ButtonCustomer
            onClick={() => {
              handlePublic();
            }}
            size="small"
            rounded={true}
            className="bg-main_1 text-white"
          >
            公開
          </ButtonCustomer>
        )}
      </div>

      <SimpleDialog
        open={openDialog}
        onClose={handleClose}
        onOk={handleStop}
        content="このバナーを無効にしてもよろしいですか?"
      />
    </div>
  );
}

export default React.memo(Item);
