import React from 'react';
import './App.css';
import Footer from 'layout/footer';
import Header from 'layout/header';
import RoutersComponent from './routers/routers';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.min.css';
import Loading from 'components/loading';

function App() {
  return (
    <BrowserRouter>
      <div className="App bg-grey min-h-screen ">
        <ToastContainer position="top-right" newestOnTop autoClose={3000} />
        <Header />
        <div className="mx-auto px-5">
          <RoutersComponent />
        </div>
        <Footer />
        <Loading />
      </div>
    </BrowserRouter>
  );
}

export default App;
