import { InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import Title from 'components/title/title';
import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

import { getAllInquiry } from 'handler/inquiry';
import { IInquiry } from 'handler/inquiry/typings';
import Table from './table';
import ButtonCustomer from 'components/button';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import './index.scss';
import Pagination from 'components/pagination';
import { useSelector } from 'react-redux';

const LIMIT = 10;

const InquiryManagement = () => {
  const dispatch = useAppDispatch();
  const totalUnreadNotify = useSelector((state: any) => state?.authSaga?.totalUnreadNotify);
  const [dataInquiries, setDataInquiries] = useState<IInquiry[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [params, setParams] = useState({
    page: 1,
    search: '',
    type: -1,
  });

  const getAllInquiryApi = useCallback(async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await getAllInquiry(params.page, LIMIT, params.search, params.type);

      setDataInquiries(response?.data?.data);
      setTotal(response?.data?.total);
      setLastPage(response?.data?.last_page);

      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  }, [dispatch, params.page, params.search, params.type]);

  useEffect(() => {
    getAllInquiryApi();
  }, [getAllInquiryApi, params]);

  useEffect(() => {
    if (totalUnreadNotify !== 0) {
      getAllInquiryApi();
    }
  }, [totalUnreadNotify]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
    await setSearch(params.search);
    setParams((prev) => {
      return { ...prev, ...{ page: newPage } };
    });
  };

  const onChangeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClickSearch = (event?: React.KeyboardEvent<HTMLDivElement>) => {
    if (event?.key === 'Enter' || !event) {
      setPage(1);
      setParams((prev) => {
        return { ...prev, ...{ search: search }, ...{ page: 1 } };
      });
    }
  };

  return (
    <div className="w-full h-main bg-white user-container">
      <div className="main-header">
        <Title title="問い合わせ一覧" />
        <div className="header-actions flex justify-between items-center gap-2">
          <div className="w-[120px]">
            <Select
              id="type"
              name="type"
              className="w-full"
              value={params.type}
              onChange={(e: any) => {
                setParams((prev) => {
                  return { ...prev, ...{ type: e.target.value as number }, ...{ page: 1 } };
                });
              }}
            >
              <MenuItem value={-1}>全て</MenuItem>
              <MenuItem value={0}>未解決</MenuItem>
              <MenuItem value={1}>解決済み</MenuItem>
            </Select>
          </div>
          <div className="flex items-end gap-2 justify-end">
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="w-[262px] rounded-[10px]"
              onChange={onChangeSearchKey}
              onKeyDown={(e) => onClickSearch(e)}
              value={search}
              placeholder={'メールアドレス、質問など'}
              sx={{
                input: {
                  '&::placeholder': {
                    fontSize: 13,
                  },
                  paddingLeft: 0,
                },
                marginRight: 1,
              }}
            />
            <ButtonCustomer
              name="検索"
              size="medium"
              rounded={true}
              className="bg-blue text-white"
              onClick={() => onClickSearch()}
            />
          </div>
        </div>
      </div>
      <div className="main-body">
        <Table dataUser={dataInquiries} onRestoreSuccess={getAllInquiryApi} />

        <div className="pagination">
          <Pagination
            limit={LIMIT}
            total={total}
            onChange={onChangePaginate}
            page={page}
            lastPage={lastPage}
            hidden={dataInquiries.length === 0}
          />
        </div>
      </div>
    </div>
  );
};

export default InquiryManagement;
