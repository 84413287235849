import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';

import ButtonCustomer from 'components/button';
import { Dialog, Table, TableBody, TableCell, TableHead, TableRow, styled } from '@mui/material';
import { ICollections } from 'handler/collections/typings';
import { useNavigate } from 'react-router-dom';
import { getCollectionDetail, toggleCollections } from 'handler/collections';
import NoData from 'components/no-data';
import SimpleDialog from 'components/dialog-simple';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IContent } from 'handler/user-management/typings';
import Tag from 'components/tag';
import ContentList from 'pages/collection-detail/content-list';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';

interface Props {
  dataCollections: ICollections[];
  onDeleteSuccess: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function Item(props: Props) {
  const navigate = useNavigate();
  const [openDialog, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const { dataCollections, onDeleteSuccess } = props;
  const [dataPreview, setDataPreview] = useState<any | null>(null);
  const [isShowPre, setIsShowPre] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [type, setType] = useState('mannual');
  const [contents, setContents] = useState<IContent[]>([]);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleStop = async () => {
    await toggleCollections(deleteId);
    setOpen(false);
    onDeleteSuccess();
    toast.success('特集が停止されました。');
  };

  const handlePublic = async (id: number) => {
    await toggleCollections(id);
    toast.success('特集が公開されました。');
    onDeleteSuccess();
  };

  const onShowPre = async (dataCollection: ICollections) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const { data } = await getCollectionDetail(String(dataCollection.id));
      setDataPreview(data);
      if (data.tags) {
        const array_tag = data.tags.split(',');
        setTags(array_tag);
        setType('hashtag');
      } else {
        setType('mannual');
        setContents(data.contents);
      }
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }

    setIsShowPre(true);
  };

  const handleClosePreview = () => {
    setIsShowPre(false);
    setDataPreview(null);
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="w-72">タイトル</TableCell>
            <TableCell className="truncate">タイプ</TableCell>
            <TableCell align="center">ハッシュタグ</TableCell>
            <TableCell align="center">コンテンツ数</TableCell>
            <TableCell align="center" className="w-[200px]">
              アクション
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataCollections.length > 0 &&
            dataCollections.map((collection: ICollections) => (
              <TableRow key={collection.id}>
                <TableCell className="">
                  <p className="truncate w-72">{collection.title} </p>
                </TableCell>
                <TableCell>
                  <p className="truncate">{collection.longterm ? '常設特集' : '特集'} </p>
                </TableCell>
                <TableCell>
                  <p className="truncate text-center">{collection.tags ? 'はい' : 'いいえ'}</p>
                </TableCell>

                <TableCell align="center">
                  <p className="truncate">{collection.count_content}</p>
                </TableCell>

                <TableCell>
                  <div className="flex gap-2 justify-end items-center">
                    <ButtonCustomer
                      size="small"
                      rounded={true}
                      className="bg-green text-white "
                      onClick={() => onShowPre(collection)}
                    >
                      プレビュー
                    </ButtonCustomer>

                    <ButtonCustomer
                      onClick={() => {
                        navigate('/collection-detail/' + collection.id);
                      }}
                      size="small"
                      rounded={true}
                      className="bg-blue text-white "
                    >
                      <EditIcon fontSize="small" /> 編集
                    </ButtonCustomer>

                    {collection?.disabled_at === null ? (
                      <ButtonCustomer
                        onClick={() => {
                          setOpen(true);
                          setDeleteId(collection.id);
                        }}
                        size="small"
                        rounded={true}
                        className="bg-red text-white"
                      >
                        停止
                      </ButtonCustomer>
                    ) : (
                      <ButtonCustomer
                        onClick={() => {
                          handlePublic(collection.id);
                          setDeleteId(collection.id);
                        }}
                        size="small"
                        rounded={true}
                        className="bg-main_1 text-white"
                      >
                        公開
                      </ButtonCustomer>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {dataCollections.length === 0 && (
        <div>
          <NoData />
        </div>
      )}

      <SimpleDialog
        open={openDialog}
        onClose={handleClose}
        onOk={handleStop}
        content="この特集を無効にしてもよろしいですか?"
      />

      {/* Modal preview  */}
      <BootstrapDialog
        onClose={handleClosePreview}
        aria-labelledby="customized-dialog-title"
        open={isShowPre}
        maxWidth="lg"
      >
        <div className="w-[600px] min-h-[100px] p-10">
          <div className="flex flex-row-reverse">
            <HighlightOffIcon className="cursor-pointer" onClick={handleClosePreview} />
          </div>
          <div className="flex justify-center pb-10">
            <b className="text-[25px]">特集プレビュー</b>
          </div>
          <div className="flex min-h-[50px] pb-10">
            <b className="min-w-[150px]">特集サムネイル:</b>
            <img
              src={dataPreview?.thumbnail?.path}
              width={50}
              height={50}
              className="rounded-full"
              alt=""
            />
            <p></p>
          </div>
          <div className="flex min-h-[50px] pb-10">
            <b className="min-w-[150px]">特集タイトル:</b>
            <p>{dataPreview?.title}</p>
          </div>
          <div className="flex min-h-[50px] pb-10">
            <b className="min-w-[150px]">特集タイプ:</b>
            <p>{dataPreview?.longterm ? '常設特集' : '特集'}</p>
          </div>
          <div className="flex min-h-[50px] pb-10">
            <b className="min-w-[150px]">コンテンツ定義:</b>
            <p>{type === 'hashtag' ? 'ハッシュタグ利用' : 'コンテンツマニュアル追加'}</p>
          </div>
          <div className="flex min-h-[50px] ">
            <b className="min-w-[150px]">コンテンツ一覧:</b>
            <div className="w-auto">
              {type === 'hashtag' && (
                <div className="flex gap-1 flex-wrap max-h-[30px]">
                  {tags &&
                    tags.map((tag: string, index: number) => {
                      return <Tag key={index} name={tag} isShowIconClose={false} />;
                    })}
                </div>
              )}
            </div>
          </div>
          <div>{type !== 'hashtag' && <ContentList contents={contents} onlyViewList={true} />}</div>
        </div>
      </BootstrapDialog>
    </div>
  );
}

export default Item;
