import { createSlice } from '@reduxjs/toolkit';
import { KeyStorage } from 'utils/localStorage';
import localStorageHelper from 'utils/localStorage';

interface authState {
  userInfo: {
    name: string;
    avatar: string;
  } | null;
  auth: {
    access_token?: string | null;
    expires_in?: number;
    refresh_token?: string;
  };
  isLoading: boolean;
  error: string;
  isTokenExpired: boolean;
  totalUnreadNotify: number;
}

const initialState: authState = {
  userInfo: {
    name: 'Kubo',
    avatar: 'https://i.pinimg.com/564x/f1/94/2f/f1942f7d8cde470a861e20f85d732846.jpg',
  },
  auth: {
    access_token: localStorageHelper.get(KeyStorage.X_TOKEN)
      ? localStorageHelper.get(KeyStorage.X_TOKEN)
      : null,
  },
  isLoading: false,
  error: '',
  isTokenExpired: false,
  totalUnreadNotify: 0,
};

const sessionSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    getAuthLoading: (state) => {
      state.isLoading = true;
    },
    getAuthSuccess: (state, action?) => {
      state.auth = action?.payload;
      state.isLoading = false;
      state.userInfo = {
        name: 'Kubo',
        avatar: 'https://i.pinimg.com/564x/f1/94/2f/f1942f7d8cde470a861e20f85d732846.jpg',
      };
    },
    getAuthFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeAuth: (state) => {
      state.isLoading = false;
      state.error = '';
      state.auth = {};
      state.userInfo = null;
    },
    changeTokenExpired: (state) => {
      state.userInfo = null;
      state.error = '';
      state.isLoading = false;
      state.isTokenExpired = true;
    },
    saveTotalUnreadInquiry: (state, action) => {
      state.totalUnreadNotify = action.payload;
    },
  },
});

export const {
  getAuthLoading,
  getAuthSuccess,
  getAuthFailure,
  removeAuth,
  changeTokenExpired,
  saveTotalUnreadInquiry,
} = sessionSlice.actions;

export const isAuthenticated = (state: authState) => !!state.auth?.access_token;

export default sessionSlice.reducer;
