import { useState } from 'react';

import RevenueDetails from './Detail';
import RequestHistories from './History';

interface Props {
  userId: number;
  onClose: () => void;
}

const tabContent = [
  {
    key: 'revenue_detail',
    title: '詳細収入',
  },
  {
    key: 'request_histories',
    title: 'リクエスト履歴',
  },
];

function RevenueDetail(props: Props) {
  const { userId } = props;
  const [selectedTab, setSelectedTab] = useState('revenue_detail');

  const changeTab = (type: string) => {
    setSelectedTab(type);
  };

  return (
    <div>
      <div className="flex justify-between bg-[#f7f5ed]">
        <div className="flex space-x-2.5 text-lg font-semibold items-center">
          {tabContent.map((item) => (
            <div
              key={item.key}
              className={`flex justify-center min-w-[160px] rounded-t-10 text-20 ${
                selectedTab === item.key && 'bg-white text-[#F1A200]'
              } px-5 py-5 cursor-pointer`}
              onClick={() => changeTab(item.key)}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
      <div
        className="px-[20px] h-[420px] bigDesktop:h-[550px]"
        style={{
          overflow: 'auto',
        }}
      >
        {selectedTab === 'revenue_detail' ? (
          <RevenueDetails userId={userId} />
        ) : (
          <RequestHistories userId={userId} />
        )}
      </div>
    </div>
  );
}

export default RevenueDetail;
