import React, { useState, useEffect } from 'react';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import { useParams } from 'react-router-dom';
import Title from 'components/title/title';
import s from './style.module.scss';

import Card from 'components/card';
import CardTitle from 'components/card-title';
import { getContentDetail, putUpdateCopyRight } from 'handler/content/intex';
import { IContent } from 'handler/user-management/typings';
import ConstantData from 'constant/data';
import ContentType from 'components/content-type';
import ButtonCustomer from 'components/button';
import { Button, Checkbox, Dialog, DialogActions, DialogTitle, FormControlLabel, MenuItem, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import CONST_DATA from 'constant/data';
import { toast } from 'react-toastify';
import MusicFileIcon from 'assets/icons/music-file-icon.svg';
import { useForm } from 'react-hook-form';
import { JASRAC_SEARCH_URL, NEXTONE_SEARCH_URL } from 'utils';
import { Edit, SubdirectoryArrowRight } from '@mui/icons-material';
import Pagination from 'components/pagination';

const blockInfo = [
  { title: '表示名', key: 'display_name', type: 'text' },
  { title: 'コメント', key: 'comment', type: 'text' },
  { title: 'ファイルフォーマット', key: 'content_type', type: 'text' },
  { title: '税別価格', key: 'price', type: 'text' },
  { title: '税込み価格', key: 'taxed_price', type: 'text' },
  { title: '楽器メインカテゴリー', key: 'instrument_main_category_id', type: 'text' },
  { title: '楽器サブカテゴリー', key: 'instrument_sub_category_id', type: 'text' },
  { title: '音楽ジャンルメインカテゴリー', key: 'music_genre_main_category_id', type: 'text' },
  { title: '音楽サブカテゴリー', key: 'music_genre_sub_category_id', type: 'text' },
  { title: 'タグ', key: 'search_tags', type: 'array' },
  { title: 'タグ（フリーワード）', key: 'free_tag', type: 'array' },
  { title: '無効理由', key: 'reason_disable', type: 'text' },
];

const blockCopyRight = [
  { title: '著作権管理', key: 'copyright_management_id', type: 'text' },
  { title: 'JASRAC 作品コード', key: 'jasrac_work_code', type: 'text' },
  { title: 'NexTone 作品コード', key: 'nextone_work_code', type: 'text' },
  { title: '原題名（曲名）', key: 'song_name', type: 'text' },
  { title: 'アーティスト名（またはグループ名）', key: 'artist_name', type: 'text' },
  { title: 'IVT 区分', key: 'ivt_type_id', type: 'text' },
  { title: '作詞者名', key: 'author_name', type: 'text' },
  { title: '作曲者名', key: 'composer_name', type: 'text' },
  { title: '原詞/訳詞区分', key: 'translation_type_id', type: 'text' },
  { title: '採譜者名', key: 'transcriptionist', type: 'text' },
  { title: '編曲者名', key: 'translator_name', type: 'text' },
];

const ItemInfo = ({
  title,
  value,
  type,
  data,
}: {
  title: string;
  value: string;
  type: string;
  data: any;
}) => {
  const RenderTextByKey = () => {
    const { id } = useParams<{ id: string }>();
    const handleChangePageViewMusic = () => {
      window.open(`${process.env.REACT_APP_WEB_DOMAIN}/content/viewer/${id}`, '_blank');
    };

    switch (value) {
      case 'content_type':
        return (
          <div className="flex ">
            <ContentType type={data[value]} />
            <img
              src={MusicFileIcon}
              alt="icon"
              className="cursor-pointer pl-6"
              onClick={handleChangePageViewMusic}
            />
          </div>
        );
      case 'instrument_main_category_id':
        return (
          <span className="p-1.5 bg-grey">
            {
              ConstantData.instrument_main_categories.find((element) => element.id === data[value])
                ?.ja_name
            }
          </span>
        );
      case 'instrument_sub_category_id':
        return (
          <span className="p-1.5 bg-grey">
            {
              ConstantData.instrument_sub_categories.find((element) => element.id === data[value])
                ?.ja_name
            }
          </span>
        );
      case 'copyright_management_id':
        return (
          <span className="p-1.5 bg-grey">
            {
              ConstantData.copyright_managements.find((element) => element.id === data[value])
                ?.ja_name
            }
          </span>
        );

      case 'music_genre_main_category_id':
        return (
          <span className="p-1.5 bg-grey">
            {
              ConstantData.music_genre_main_categories.find((element) => element.id === data[value])
                ?.ja_name
            }
          </span>
        );

      case 'music_genre_sub_category_id':
        return (
          <span className="p-1.5 bg-grey">
            {
              ConstantData.music_genre_sub_categories.find((element) => element.id === data[value])
                ?.ja_name
            }
          </span>
        );

      case 'ivt_type_id':
        return (
          <span className="p-1.5 bg-grey">
            {ConstantData.ivt_types.find((element) => element.id === data[value])?.ja_name}
          </span>
        );
      case 'translation_type_id':
        return (
          <span className="p-1.5 bg-grey">
            {ConstantData.translation_types.find((element) => element.id === data[value])?.ja_name}
          </span>
        );
      case 'price':
        return <span>{data[value] ? `${data[value]} ¥` : 'N/A'} </span>;
      case 'taxed_price':
        return <span>{data[value] ? `${data[value]} ¥` : 'N/A'} </span>;
      default:
        return <span>{data[value] ? data[value] : 'N/A'} </span>;
    }
  };
  const RenderTextByArray = () => {
    switch (value) {
      case 'search_tags':
        if (data[value].length === 0) return <span>N/A</span>;
        return (
          <div className="flex flex-wrap">
            {data[value] &&
              data[value].map((item: any, index: number) => (
                <span className="p-1.5 bg-grey m-1" key={index}>
                  {
                    ConstantData.search_tags.find((element) => element.id === item.search_tag_id)
                      ?.ja_name
                  }
                </span>
              ))}
          </div>
        );
      case 'free_tag':
        if (!data[value]) return <span>N/A</span>;
        const tags = data[value].split(',');

        return (
          <div className="flex flex-wrap">
            {tags &&
              tags.map((item: string, index: number) => (
                <span className="p-1.5 bg-grey m-1" key={index}>
                  {item}
                </span>
              ))}
          </div>
        );
      default:
        return <span>Arrary</span>;
    }
  };
  return (
    <div className="mt-1 py-4 flex items-center line-in-bottom">
      <p className="text-14 font-bold w-3/12 text-left">{title}</p>
      <div className="text-left w-9/12">
        {type === 'text' ? <RenderTextByKey /> : <RenderTextByArray />}
      </div>
    </div>
  );
};

interface UploadType {
  copyright_management_id: string | null;
  nextone_work_code: string | null;
  jasrac_work_code: string | null;
  song_name: string | null;
  ivt_type_id: number | null;
  author_name: string | null;
  composer_name: string | null;
  artist_name: string | null;
  translation_type_id: number | null;
  transcriptionist: string | null;
  translator_name: string | null;
  translator_flag: number | null;
}

function ContentDetail() {
  const dispatch = useAppDispatch();
  //   const [searchParams] = useSearchParams;
  const { id } = useParams<{ id: string }>();
  const [modalVisible, setModalVisible] = useState(false);
  const [typeManagement, setTypeManagement] = useState(1);
  const [dataCopyRight, setDataCopyright] = useState<any>(null);
  const [translationTypeId, setTranslationTypeId] = useState(1);
  const [ivtTypeId, setIvtTypeId] = useState(1);
  const [showModalCopyRight, setShowModalCopyRight] = useState(false);
  const [copyrightDataList, setCopyrightDataList] = useState<any>(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [dataContent, setDataContent] = useState<IContent | any>();

  const getContentDetailApi = async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await getContentDetail(id);
      setDataContent(response?.data);
      setCopyrightDataList(response?.data.copyrights);

      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  const putUpdateCopyRightApi = async (data: UploadType) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });

      const response = await putUpdateCopyRight(dataCopyRight.id, {
        copyright_management_id: typeManagement,
        jasrac_work_code:
          typeManagement === 1 || typeManagement === 2 || typeManagement === 4
            ? null
            : data.jasrac_work_code,
        nextone_work_code:
          typeManagement === 1 || typeManagement === 2 || typeManagement === 3
            ? null
            : data.nextone_work_code,
        song_name: data?.song_name,
        ivt_type_id: ivtTypeId,
        author_name: data?.author_name,
        composer_name: data?.composer_name,
        artist_name: data?.artist_name,
        translation_type_id: translationTypeId,
        transcriptionist: data?.transcriptionist,
        translator_name: data?.translator_name,
        translator_flag: data?.translator_flag
      });

      dispatch({ type: SET_LOADING, payload: false });

      setModalVisible(false);
      toast.success('コンテンツが更新されました。');

      const responseContentDetail = await getContentDetail(id);
      setDataContent(responseContentDetail?.data);
      setCopyrightDataList(responseContentDetail?.data.copyrights)
      setValue('jasrac_work_code', responseContentDetail?.data?.jasrac_work_code);
      setValue('nextone_work_code', responseContentDetail?.data?.nextone_work_code);
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  useEffect(() => {
    getContentDetailApi();
  }, []);

  const onSubmit = (data: any) => {
    putUpdateCopyRightApi(data);
  };

  const handleShowDataCopyRight = (data: any) => {
    setDataCopyright(data);
    setShowModalCopyRight(true);
  };

  const handleShowEditCopyRight = (data: any) => {
    Object.keys(data).forEach(key => setValue(key, data[key]))
    setDataCopyright(data);
    setModalVisible(true);
  };
  // == handle pagination ==
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const pageItems = copyrightDataList?.slice(startIndex, endIndex);
  const totalPages = Math.ceil(copyrightDataList?.length / pageSize);

  const handlePageChange = (_: any, newPage: number) => {
    setCurrentPage(newPage);
  };
  // =======================
  return (
    <div className="bg-white p-8 h-main">
      <Title title="コンテンツ詳細" />
      <div className="mt-2">
        <Card className="">
          <CardTitle title="コンテンツ情報" />
          {dataContent &&
            blockInfo.map((item, index) => (
              <ItemInfo
                key={index}
                title={item.title}
                value={item.key}
                type={item.type}
                data={dataContent}
              />
            ))}
        </Card>
      </div>
      <div className="mt-4">
        <Card className="">
          <CardTitle title="著作権情報" />
          {dataContent &&
            pageItems.map((copyright: any, index: number) => {
              return (
                <div className="">
                  <div
                    key={index}
                    className="flex justify-between px-3 items-center border-[2px] border-[#0000001a] w-full h-[52px] rounded-[10px] mb-3"
                  >
                    <span>{`${index + 1}. ${copyright.song_name}`}</span>
                    <div className="w-[150px] flex justify-between">
                      <div
                        className="bg-green text-white p-1 rounded-[4px] cursor-pointer"
                        onClick={() => handleShowDataCopyRight(copyright)}
                      >
                        {' '}
                        <SubdirectoryArrowRight fontSize="small" /> &nbsp;詳細
                      </div>
                      <div
                        className="bg-blue text-white p-1 rounded-[4px] cursor-pointer"
                        onClick={() => handleShowEditCopyRight(copyright)}
                      >
                        <Edit fontSize="small" />
                        &nbsp;編集
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div className="flex pt-4">
            <ButtonCustomer
              className="bg-main_1 text-white"
              size="small"
              rounded
              onClick={() => {
                setModalVisible(true);
                setValue('translation_type_id', 2);
                setValue('ivt_type_id', dataContent?.ivt_type_id);
              }}
            >
              編集
            </ButtonCustomer>
          </div> */}
          <div className="pagination">
            <Pagination
              limit={pageSize}
              total={copyrightDataList?.length}
              onChange={handlePageChange}
              page={currentPage}
              hidden={pageItems?.length === 0}
              lastPage={totalPages}
            />
          </div>
        </Card>
      </div>

      <Dialog
        onClose={() => {
          setModalVisible(false);
          setTypeManagement(+dataCopyRight?.copyright_management_id);
        }}
        open={modalVisible}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">著作権情報を編集する</DialogTitle>
        <div className="h-min bg-white pl-5 pr-5 ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-between pt-10 items-center">
              <b>著作権管理</b>
              <div className="w-72">
                <Select
                  id="type"
                  name="type"
                  className="w-72"
                  value={dataCopyRight?.copyright_management_id}
                  onChange={(e: any) => {
                    setTypeManagement(e.target.value as number);
                  }}
                >
                  {CONST_DATA.copyright_managements.map((item) => {
                    return <MenuItem value={item.id}>{item.ja_name}</MenuItem>;
                  })}
                </Select>
              </div>
            </div>

            {(typeManagement === 3 || typeManagement === 5) && (
              <div className="flex justify-between pt-7 items-center">
                <b>JASRAC 作品コード </b>
                <div className="w-72">
                  <a
                    href={JASRAC_SEARCH_URL}
                    className={s.textLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ここでJASRAC 作品コードを検索
                  </a>
                  <TextField
                    id="jasrac_work_code"
                    fullWidth
                    {...register('jasrac_work_code', { required: true })}
                    error={errors.jasrac_work_code ? true : false}
                    helperText={errors.jasrac_work_code ? 'JASRAC 作品コードは必須項目です。' : ''}
                    placeholder="JASRAC 作品コード"
                    defaultValue={dataCopyRight?.jasrac_work_code}
                  />
                </div>
              </div>
            )}

            {(typeManagement === 4 || typeManagement === 5) && (
              <div className="flex justify-between pt-7 items-center">
                <b>NexTone 作品コード</b>
                <div className="w-72">
                  <a
                    href={NEXTONE_SEARCH_URL}
                    className={s.textLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ここでNexTone 作品コードを検索
                  </a>
                  <TextField
                    id="nextone_work_code"
                    fullWidth
                    {...register('nextone_work_code', { required: true })}
                    error={errors.nextone_work_code ? true : false}
                    helperText={
                      errors.nextone_work_code ? 'NexTone 作品コードは必須項目です。' : ''
                    }
                    placeholder="NexTone 作品コード"
                    defaultValue={dataCopyRight?.nextone_work_code}
                  />
                </div>
              </div>
            )}

            <div className="flex justify-between pt-7 items-center">
              <b>
                原題名（曲名）<span className="text-red">*</span>
              </b>
              <div className="w-72">
                <TextField
                  id="song_name"
                  fullWidth
                  {...register('song_name', { required: true })}
                  error={errors.song_name ? true : false}
                  helperText={errors.song_name ? '原題名（曲名）は必須項目です。' : ''}
                  placeholder="原題名"
                  defaultValue={dataCopyRight?.song_name}
                />
              </div>
            </div>

            <div className="flex justify-between pt-7 items-center">
              <b>アーティスト名（またはグループ名）</b>
              <div className="w-72">
                <TextField
                  id="artist_name"
                  fullWidth
                  {...register('artist_name')}
                  placeholder="アーティスト名"
                  defaultValue={dataCopyRight?.artist_name}
                />
              </div>
            </div>

            <div className="flex justify-between pt-7 items-center">
              <b>IVT 区分</b>
              <div className="w-72">
                <Select
                  id="ivt_type_id"
                  className="w-72"
                  value={dataCopyRight?.ivt_type_id}
                  onChange={(e: any) => {
                    setIvtTypeId(e.target.value as number);
                  }}
                >
                  {CONST_DATA.ivt_types.map((item) => {
                    return <MenuItem value={item.id}>{item.ja_name}</MenuItem>;
                  })}
                </Select>
              </div>
            </div>

            <div className="flex justify-between pt-7 items-center">
              <b>作詞者名</b>
              <div className="w-72">
                <TextField
                  id="author_name"
                  fullWidth
                  {...register('author_name', { required: ivtTypeId === 1 ? false : true })}
                  placeholder="作詞者名"
                  defaultValue={dataCopyRight?.author_name}
                  error={errors.author_name ? true : false}
                  helperText={errors.author_name ? '作詞者名は必須項目です。' : ''}
                />
              </div>
            </div>

            <div className="flex justify-between pt-7 items-center">
              <b>
                作曲者名 <span className="text-red">*</span>
              </b>
              <div className="w-72">
                <TextField
                  id="composer_name"
                  fullWidth
                  {...register('composer_name', { required: true })}
                  error={errors.composer_name ? true : false}
                  helperText={errors.composer_name ? '作曲者名は必須項目です。' : ''}
                  placeholder="作曲者名"
                  defaultValue={dataCopyRight?.composer_name}
                />
              </div>
            </div>

            <div className="flex justify-between pt-7 items-center">
              <b>
                採譜者名
              </b>
              <div className="w-72">
                <TextField
                  id="transcriptionist"
                  fullWidth
                  {...register('transcriptionist')}
                  placeholder="採譜者名"
                  defaultValue={dataCopyRight?.transcriptionist}
                />
              </div>
            </div>

            <div className="flex justify-between pt-7 items-center">
              <b>
                編曲者名
              </b>
              <div className="w-72">
                <TextField
                  id="translator_name"
                  fullWidth
                  {...register('translator_name')}
                  placeholder="編曲者名"
                  defaultValue={dataCopyRight?.translator_name}
                />
              </div>
            </div>

            <div className="flex justify-between pt-7 items-center">
              <FormControlLabel
                control={
                  <Checkbox
                  defaultChecked={!!getValues('translator_flag')}
                  
                    onChange={(e) => {
                      console.log(e.target.checked)
                      const isCheck = e.target.checked ? 1 : 0;
                      setValue('translator_flag', isCheck)
                    }}
                    // {...register('translator_name')}
                  />
                }
                label="JASRACやNexToneに登録されている編曲者"
              />
            </div>

            <div className="flex justify-between pt-7 items-center">
              <b>原詞/訳詞区分</b>
              <div className="w-72">
                <Select
                  id="translation_type_id"
                  className="w-72"
                  value={dataCopyRight?.translation_type_id}
                  onChange={(e: any) => {
                    setTranslationTypeId(e.target.value as number);
                  }}
                >
                  {CONST_DATA.translation_types.map((item) => {
                    return <MenuItem value={item.id}>{item.ja_name}</MenuItem>;
                  })}
                </Select>
              </div>
            </div>

            <div className="flex pt-10 pb-10 justify-center">
              <ButtonCustomer className="bg-main_1 text-white" size="small" rounded type="submit">
                保存
              </ButtonCustomer>
              <ButtonCustomer
                className="bg-red text-white ml-2"
                size="small"
                rounded
                type="button"
                onClick={() => {
                  setModalVisible(false);
                  setTypeManagement(+dataCopyRight?.copyright_management_id);
                }}
              >
                閉じる
              </ButtonCustomer>
            </div>
          </form>
        </div>
      </Dialog>

      <Dialog
        onClose={() => {
          setShowModalCopyRight(false);
        }}
        open={showModalCopyRight}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">著作権の詳細</DialogTitle>
        <div className="px-4">
          {blockCopyRight.map((item: any, index: any) => (
            <ItemInfo
              key={index}
              title={item.title}
              value={item.key}
              type={item.type}
              data={dataCopyRight}
            />
          ))}
        </div>
        <div className='flex justify-center py-3'>
          <div
            onClick={() => {
              setShowModalCopyRight(false);
            }}
            className="bg-red cursor-pointer text-white p-2 w-[85px] text-center rounded-[8px]"
          >
            OK
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ContentDetail;
