import { API_INQUIRY } from 'constant/api/inquiry';
import { IResponse } from 'models/common';
import ApiUtils from 'utils/api/api.utils';
import { ICreateTemplateRequest } from './typings';

export const getAllInquiry = async (
  page: number,
  limit: number,
  searchKey: string,
  type: number
) => {
  const params = {
    page: page,
    limit: limit,
    search_key: searchKey,
    is_answer: type >= 0 ? type : null,
  };

  const response: IResponse = await ApiUtils.get(API_INQUIRY.GET_ALL, params, {
    isAuth: true,
  });
  return response?.data;
};

export const getInquiryDetail = async (id: string | undefined) => {
  const response: IResponse = await ApiUtils.get(API_INQUIRY.GET_DETAIL + '/' + id, null, {
    isAuth: true,
  });
  return response?.data;
};

export const replyInquiry = async (id: string | undefined, answer: string) => {
  const params = {
    id,
    answer,
  };
  const response: IResponse = await ApiUtils.post(API_INQUIRY.ANSWER, params, {
    isAuth: true,
  });
  return response?.data;
};

export const getInquiryUnreadCount = async () => {
  const response: IResponse = await ApiUtils.get(API_INQUIRY.GET_INQUIRY_UNREAD_COUNT, {
    isAuth: true,
  });
  return response?.data;
};

export const getTemplate = async (params: { page?: number; limit?: number; paginate?: number }) => {
  const response: IResponse = await ApiUtils.get(API_INQUIRY.TEMPLATE, params, {
    isAuth: true,
  });
  return response?.data;
};
export const createTemplate = async (data: ICreateTemplateRequest) => {
  const response: IResponse = await ApiUtils.post(API_INQUIRY.CREATE, data, {
    isAuth: true,
  });
  return response.data;
};

export const updateTemplate = async (data: ICreateTemplateRequest, id: number | undefined) => {
  const response: IResponse = await ApiUtils.put(API_INQUIRY.UPDATE + '/' + id, data, {
    isAuth: true,
  });
  return response.data;
};

export const deleteTemplate = async (id: number | undefined) => {
  const response: IResponse = await ApiUtils.remove(API_INQUIRY.DELETE + '/' + id, {
    isAuth: true,
  });
  return response.data;
};
export const putInquiryRead = async (id: string) => {
  const response: IResponse = await ApiUtils.put(API_INQUIRY.PUT_INQUIRY_READ + '/' + id);
  return response?.data;
};
