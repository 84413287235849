// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__L3jR- {
  background-color: white;
  height: 80vh;
  display: flex;
  justify-content: start;
  padding: 40px;
  align-items: flex-start;
  flex-direction: column;
}
.style_container__L3jR- .style_boxselectDate__TiX-H {
  display: flex;
  align-items: center;
}
.style_container__L3jR- .style_boxselectDate__TiX-H .style_boxDate__m9F9e {
  padding-left: 20px;
}
.style_container__L3jR- .style_boxDownload__mlV88 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 30px;
}
.style_container__L3jR- .style_boxDownload__mlV88 .style_itemFile__7sTvX {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}
.style_container__L3jR- .style_boxDownload__mlV88 .style_itemFile__7sTvX .style_listFileDownload__E8pAY {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.style_container__L3jR- .style_boxDownload__mlV88 .style_itemFile__7sTvX .style_listFileDownload__E8pAY ul {
  padding-left: 20px;
}
.style_container__L3jR- .style_boxDownload__mlV88 .style_itemFile__7sTvX .style_listFileDownload__E8pAY ul li {
  text-align: left;
  list-style-type: disc;
}
.style_container__L3jR- .style_boxDownload__mlV88 .style_itemFile__7sTvX .style_listFileDownload__E8pAY ul li > span {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
.style_container__L3jR- .style_boxDownload__mlV88 .style_itemFile__7sTvX .style_listFileDownload__E8pAY ul li .style_countInfo__JFcYc {
  display: flex;
  gap: 40px;
}
.style_container__L3jR- .style_boxDownload__mlV88 .style_itemFile__7sTvX .style_itemName__kM9Ki {
  min-width: 150px;
  text-align: left;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/statistic/style.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;AAEJ;AADI;EACE,kBAAA;AAGN;AACE;EACE,aAAA;EACA,uBAAA;EAEA,sBAAA;EACA,iBAAA;AAAJ;AACI;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;AACN;AAAM;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;AAER;AADQ;EACE,kBAAA;AAGV;AADQ;EACE,gBAAA;EACA,qBAAA;AAGV;AAFU;EACE,WAAA;EACA,eAAA;EACA,0BAAA;AAIZ;AAFU;EACE,aAAA;EACA,SAAA;AAIZ;AAAM;EACE,gBAAA;EACA,gBAAA;EACA,iBAAA;AAER","sourcesContent":[".container {\n  background-color: white;\n  height: 80vh;\n  display: flex;\n  justify-content: start;\n  padding: 40px;\n  align-items: flex-start;\n  flex-direction: column;\n  .boxselectDate {\n    display: flex;\n    align-items: center;\n    .boxDate {\n      padding-left: 20px;\n    }\n  }\n\n  .boxDownload {\n    display: flex;\n    align-items: flex-start;\n\n    flex-direction: column;\n    padding-top: 30px;\n    .itemFile {\n      padding-top: 20px;\n      display: flex;\n      flex-direction: column;\n      .listFileDownload {\n        display: flex;\n        flex-direction: column;\n        align-items: start;\n        ul {\n          padding-left: 20px;\n        }\n        ul li {\n          text-align: left;\n          list-style-type: disc;\n          > span {\n            color: blue;\n            cursor: pointer;\n            text-decoration: underline;\n          }\n          .countInfo {\n            display: flex;\n            gap: 40px;\n          }\n        }\n      }\n      .itemName {\n        min-width: 150px;\n        text-align: left;\n        font-weight: bold;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__L3jR-`,
	"boxselectDate": `style_boxselectDate__TiX-H`,
	"boxDate": `style_boxDate__m9F9e`,
	"boxDownload": `style_boxDownload__mlV88`,
	"itemFile": `style_itemFile__7sTvX`,
	"listFileDownload": `style_listFileDownload__E8pAY`,
	"countInfo": `style_countInfo__JFcYc`,
	"itemName": `style_itemName__kM9Ki`
};
export default ___CSS_LOADER_EXPORT___;
