import React from 'react';
import { Pagination as MuiPagination } from '@mui/material';

interface Props {
  total: number;
  limit: number;
  page: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  hidden: boolean;
  lastPage?: number;
}
export default function Pagination(props: Props) {
  const { total, limit, page, onChange, hidden, lastPage } = props;
  const itemPage = lastPage === page ? total : limit + (page - 1) * limit;
  return (
    <>
      {Math.ceil(total / limit) >= 2 ? (
        <div className={`flex items-center gap-2 ${hidden ? 'hidden' : ''}`}>
          <span>
            {1 + (page - 1) * limit}~{itemPage} 件 / {total}件
          </span>
          <MuiPagination
            count={Math.ceil(total / limit)}
            variant="outlined"
            shape="rounded"
            onChange={onChange}
            page={page}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
