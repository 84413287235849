const API_USER_REVENUE = {
  GET_ALL: '/admin/revenues/claims',
  GET_DETAIL: '/admin/users/detail',
  GET_CONTENT: '/admin/users',
  RESTORE_USER: '/admin/users/restore',
  DELETE_USER: '/admin/users/delete',
  PUT_STATUS_PENDING: '/admin/revenues/claim/pending',
  PUT_STATUS_IN_PROGRESS: '/admin/revenues/claim/in_progress',
  PUT_STATUS_COMPLETED: '/admin/revenues/claim/completed',
  PUT_STATUS_REJECT: '/admin/revenues/claim/rejected',
  EXPORT_REQUEST_CLAIM: '/admin/revenues/claims/export',
};

export { API_USER_REVENUE };
