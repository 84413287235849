import Title from 'components/title/title';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import { deleteGuide, getListGuide } from 'handler/update-info-web';
import Pagination from 'components/pagination';
import ButtonCustomer from 'components/button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SimpleDialog from 'components/dialog-simple';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ViewCkEditor from 'components/ViewCKEditor';
import { convertDate } from 'utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ListGuide = () => {
  const navigate = useNavigate();
  const [listData, setListData] = useState<any>({});
  const [isShowModal, setIsShowModal] = useState(false);
  const [idGuide, setIdGuide] = useState(null);
  const [isShowPre, setIsShowPre] = useState(false);
  const [dataPreview, setDataPreview] = useState<any>(null);

  useEffect(() => {
    getListGuideApi(1);
  }, []);

  const onChangePageDetail = (id: number) => {
    navigate(`/update-guide/guide/${id}`);
  };

  const onChangePageCreate = () => {
    navigate(`/update-guide/create`);
  };

  const getListGuideApi = async (pageSize: number) => {
    try {
      const { data } = await getListGuide({ page: pageSize });
      setListData(data);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const onChangePaginate = (event: any, pageSize: number) => {
    getListGuideApi(pageSize);
  };

  const handleDelete = async () => {
    if (!idGuide) return;
    try {
      const data = await deleteGuide(idGuide);
      toast.success('正常に削除されました');
      getListGuideApi(1);
      setIsShowModal(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const onShowPre = (data: any) => {
    setDataPreview(data);
    setIsShowPre(true);
  };

  const handleClosePreview = () => {
    setIsShowPre(false);
    setDataPreview(null);
  };

  return (
    <div className="w-full h-main pl-6 pr-6 pt-5">
      <div className="pt-2 flex justify-between">
        <Title title="ガイド" />
        <Button
          onClick={onChangePageCreate}
          variant="contained"
          startIcon={<AddIcon />}
          color="primary"
          className="h-[41px]"
        >
          新しい作成
        </Button>
      </div>
      <div className="pt-5">
        {listData?.data?.length !== 0 ? (
          <ul className="flex flex-col items-start ">
            {listData?.data?.map((item: any) => {
              return (
                <li className="py-1 flex flex-col w-[100%]" key={item?.id}>
                  <div className="py-1 flex justify-between w-[100%]">
                    <div className="text-left">
                      <div>{item?.title}</div>
                      <span className="text-[12px] text-gray">{convertDate(item?.created_at)}</span>
                    </div>
                    <div className="flex justify-between min-w-[300px]">
                      <ButtonCustomer
                        size="small"
                        rounded={true}
                        className="bg-green text-white "
                        onClick={() => onShowPre(item)}
                      >
                        プレビュー
                      </ButtonCustomer>
                      <ButtonCustomer
                        onClick={() => onChangePageDetail(item?.id)}
                        size="small"
                        rounded={true}
                        className="bg-blue text-white "
                      >
                        <EditIcon fontSize="small" /> 編集
                      </ButtonCustomer>
                      <ButtonCustomer
                        onClick={() => {
                          setIsShowModal(true);
                          setIdGuide(item?.id);
                        }}
                        size="small"
                        rounded={true}
                        className="bg-red text-white"
                      >
                        <DeleteIcon fontSize="small" /> 消去
                      </ButtonCustomer>
                    </div>
                  </div>
                  <div className="h-[0.5px] bg-gray"></div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="pt-[15%] w-[100%]">データなし</div>
        )}
      </div>
      <div className="pagination">
        <Pagination
          limit={10}
          total={listData?.total || 0}
          onChange={onChangePaginate}
          page={listData?.current_page || 1}
          hidden={listData?.data?.length === 0}
          lastPage={listData?.last_page || 1}
        />
      </div>
      <SimpleDialog
        open={isShowModal}
        onClose={() => {
          setIsShowModal(false);
          setIdGuide(null);
        }}
        onOk={handleDelete}
        content="このコンテンツを削除しますか??"
      />

      {/* Modal preview  */}
      <BootstrapDialog
        onClose={handleClosePreview}
        aria-labelledby="customized-dialog-title"
        open={isShowPre}
        maxWidth="lg"
      >
        <div className="min-w-[30rem] min-h-[100px] p-10">
          <div className="flex flex-row-reverse">
            <HighlightOffIcon className="cursor-pointer" onClick={handleClosePreview} />
          </div>
          <div className="flex justify-center pb-10">
            <b className="text-[25px]">プレビュー</b>
          </div>
          <div className="flex min-h-[50px] pb-10">
            <b className="min-w-[100px] ">タイトル:</b>
            <p className="max-w-[850px]">{dataPreview?.title}</p>
          </div>
          <div className="flex min-h-[50px] pb-10">
            <b className="min-w-[100px]">コンテンツ:</b>
            <div className="min-w-[850px] max-w-[850px]">
              <ViewCkEditor valueEditor={dataPreview?.content || ''} />
            </div>
          </div>
          <div className="flex min-h-[50px]">
            <b className="min-w-[100px]">日付:</b>
            <p>{convertDate(dataPreview?.created_at)}</p>
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
};

export default ListGuide;
