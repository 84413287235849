import React, { useEffect, useState } from 'react';
import Title from 'components/title/title';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { getListNews } from 'handler/news/index';
import { INews } from 'handler/news/typings';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import Pagination from 'components/pagination';
import Table from './table';
import Create from './create';

const News = () => {
  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [dataNews, setDataNews] = useState<INews[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const limit = 10;
  const [isEdit, setIsEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState<any>();

  const getListNewsApi = async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response: any = await getListNews(page, limit);
      setDataNews(response?.data?.data);
      setTotal(response?.data?.total);
      setLastPage(response?.data?.last_page);
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  useEffect(() => {
    getListNewsApi();
  }, [page]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
  };
  const onSuccess = () => {
    getListNewsApi();
    onCloseDialog();
  };
  const handleCreateNew = () => {
    setOpenDialog(true);
    setIsEdit(false);
    setDataEdit(null);
  };
  const handleEdit = (id: number) => {
    setIsEdit(true);
    setOpenDialog(true);
    setDataEdit(dataNews.find((item) => item.id === id));
  };

  const onDeleteSuccess = () => {
    if (dataNews.length === 1 && page > 1) {
      setPage(page - 1);
      return;
    }
    getListNewsApi();
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
    setIsEdit(false);
    setDataEdit(null);
  };

  return (
    <div className="w-full h-main ">
      <div className="main-header">
        <Title title="ニュース設定" subTitle="ウェブサイトのニュース登録/編集/削除可能" />
        <div className="header-actions">
          <div className="flex justify-between">
            <p className="text-16">ニュース一覧</p>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              color="primary"
              onClick={handleCreateNew}
            >
              新しい作成
            </Button>
          </div>
        </div>
      </div>
      <div className="main-body">
        <Table dataNews={dataNews} onEdit={handleEdit} onDeleteSuccess={onDeleteSuccess} />

        <div className="pagination">
          <Pagination
            limit={limit}
            total={total}
            onChange={onChangePaginate}
            page={page}
            hidden={dataNews.length === 0}
            lastPage={lastPage}
          />
        </div>
      </div>

      {openDialog && (
        <Create
          open={openDialog}
          handleClose={onCloseDialog}
          isEdit={isEdit}
          dataEdit={dataEdit}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default News;
