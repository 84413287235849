// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create_boxUpload__-AMZP {
  width: 800px;
  height: 450px;
  border: 0.5px solid rgb(180, 180, 180);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.create_boxImage__aravf {
  cursor: pointer;
  width: auto;
  height: auto;
}

.create_boxTextLink__GdIO6 {
  width: 800px;
}

.create_boxItemListBanner__BOKPO {
  width: 300px;
  max-height: 200px;
  overflow: hidden;
  text-align: center;
}
.create_boxItemListBanner__BOKPO img {
  display: inline-block;
  max-width: 300px;
  max-height: 200px;
  width: auto;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/banner-settings/create.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,eAAA;EACA,WAAA;EACA,YAAA;AACJ;;AACA;EACI,YAAA;AAEJ;;AAAA;EACI,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AAGJ;AAFI;EACI,qBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AAIR","sourcesContent":[".boxUpload{\n    width: 800px;\n    height: 450px;\n    border: 0.5px solid rgb(180, 180, 180);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 10px;\n    border-radius: 5px;\n    overflow: hidden;\n    cursor: pointer;\n\n}\n.boxImage{\n    cursor: pointer;\n    width: auto;\n    height: auto;\n}\n.boxTextLink{\n    width: 800px;\n}\n.boxItemListBanner{\n    width: 300px;\n    max-height: 200px;\n    overflow: hidden;\n    text-align: center;\n    img{\n        display: inline-block;\n        max-width:300px;\n        max-height: 200px;\n        width: auto;\n        height: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxUpload": `create_boxUpload__-AMZP`,
	"boxImage": `create_boxImage__aravf`,
	"boxTextLink": `create_boxTextLink__GdIO6`,
	"boxItemListBanner": `create_boxItemListBanner__BOKPO`
};
export default ___CSS_LOADER_EXPORT___;
