import { put, takeEvery, call } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import AUTH_API from 'constant/api/auth';
import { SAGA_LOGIN } from 'stores/const/auth';
import ApiUtils from 'utils/api/api.utils';
import { getAuthSuccess, getAuthFailure } from './reducer';
import { KeyStorage } from 'utils/localStorage';
import localStorageHelper from 'utils/localStorage';
import { ITokenInfo } from 'models/common';

interface payloadLogin {
  username: string;
  password: string;
}

async function callApi(payload: payloadLogin) {
  const { username, password } = payload;
  const data = await ApiUtils.post(AUTH_API.LOGIN, {
    username: username,
    password: password,
  });
  return data;
}

function* handleLoginSaga(action: PayloadAction<payloadLogin>) {
  try {
    const { data } = yield call(callApi, action.payload);

    const tokenStorage: ITokenInfo = {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      expiresAt: data.expires_in,
    };

    localStorageHelper.set(KeyStorage.X_TOKEN, JSON.stringify(tokenStorage));
    // Dispatch action success]
    yield put(getAuthSuccess(data));
  } catch (error) {
    yield put(getAuthFailure(error));
  }
}

export default function* authSaga() {
  yield takeEvery(SAGA_LOGIN, handleLoginSaga);
}
