import React, { useState } from 'react';
import s from './style.module.scss';
import Title from 'components/title/title';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import {
  changeEmailRequest,
  changeEmailResendCode,
  changeEmailVerify,
} from 'handler/user-management';
import { toast } from 'react-toastify';

const UpdateNewemail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [isShowInputCode, setIsShowInputCode] = useState(false);

  const onSubmitForm = (data: any) => {
    if (!isShowInputCode) {
      callApiChangeEmailReq({ new_email: data.email });
    }

    if (isShowInputCode) {
      callApiChangeEmailVerify({ new_email: data.email, code: data.code });
    }
  };

  const callApiChangeEmailReq = async (dataForm: any) => {
    try {
      const data = await changeEmailRequest(dataForm);
      toast.success('コードがメールに送信されました');
      setIsShowInputCode(true);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const callApiChangeEmailVerify = async (dataForm: any) => {
    try {
      const data = await changeEmailVerify(dataForm);
      toast.success('メールアドレスが正常に変更されました');
      setIsShowInputCode(false);
      setValue('email', '');
      setValue('code', '');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const onResendCode = async () => {
    try {
      const data = await changeEmailResendCode({});
      toast.success('コードがメールに送信されました');
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  return (
    <div className={s.container}>
      <Title title="メール変更" />
      <div className={s.content}>
        <p>新しいメールアドレスを入力してから送信ボタンをクリックします。</p>
        <p>元のメールアドレスのメールを確認してください。</p>
        <p>6 桁の確認コードが記載されたメールが届きます。</p>
        <p>メールアドレスの変更を完了するには、</p>
        <p>メールに記載されたコードをセキュリティーコード欄に入力して変更ボタンを押します。</p>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className={s.formChangeEmail}>
          <TextField
            className={s.inputMail}
            label="新しいメールアドレス"
            type="email"
            {...register('email', { required: true })}
            error={errors.email ? true : false}
            helperText={errors.email ? '新しいメールアドレスを入力してください' : ''}
          />

          {isShowInputCode && (
            <div className={s.boxInputCode}>
              <span onClick={onResendCode}>再送信</span>
              <TextField
                className={s.inputCode}
                label="新しいセキュリティーコードメールアドレス"
                {...register('code', { required: true })}
                error={errors.code ? true : false}
                helperText={
                  errors.code ? '新しいセキュリティーコードメールアドレスを入力してください' : ''
                }
              />
            </div>
          )}

          <Button className={s.btnSubmit} type="submit" variant="contained">
            送信
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UpdateNewemail;
