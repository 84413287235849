import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Terms from 'pages/Terms';
import CompanyProfile from 'pages/company-profile';
import PrivacePolicy from 'pages/privace-policy';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UpdateInfoWeb = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="bg-white h-[100vh]">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="会社概要" {...a11yProps(0)} />
            <Tab label="プライバシーポリシー" {...a11yProps(1)} />
            <Tab label="利用規約" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CompanyProfile />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PrivacePolicy />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Terms />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default UpdateInfoWeb;
