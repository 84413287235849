import { FormControl, InputAdornment, TextField, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'stores/hooks';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SAGA_LOGIN } from 'stores/const/auth';
import { RootState } from 'stores/store';
import { useNavigate } from 'react-router-dom';
import ButtonCustomer from 'components/button';
import { toast } from 'react-toastify';
import { SET_LOADING } from 'stores/const/app';

const Login = (props: any) => {
  const dispatch = useAppDispatch();
  const useInfo = useAppSelector((state: RootState) => state.authSaga);

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const onChangeInput = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'username') {
      setUsername(event.target.value);
    }
    if (type === 'password') {
      setPassword(event.target.value);
    }
  };

  const handleSubmit = () => {
    dispatch({ type: SET_LOADING, payload: true });
    dispatch({
      type: SAGA_LOGIN,
      payload: { username: username, password: password },
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: false });
    if (useInfo.auth.access_token) {
      toast.success('ログインできました。');
      navigate('/user-management');
    }
  }, [useInfo]);

  const checkIsValidate = () => {
    return username === '' || password === '' ? true : false;
  };

  return (
    <div className="flex items-center h-[70vh]">
      <div className="bg-white w-[600px] mx-auto px-16 pt-12 pb-12">
        <FormControl className="w-full gap-y-5">
          <TextField
            placeholder="メールアドレス"
            value={username}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            onChange={onChangeInput('username')}
            className="bg-white"
          />
          <TextField
            placeholder="パスワード"
            value={password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={onChangeInput('password')}
            type={showPassword ? 'text' : 'password'}
            className="bg-white"
          />
          <div className="flex justify-end">
            {/* <Link to={'/forgot-password'} className="text-blue hover:underline">
              パスワードのお忘れ
            </Link> */}
            <></>
          </div>

          <div className="mt-5">
            <ButtonCustomer
              onClick={handleSubmit}
              className="rounded-xl bg-main_1 text-white px-18 py-3 w-10/12 font-bold"
              disabled={checkIsValidate()}
            >
              ログイン
            </ButtonCustomer>
          </div>
        </FormControl>
      </div>
    </div>
  );
};

export default Login;
