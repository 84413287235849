import React from 'react';

interface Props {
  title: string;
  subTitle?: string;
}

function Title(props: Props) {
  return (
    <div>
      <div className="flex">
        <div className="border-l-4 border-main_1">&nbsp;</div>
        <p className="text-24 font-bold">{props.title}</p>
      </div>
      {props.subTitle && (
        <p className="text-gray text-10 text-left mt-2 ml-1 h-8">{props.subTitle}</p>
      )}
    </div>
  );
}

export default Title;
