const API_INQUIRY = {
  GET_ALL: '/admin/inquiry',
  GET_DETAIL: '/admin/inquiry/detail',
  ANSWER: '/admin/inquiry/answer',
  GET_INQUIRY_UNREAD_COUNT: '/admin/inquiry/unread-count',
  PUT_INQUIRY_READ: '/admin/inquiry/read',
  TEMPLATE: '/admin/inquiry/template',
  CREATE: '/admin/inquiry/template/create',
  DELETE: '/admin/inquiry/template/delete',
  UPDATE: '/admin/inquiry/template/update',
};

export { API_INQUIRY };
