import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import DefaultImage from 'assets/icons/default-image.svg';
import { createBanner, checkImage, updateBanner, uploadImage } from 'handler/banner';
import { toast } from 'react-toastify';
import { IBanner } from 'handler/banner/typing';
import ButtonCustomer from 'components/button';
import s from './create.module.scss';

interface Props {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  dataEdit?: IBanner;
  successBanner: () => void;
}
function CreateBanner(props: Props) {
  const { open, handleClose, isEdit, dataEdit, successBanner } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [prevImage, setPrevImage] = useState(DefaultImage);
  const [prevImageId, setPrevImageId] = useState('');
  const [link, setLink] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [isValidateLink, setIsValidateLink] = useState(true);
  const [onDisable, setOnDisable] = useState(false);

  useEffect(() => {
    if (isEdit && dataEdit) {
      setLink(dataEdit.url || '');
      setPrevImage(dataEdit.image.path || DefaultImage);
      setPrevImageId(dataEdit.image.id?.toString());
      setIsValidateLink(validateLink(dataEdit.url || ''));
    } else {
      setLink('');
      setPrevImage(DefaultImage);
      setImage(null);
      setIsValidateLink(true);
    }
  }, [dataEdit, isEdit, open]);

  const handleMakeInput = () => {
    document.getElementById('image_input')?.click();
  };

  const handleUploadFile = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPrevImage(reader.result as string);
    };
    setImage(file);
  };

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleSave = async () => {
    setOnDisable(true);
    if (isEdit) {
      await handleUpdateBanner();
      setOnDisable(false);
      return;
    }

    await handleCreateBanner();
    setOnDisable(false);
  };

  const handleCreateBanner = async () => {
    try {
      if (!image) {
        toast.error('画像が必要です');
        setOnDisable(false);
        return;
      }
      if ((await checkImage(image)) === false) {
        toast.error('サイズが 5MB 未満の画像のみアクセスされます');
        setOnDisable(false);
        return;
      }

      const formData = new FormData();
      formData.append('type', 'banner');
      formData.append('file', image);

      const resImage = await uploadImage(formData);

      if (resImage && resImage.data) {
        const formDataBanner = new FormData();
        formDataBanner.append('url', link);
        formDataBanner.append('image_file_id', resImage.data.data.id);
        await createBanner(formDataBanner);
      }

      success();
    } catch (error) {
      setOnDisable(false);
    }
  };

  const handleUpdateBanner = async () => {
    try {
      if (image) {
        if ((await checkImage(image)) === false) {
          toast.error('サイズが 800*450 でサイズが 5MB 未満の画像のみアクセスされます');
          setOnDisable(false);
          return;
        }
      }

      let imageId = prevImageId;
      if (image) {
        const formData = new FormData();
        formData.append('type', 'banner');
        formData.append('file', image as Blob);
        const resImage = await uploadImage(formData);
        imageId = resImage.data.data.id;
      }

      if (imageId) {
        const formDataBanner = new FormData();
        formDataBanner.append('url', link);
        formDataBanner.append('image_file_id', imageId);
        await updateBanner(formDataBanner, dataEdit?.id || 0);
        success();
      }
    } catch (error) {
      setOnDisable(false);
    }
  };

  const success = () => {
    toast.success(
      `${isEdit ? 'バーナーの更新が成功しました。' : 'バーナーの作成が成功しました。'}`
    );
    successBanner();
    setOnDisable(false);
    onClose();
  };

  const validateLink = (link: string) => {
    if (!link) return true;

    const regex = new RegExp(
      'https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,}'
    );
    return !!regex.test(link);
  };

  const checkCanSubmit = () => {
    if (isEdit) {
      return !isValidateLink;
    }
    return !isValidateLink || !image;
  };

  const onClose = () => {
    setImage(null);
    setPrevImage(DefaultImage);
    setLink('');
    handleClose();
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle className="text-center">{isEdit ? 'バナー編集' : 'バナー登録'}</DialogTitle>
        <DialogContent>
          <div className="flex justify-center flex-col items-center">
            <div className={s.boxUpload} onClick={handleMakeInput}>
              <img src={prevImage} alt="default" className={s.boxImage} />
            </div>
            <input
              hidden
              id="image_input"
              accept="image/*"
              multiple
              type="file"
              onChange={handleUploadFile}
            />
            <div className={s.boxTextLink}>
              <TextField
                error={!isValidateLink}
                value={link}
                label="リンク追加"
                className="w-full"
                onChange={(e) => {
                  setLink(e.target.value);
                  setIsValidateLink(validateLink(e.target.value));
                }}
                helperText={!isValidateLink ? 'リンク形式が間違いました' : ''}
                inputProps={{ maxLength: 255 }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="mx-auto mb-5 font-bold">
          <ButtonCustomer onClick={onClose} className="w-32 border-2 border-gray rounded-3xl">
            キャンセル
          </ButtonCustomer>
          <ButtonCustomer
            disabled={checkCanSubmit() || onDisable}
            onClick={handleSave}
            className="w-32  rounded-3xl bg-main_1 text-white"
            rounded
          >
            保存
          </ButtonCustomer>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateBanner;
