import { InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import Title from 'components/title/title';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { getListContent } from 'handler/content/intex';
import { IContent } from 'handler/user-management/typings';
import Table from 'components/content-table';
import ButtonCustomer from 'components/button';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import './index.scss';
import Pagination from 'components/pagination';
// let delaySearch: any;

const ContentManagement = (props: any) => {
  const dispatch = useAppDispatch();

  const [dataContents, setDataContent] = useState<IContent[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPageCount, setLastPageCount] = useState<number>(1);
  const limit = 10;
  const [search, setSearch] = useState<string>('');
  const [type, setType] = useState(-1);

  const getAllUserApi = async () => {
    dispatch({ type: SET_LOADING, payload: true });
    const response = await getListContent(search, page, limit, type, 1);
    console.log(response);
    setDataContent(response?.data?.data);
    setLastPageCount(response?.data?.last_page);
    setTotal(response?.data?.total);

    dispatch({ type: SET_LOADING, payload: false });
  };

  useEffect(() => {
    getAllUserApi();
  }, [page, type]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
  };

  const onChangeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClickSearch = (event?: React.KeyboardEvent<HTMLDivElement>) => {
    if (event?.key === 'Enter' || !event) {
      setPage(1);
      getAllUserApi();
    }
  };

  return (
    <div className="w-full h-main bg-white user-container">
      <div className="main-header">
        <Title title="コンテンツ一覧" />

        <div className="header-actions flex justify-between items-center gap-2">
          <div className="w-[120px]">
            <Select
              id="type"
              name="type"
              className="w-full"
              value={type}
              onChange={(e: any) => {
                setType(e.target.value as number);
                setPage(1);
              }}
            >
              <MenuItem value={-1}>All</MenuItem>
              <MenuItem value={0}>PDF</MenuItem>
              <MenuItem value={1}>MIDI</MenuItem>
              <MenuItem value={2}>AUDIO</MenuItem>
              <MenuItem value={3}>VIDEO</MenuItem>
            </Select>
          </div>
          <div>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="w-[300px]"
              onChange={onChangeSearchKey}
              onKeyDown={(e) => onClickSearch(e)}
              value={search}
              placeholder="コンテンツタイプ、コンテンツなど"
              sx={{
                input: {
                  '&::placeholder': {
                    fontSize: 13,
                  },
                },
                marginRight: 1,
              }}
            />
            <ButtonCustomer
              name="検索"
              size="medium"
              rounded={true}
              className="bg-blue text-white"
              onClick={() => onClickSearch()}
            />
          </div>
        </div>
      </div>

      <div className="main-body">
        <Table dataContents={dataContents} onDisableSuccess={getAllUserApi} />

        <div className="pagination">
          <Pagination
            limit={limit}
            total={total}
            onChange={onChangePaginate}
            page={page}
            hidden={dataContents.length === 0}
            lastPage={lastPageCount}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentManagement;
