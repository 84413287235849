import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import { deleteUser, getUserDetail } from 'handler/user-management';
import Title from 'components/title/title';
import ButtonCustomer from 'components/button';
import { restoreUser } from 'handler/user-management';
import Card from 'components/card';
import CardTitle from 'components/card-title';
import DeleteUserDialog from 'components/dialog-delete-user';

const blockInfo = [
  { title: 'ユーザー名', key: 'name' },
  { title: 'メールアドレス', key: 'email' },
  { title: '電話番号（緊急連絡用ですので、公開されません)', key: 'phone_number' },
  { title: '自己紹介', key: 'description' },
  { title: 'インボイス番号', key: 'invoice_number' },
];

const blockBank = [
  { title: '銀行名', key: 'name' },
  { title: '銀行番号', key: 'bank_code' },
  { title: '支店名', key: 'address' },
  { title: '支店番号', key: 'branch_code' },
  { title: '口座種別', key: 'type' },
  { title: '口座番号', key: 'number' },
  { title: '口座名義人名', key: 'holder_name' },
];

function UserDetail(props: any) {
  const dispatch = useAppDispatch();
  //   const [searchParams] = useSearchParams;
  const { id } = useParams<{ id: string }>();

  const [dataUser, setDataUser] = useState<any>();
  const [dataBank, setDataBank] = useState<any>();

  const [openDialog, setOpen] = React.useState(false);

  const handleDelete = async (reason: string) => {
    if (id) await deleteUser(+id, reason);
    toast.success('ユーザが正常に削除されました。');
    setOpen(false);
    getUserDetailApi();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickDelete = () => {
    setOpen(true);
  };

  const getUserDetailApi = useCallback(async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await getUserDetail(id);
      setDataUser(response?.data);
      setDataBank(response?.data?.bank_account);
      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  }, [dispatch, id]);

  useEffect(() => {
    getUserDetailApi();
  }, [dispatch, getUserDetailApi, id]);

  const onClickRestore = async () => {
    await restoreUser(Number(id));
    toast.success('ユーザが正常に復活されました。');
  };

  return (
    <div className="w-full h-main bg-white p-4 user-detail-container text-left pb-18">
      <Title title="ユーザー詳細" />
      <div className="flex gap-6">
        <div className="w-1/2">
          <Card>
            <CardTitle title="アカウント情報" />
            {blockInfo.map((item, index) => (
              <div className={`mt-1 py-1 border-b border-[#F1A200]/20`} key={index}>
                <p className="text-18 font-medium">{item.title}</p>
                <p className="mt-2 break-words">{dataUser?.[item.key]}</p>
              </div>
            ))}
            <div className="mt-1">
              <div className="text-20 my-3">
                <p className="text-18 font-medium">ステータス</p>
                <div className="flex justify-between items-center">
                  <div>
                    {dataUser?.deleted_at ? (
                      <span className="text-red">無効</span>
                    ) : (
                      <span className="text-blue">有効</span>
                    )}
                  </div>

                  {!dataUser?.deleted_at ? (
                    <ButtonCustomer
                      size="small"
                      rounded={true}
                      className="bg-red text-white"
                      onClick={onClickDelete}
                    >
                      <DeleteIcon fontSize="small" /> 無効
                    </ButtonCustomer>
                  ) : (
                    <ButtonCustomer
                      size="small"
                      rounded={true}
                      className="bg-blue text-white"
                      onClick={onClickRestore}
                    >
                      <ReplayIcon fontSize="small" /> 復旧
                    </ButtonCustomer>
                  )}
                </div>
              </div>
              {dataUser?.reason_delete && (
                <div className="mt-1 py-1 border-t border-[#F1A200]/20">
                  <p className="mt-2 text-18 font-medium">無効理由</p>
                  <p className="mt-2 break-words">{dataUser?.reason_delete}</p>
                </div>
              )}
            </div>
          </Card>
          <Card className="mt-6">
            <CardTitle title="報酬受取口座" />
            {/* <div className="flex justify-between items-center text-20 mt-10 mb-3">
              <div>
                {dataUser?.deleted_at ? (
                  <span className="text-red">無効</span>
                ) : (
                  <span className="text-blue">有効</span>
                )}
              </div>

              {!dataUser?.deleted_at ? (
                <ButtonCustomer
                  size="small"
                  rounded={true}
                  className="bg-red text-white"
                  onClick={onClickDelete}
                >
                  <DeleteIcon fontSize="small" /> 無効
                </ButtonCustomer>
              ) : (
                <ButtonCustomer
                  size="small"
                  rounded={true}
                  className="bg-blue text-white"
                  onClick={onClickRestore}
                >
                  <ReplayIcon fontSize="small" /> 復旧
                </ButtonCustomer>
              )}
            </div>
            {dataUser?.reason_delete && (
              <div
                className='flex justify-between mt-1 py-1 border-t border-[#F1A200]/20'
              >
                <p className="mt-2 text-18 font-medium">無効理由</p>
                <p className="mt-2 break-words">{dataUser?.reason_delete}</p>
              </div>
            )} */}
          </Card>
        </div>

        <Card className="w-1/2">
          <CardTitle title="報酬受取口座" />
          {blockBank.map((item, index) => (
            <div
              className={`mt-1 py-1 ${
                index !== blockBank.length - 1 ? 'border-b border-[#F1A200]/20' : ''
              }`}
              key={index}
            >
              <p className="text-18 font-medium">{item.title}</p>
              <p className="mt-2 break-words">{dataBank?.[item.key]}</p>
            </div>
          ))}
        </Card>
      </div>
      <Card className="mt-6">
        <CardTitle title="アップロードコンテンツ情報" />
        <div className="mt-1 border-b border-[#F1A200]/20 py-1 mb-[11px]">
          <p className="text-18 font-medium">アップロードコンテンツ数</p>
          <p className="mt-2 break-words">{dataUser?.contents_count}</p>
        </div>
        <NavLink to={'/user-management/user-content/' + id}>
          <ButtonCustomer
            size="small"
            className="bg-main_1 text-white text-14mt-2 break-words"
            rounded
          >
            全て見る
          </ButtonCustomer>
        </NavLink>
      </Card>
      <DeleteUserDialog open={openDialog} onClose={handleClose} onHandlerDelete={handleDelete} />
    </div>
  );
}

export default UserDetail;
