// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collection-detail-container .MuiInputBase-input {
  padding: 9px 12px;
}
.collection-detail-container .label {
  margin-bottom: 10px;
}

.content-table .MuiInputBase-input {
  padding: 9px 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/collection-detail/index.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAEE;EACE,mBAAA;AAAJ;;AAKE;EACE,iBAAA;AAFJ","sourcesContent":[".collection-detail-container {\n  .MuiInputBase-input {\n    padding: 9px 12px;\n  }\n  .label {\n    margin-bottom: 10px;\n  }\n}\n\n.content-table {\n  .MuiInputBase-input {\n    padding: 9px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
