import { useEffect } from 'react';
import { getInquiryUnreadCount } from 'handler/inquiry';
import { useSelector, useDispatch } from 'react-redux';
import { isAuthenticated, saveTotalUnreadInquiry } from 'stores/user/reducer';

const TIMER_FETCH_INQUIRY_UNREAD_COUNT = 300000; // 5 mins

const useIdleTimer = () => {
  const dispatch = useDispatch();
  const getIsAuthenticated = useSelector(isAuthenticated);

  let myIntervalFetchInQuiryUnreadCount: any;
  let events = ['mousedown', 'mousemove', 'load', 'keypress', 'scroll', 'touchstart'];

  const callApiUnreadInquiry = async () => {
    const data = await getInquiryUnreadCount();
    dispatch(saveTotalUnreadInquiry(data?.data?.count));
  };

  const onFetchInQuiryUnreadCountInfo = () => {
    if (!getIsAuthenticated) {
      return;
    }

    clearInterval(myIntervalFetchInQuiryUnreadCount);
    myIntervalFetchInQuiryUnreadCount = setInterval(() => {
      callApiUnreadInquiry();
    }, TIMER_FETCH_INQUIRY_UNREAD_COUNT);
  };

  const handleRemoveEvents = () => {
    clearInterval(myIntervalFetchInQuiryUnreadCount);
    events.forEach((name) => {
      document.removeEventListener(name, onFetchInQuiryUnreadCountInfo, true);
    });
  };

  useEffect(() => {
    events.forEach((name) => {
      document.addEventListener(name, onFetchInQuiryUnreadCountInfo, true);
    });

    return () => {
      handleRemoveEvents();
    };
  }, []);
};

export default useIdleTimer;
