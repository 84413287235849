const INFOR_WEB_API = {
  GET_COMPANY_PROFILE: '/admin/system-setting/company_profile',
  UPDATE_COMPANY_PROFILE: '/admin/system-setting/company_profile/update',
  GET_POLICY: '/admin/system-setting/policy',
  UPDATE_POLICY: '/admin/system-setting/policy/update',
  GET_TERMS: '/admin/system-setting/terms',
  UPDATE_TERMS: '/admin/system-setting/terms/update',
  CREATE_GUIDE: '/admin/guide/create',
  UPDATE_GUIDE: '/admin/guide/update',
  DETAIL_GUIDE: '/admin/guide/detail',
  DELETE_GUIDE: '/admin/guide/delete',
  GET_LIST_GUIDE: '/admin/guide',
  GET_LIST_FAQ: '/admin/faq',
  UPDATE_FAQ: '/admin/faq/update',
  DETAIL_FAQ: '/admin/faq/detail',
  DELETE_FAQ: '/admin/faq/delete',
  CREATE_FAQ: '/admin/faq/create',
};

export default INFOR_WEB_API;
