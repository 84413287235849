import { TextField } from '@mui/material';
import { MenuLoading } from 'components/MenuLoading';
import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface PropsType {
  options: {
    label?: string;
    title?: string;
    id?: number;
    content?: string;
  }[];
  value?: string;
  onChange?: (e: string) => void;
  placeholder?: string;
  hasMore?: boolean;
  next?: () => void;
  onClickOption?: (id: number) => void;
  setValue: (value: string) => void;
}
export const AutoCompleteCustom = ({
  options,
  value,
  onChange,
  placeholder,
  next,
  hasMore = false,
  onClickOption,
  setValue,
}: PropsType) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const autocomplete = useRef<HTMLDivElement | any>();
  const handleClickOutSide = (e: Event) => {
    if (!autocomplete.current.contains(e.target)) {
      setIsShow(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, []);

  return (
    <>
      <div className="relative" ref={autocomplete}>
        <div className="w-full">
          <TextField
            onChange={(e) => {
              if (!onChange) return;
              onChange(e.target.value);
              setValue(e.target.value);
            }}
            placeholder={placeholder}
            value={value}
            onFocus={() => setIsShow(true)}
            className="w-full"
            size="small"
          />
        </div>
        {isShow && (
          <div
            className="flex flex-col items-center justify-start absolute h-[200px] top-11 z-10 bg-[#fff] rounded-[4px] w-full overflow-auto border border-[#0000001a]"
            id="box-scroll"
          >
            <InfiniteScroll
              dataLength={options.length}
              next={next ? next : () => null}
              hasMore={hasMore} // Adjust the condition as needed
              loader={<MenuLoading />}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'hidden',
              }}
              scrollableTarget="box-scroll"
            >
              {options.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="cursor-pointer h-[50px] hover:bg-[#0000001a] w-full px-2 py-3 flex items-center"
                    onClick={() => {
                      if (onClickOption) {
                        onClickOption(item.id ?? 0);
                        setIsShow(false);
                      }
                    }}
                  >
                    {item.title}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </>
  );
};
