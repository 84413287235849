import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import Title from 'components/title/title';
import { getInquiryDetail } from 'handler/inquiry';
import InquiryDetail from './InquiryDetail';
import InquiryReply from './InquiryReply';

function Inquiry() {
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();
  const [dataInquiry, setDataInquiry] = useState<any>();
  const [isReply, setIsReply] = useState(false);

  const getInquiryDetailApi = useCallback(async () => {
    dispatch({ type: SET_LOADING, payload: true });
    const response = await getInquiryDetail(id);
    setDataInquiry(response?.data);
    dispatch({ type: SET_LOADING, payload: false });
  }, [dispatch, id]);

  useEffect(() => {
    getInquiryDetailApi();
  }, [dispatch, getInquiryDetailApi, id]);

  return (
    <div className="w-full h-main bg-white p-4 user-detail-container text-left pb-18">
      <Title title={isReply ? '問い合わせ返信' : '問い合わせ詳細'} />
      <>
        {isReply ? (
          <InquiryReply
            dataInquiry={dataInquiry}
            cancelAction={() => setIsReply(false)}
            replyAction={() => setIsReply(false)}
          />
        ) : (
          <InquiryDetail dataInquiry={dataInquiry} replyAction={() => setIsReply(true)} />
        )}
      </>
    </div>
  );
}

export default Inquiry;
