import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, Switch, TextField, Stack } from '@mui/material';
import Title from 'components/title/title';
import SearchIcon from '@mui/icons-material/Search';

import { getAllUserInfo } from 'handler/user-management';
import { IUser } from 'handler/user-management/typings';
import ButtonCustomer from 'components/button';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import Pagination from 'components/pagination';
import Table from './table';
import './index.scss';

const LIMIT = 10;

const UserManagement = (props: any) => {
  const dispatch = useAppDispatch();
  const [isGetAll, setGetAll] = useState(false);
  const [dataUser, setDataUser] = useState<IUser[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [params, setParams] = useState({
    page: 1,
    search: '',
    isGetAll: false,
  });

  const getAllUserApi = useCallback(async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await getAllUserInfo(params.isGetAll, params.page, LIMIT, params.search);
      setDataUser(response?.data?.data);
      setTotal(response?.data?.total);
      setLastPage(response?.data?.last_page);

      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  }, [dispatch, params.isGetAll, params.page, params.search]);

  useEffect(() => {
    getAllUserApi();
  }, [getAllUserApi, params]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
    await setSearch(params.search);
    await setParams({ ...params, ...{ page: newPage } });
  };

  const onChangeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClickSearch = (event?: React.KeyboardEvent<HTMLDivElement>) => {
    if (event?.key === 'Enter' || !event) {
      setPage(1);
      setParams({ ...params, ...{ search: search }, ...{ page: 1 } });
    }
  };

  return (
    <div className="w-full h-main bg-white user-container">
      <div className="main-header">
        <Title title="ユーザー管理" subTitle="ユーザー管理" />
        <div className="header-actions flex justify-between items-center">
          <Stack direction="row" spacing={0} alignItems="center" className="">
            <Switch
              checked={isGetAll}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setGetAll(event.target.checked);
                setParams({ ...params, ...{ isGetAll: event.target.checked } });
              }}
            />
            <div className="w-full">
              <span>{`全てのユーザー (削除されたユーザー）`}</span>
            </div>
          </Stack>

          <div className="flex items-center gap-2 justify-end">
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="w-[300px]"
              onChange={onChangeSearchKey}
              value={search}
              onKeyDown={(e) => onClickSearch(e)}
              placeholder="メール、ユーザーID、ユーザー名など"
              sx={{
                input: {
                  '&::placeholder': {
                    fontSize: 13,
                  },
                },
              }}
            />
            <ButtonCustomer
              name="検索"
              size="medium"
              rounded={true}
              className="bg-blue text-white"
              onClick={() => onClickSearch()}
            />
          </div>
        </div>
      </div>
      <div className="main-body">
        <Table dataUser={dataUser} onRestoreSuccess={() => getAllUserApi()} />

        <div className="pagination">
          <Pagination
            limit={LIMIT}
            total={total}
            onChange={onChangePaginate}
            page={page}
            hidden={dataUser.length === 0}
            lastPage={lastPage}
          />
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
