import React from 'react';

interface IContainerProps {
  children: React.ReactNode;
}

function Container(props: IContainerProps) {
  const { children } = props;
  return <div className="pt-28 w-10/12">{children}</div>;
}

export default Container;
