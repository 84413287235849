import s from './style.module.scss';
import Title from 'components/title/title';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { changePassword } from 'handler/user-management';
import { toast } from 'react-toastify';

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmitForm = (data: any) => {
    if (data.oldpass.trim() === '' || data.newpass.trim() === '') {
      toast.error('パスワードを再入力してください');
      setValue('oldpass', '');
      setValue('newpass', '');
      return;
    }

    callApiChangePass({
      old_password: data.oldpass.trim(),
      new_password: data.newpass.trim(),
      new_password_confirmation: data.newpass.trim(),
    });
  };

  const callApiChangePass = async (dataForm: any) => {
    try {
      const data = await changePassword(dataForm);
      toast.success('パスワードが変更されました');
      setValue('oldpass', '');
      setValue('newpass', '');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <div className={s.container}>
      <Title title="パスワード変更" />

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className={s.formChangeEmail}>
          <TextField
            className={s.inputOldpass}
            label="以前のパスワード"
            type="text"
            {...register('oldpass', { required: true })}
            error={!!errors.oldpass}
            helperText={errors.oldpass ? '以前のパスワードを入力してください' : ''}
          />

          <TextField
            className={s.inputOldpass}
            label="新しいパスワード"
            type="text"
            {...register('newpass', { required: true })}
            error={!!errors.newpass}
            helperText={errors.newpass ? '新しいパスワードを入力してください' : ''}
          />

          <Button className={s.btnSubmit} type="submit" variant="contained">
            送信
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
