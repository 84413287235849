import BonusIcon from 'assets/icons/bonus.svg';
import ComputerIcon from 'assets/icons/computer.svg';
import ContentIcon from 'assets/icons/contents.svg';
import InquiryIcon from 'assets/icons/inquiry.svg';
import MedalIcon from 'assets/icons/medal.svg';
import StatisticIcon from 'assets/icons/statistic.svg';
import UserIcon from 'assets/icons/user.svg';
import React from 'react';

import FAQ from 'pages/FAQ';
import BannerSettings from 'pages/banner-settings';
import BonusManagement from 'pages/bonus-management';
import AdsRevenueSetting from 'pages/bonus-management/ads-revenue-setting';
import CostSetting from 'pages/bonus-management/cost-setting';
import HistoryAdsRevenueSetting from 'pages/bonus-management/history-ads-revenue-setting';
import CollectionDetail from 'pages/collection-detail';
import Collections from 'pages/collections';
import ContentDetail from 'pages/content-detail';
import ContentManage from 'pages/contents';
import Guide from 'pages/guide';
import Home from 'pages/home';
import InquiryManagement from 'pages/inquiry-management';
import Inquiry from 'pages/inquiry-management/inquiry-detail';
import { InquiryTemplateComponent } from 'pages/inquiry-template';
import ListFAQ from 'pages/list-FAQ';
import ListGuide from 'pages/list-guide';
import Login from 'pages/login';
import News from 'pages/news';
import Setting from 'pages/setting';
import Statistic from 'pages/statistic';
import SubAccountManagement from 'pages/sub-account-management';
import UpdateInfoWeb from 'pages/update-info-web';
import UploadContentManage from 'pages/upload-content';
import UserContentList from 'pages/user-content';
import UserDetail from 'pages/user-detail';
import UserManagement from 'pages/user-management';
import UserRequest from 'pages/user-request';

export type IRouter = {
  path: string;
  component?: React.FC;
  hidden?: boolean;
  exact?: boolean;
  title?: string;
  icon?: string;
  children?: IRouter[];
  iconNotify?: boolean;
};

export const publicRouters: IRouter[] = [
  {
    path: '/login',
    component: Login,
    hidden: true,
    title: 'Login',
  },
];

export const privateRouters: IRouter[] = [
  {
    path: '/',
    hidden: true,
    title: 'ユーザー管理',
    component: Home,
  },
  {
    title: '問い合わせ',
    hidden: false,
    icon: InquiryIcon,
    path: '/',
    children: [
      {
        path: '/inquiry',
        component: InquiryManagement,
        hidden: false,
        title: '問い合わせ管理',
        iconNotify: true,
      },
      {
        path: '/inquiry-template',
        component: InquiryTemplateComponent,
        hidden: false,
        title: '問い合わせ回答テンプレート管理',
      },
    ],
  },
  {
    path: '/inquiry',
    component: InquiryManagement,
    hidden: true,
    title: '問い合わせ管理',
    iconNotify: true,
  },
  {
    path: '/inquiry-template',
    component: InquiryTemplateComponent,
    hidden: true,
    title: '問い合わせ回答テンプレート管理',
  },
  {
    title: 'ユーザー管理',
    hidden: false,
    icon: UserIcon,
    path: '/',
    children: [
      {
        path: '/user-management',
        component: UserManagement,
        hidden: false,
        title: 'ユーザー',
      },
      {
        path: '/sub-account-management',
        component: SubAccountManagement,
        hidden: false,
        title: '子供アカウント',
      },
      {
        path: '/user-request',
        component: UserRequest,
        hidden: false,
        title: '引き出し申請一覧',
      },
    ],
  },
  {
    path: '/user-management',
    component: UserManagement,
    hidden: true,
    icon: MedalIcon,
  },
  {
    path: '/sub-account-management',
    component: SubAccountManagement,
    hidden: true,
    icon: MedalIcon,
  },
  {
    path: '/user-request',
    component: UserRequest,
    hidden: true,
    icon: MedalIcon,
  },
  {
    path: 'change-email',
    component: Setting,
    hidden: true,
    icon: MedalIcon,
  },
  {
    path: '/news',
    component: News,
    hidden: true,
    icon: MedalIcon,
  },
  {
    path: '/banner',
    component: BannerSettings,
    hidden: true,
  },
  {
    path: '/bonus-management',
    component: BonusManagement,
    hidden: true,
  },
  {
    path: '/ads-revenue-setting',
    component: AdsRevenueSetting,
    hidden: true,
  },
  {
    path: '/ads-revenue-setting/history',
    component: HistoryAdsRevenueSetting,
    hidden: true,
  },
  {
    path: '/cost-setting',
    component: CostSetting,
    hidden: true,
  },
  {
    path: '/content-management',
    component: ContentManage,
    hidden: true,
  },
  {
    path: '/upload-content',
    component: UploadContentManage,
    hidden: true,
  },
  {
    path: '/user-management/user-detail/:id',
    component: UserDetail,
    hidden: true,
  },
  {
    path: '/user-management/user-content/:id',
    component: UserContentList,
    hidden: true,
  },
  {
    path: '/content-detail/:id',
    component: ContentDetail,
    hidden: true,
  },
  {
    path: '/collections',
    component: Collections,
    hidden: true,
    title: 'コレクション設定',
  },
  {
    path: '/update-info-web',
    component: UpdateInfoWeb,
    hidden: true,
    title: 'ウェブサイト情報更新',
  },
  {
    path: '/update-guide',
    component: ListGuide,
    hidden: true,
    title: 'ガイド',
  },
  {
    path: '/update-faq',
    component: ListFAQ,
    hidden: true,
    title: 'FAQ',
  },
  {
    path: '/collection-detail/:id',
    component: CollectionDetail,
    hidden: true,
    title: 'コレクション設定',
  },
  {
    path: '/create-collection',
    component: CollectionDetail,
    hidden: true,
    title: 'コレクション設定',
  },
  {
    title: 'ライブラリ管理',
    hidden: false,
    icon: ContentIcon,
    path: '/',
    children: [
      {
        path: '/content-management',
        component: ContentManage,
        hidden: false,
        title: 'コンテンツ一覧',
      },
      {
        path: '/upload-content',
        component: UploadContentManage,
        hidden: false,
        title: 'コンテンツをアップロード',
      },
    ],
  },
  {
    title: '表示設定',
    hidden: false,
    icon: ComputerIcon,
    path: '/',
    children: [
      {
        path: '/banner',
        component: BannerSettings,
        hidden: false,
        title: 'バナー設定',
      },
      {
        path: '/news',
        component: News,
        hidden: false,
        title: 'ニュース設定',
      },
      {
        path: '/collections',
        component: Collections,
        hidden: false,
        title: '特集設定',
      },
      {
        path: '/update-info-web',
        component: UpdateInfoWeb,
        hidden: false,
        title: 'ウェブサイト情報更新',
      },
      {
        path: '/update-guide',
        component: Guide,
        hidden: false,
        title: 'ガイド',
      },
      {
        path: '/update-faq',
        component: FAQ,
        hidden: false,
        title: 'FAQ',
      },
    ],
  },
  {
    title: '集計報告',
    hidden: false,
    path: '/statistic',
    icon: StatisticIcon,
    component: Statistic,
  },
  {
    title: '報酬管理',
    hidden: false,
    path: '/',
    icon: BonusIcon,
    children: [
      {
        path: '/bonus-management',
        component: BonusManagement,
        hidden: false,
        title: 'ユーザー収益',
      },
      {
        path: '/cost-setting',
        component: CostSetting,
        hidden: false,
        title: 'コスト設定',
      },
      {
        path: '/ads-revenue-setting',
        component: AdsRevenueSetting,
        hidden: false,
        title: '広告収入の設定',
      },
    ],
  },
  {
    path: '/inquiry-detail/:id',
    component: Inquiry,
    hidden: true,
  },
  {
    path: '/update-faq/faq/:id',
    component: FAQ,
    hidden: true,
  },
  {
    path: '/update-guide/guide/:id',
    component: Guide,
    hidden: true,
  },
  {
    path: '/update-guide/create',
    component: Guide,
    hidden: true,
  },
  {
    path: '/update-faq/create',
    component: FAQ,
    hidden: true,
  },
];
