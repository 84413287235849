import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { uploadImage } from 'handler/banner';
import { CKEditor } from '@ckeditor/ckeditor5-react';

interface Props {
  valueEditor: string;
  onHandleData: (data: string) => void;
  disable?: boolean;
}

const CKEditorCommon = ({ valueEditor, onHandleData, disable = false }: Props) => {
  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader);
    };
  }

  function uploadAdapter(loader: any) {
    return {
      upload: async () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file: any) => {
            body.append('file', file);
            body.append('type', 'ck_editor');

            const callApiUpload = async () => {
              try {
                const resImage = await uploadImage(body);
                resolve({
                  default: resImage?.data?.data?.path,
                });
              } catch (error) {
                reject(error);
              }
            };
            callApiUpload();
          });
        });
      },
    };
  }

  return (
    <div className={`article-content min-h-[200px] min-w-[850px] w-full`}>
      <CKEditor
        disabled={disable}
        editor={DecoupledEditor}
        config={{
          extraPlugins: [uploadPlugin],
          toolbar: {
            items: [
              'heading',
              '|',
              'fontfamily',
              'fontsize',
              'fontColor',
              'fontBackgroundColor',
              '|',
              'bold',
              'italic',
              'strikethrough',
              '|',
              'uploadImage',
              'imageStyle:alignLeft',
              'imageStyle:full',
              'imageStyle:alignRight',
              'imageStyle:alignCenter',
              '|',
              'alignment',
              '|',
              'bulletedList',
              'numberedList',
              'todoList',
            ],
            shouldNotGroupWhenFull: true,
          },
        }}
        data={valueEditor}
        onReady={(editor: any) => {
          editor.ui.view.editable.element.parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.view.editable.element
          );
        }}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          onHandleData(data);
        }}
      />
    </div>
  );
};

export default CKEditorCommon;
