import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Menu, MenuItem, Avatar, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { removeAuth } from 'stores/user/reducer';
import { useAppSelector, useAppDispatch } from 'stores/hooks';
import { RootState } from 'stores/store';
import localStorageHelper from 'utils/localStorage';
import { KeyStorage } from 'utils/localStorage';
import SettingsIcon from '@mui/icons-material/Settings';

const Header = () => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const userInfo = useAppSelector((state: RootState) => state.authSaga);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    setAnchorEl(null);
    dispatch(removeAuth());
    localStorageHelper.remove(KeyStorage.X_TOKEN);
    navigate('/login');
  };

  const onChangePageSetting = () => {
    navigate('/change-email');
  };

  return (
    <div className="px-8 py-2 bg-primary z-10 w-full fixed">
      <div className="flex justify-between w-full items-center">
        <Link to="/" className="text-white text-36">
          SCOPARA
        </Link>
        {userInfo.auth.access_token && (
          <div className="flex items-center gap-2">
            <Button onClick={handleClick}>
              <Avatar src={userInfo.userInfo?.avatar} alt="avatar" />
            </Button>
            <span className="text-white">{userInfo.userInfo?.name}</span>
          </div>
        )}
      </div>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={onLogout}>
          <LogoutIcon className="mr-1" /> ログアウト
        </MenuItem>
        <MenuItem onClick={onChangePageSetting}>
          <SettingsIcon className="mr-1" /> 設定
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Header;
