// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.router-link {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: bold;
  color: black;
  height: 56px;
  width: 100%;
  text-align: left;
  padding: 0 20px;
}
.router-link:hover {
  color: #f1a200 !important;
  background-color: #f2f2f2;
}

.active-router {
  color: #f1a200;
  background-color: rgba(241, 162, 0, 0.1411764706);
  border-right: 2px solid #f1a200;
}

.sidebar-container .MuiButtonBase-root {
  padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/layout/sidebar/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AACF;AAAE;EACE,yBAAA;EACA,yBAAA;AAEJ;;AACA;EACE,cAAA;EACA,iDAAA;EACA,+BAAA;AAEF;;AAEE;EACE,YAAA;AACJ","sourcesContent":[".router-link {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  font-weight: bold;\n  color: black;\n  height: 56px;\n  width: 100%;\n  text-align: left;\n  padding: 0 20px;\n  &:hover {\n    color: #f1a200 !important;\n    background-color: #f2f2f2;\n  }\n}\n.active-router {\n  color: #f1a200;\n  background-color: #f1a20024;\n  border-right: 2px solid #f1a200;\n}\n\n.sidebar-container {\n  .MuiButtonBase-root {\n    padding: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
