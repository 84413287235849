import { IUpdatePosition } from './typing';
import ApiUtils from 'utils/api/api.utils';
import API_BANNER from 'constant/api/banner';
import { IMAGE_SIZE_UPLOAD, IMAGE_SIZE_HEIGHT, IMAGE_SIZE_WIDTH } from 'constant/image';
import { IResponse } from 'models/common';

export const uploadImage = async (formData: any) => {
  const response: any = await ApiUtils.postForm(API_BANNER.UPLOAD_FILE, formData, { isAuth: true });

  return response;
};

export const createBanner = async (formData: any) => {
  const response = await ApiUtils.postForm(API_BANNER.CREATE, formData, { isAuth: true });

  return response;
};

export const updateBanner = async (formData: any, id: number) => {
  const response = await ApiUtils.postForm(API_BANNER.UPDATE + '/' + id, formData, {
    isAuth: true,
  });

  return response;
};

export const checkImage = async (file: File) => {
  const image = new Image();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      image.src = reader.result as string;
      image.onload = () => {
        const size = file.size;

        if (size < IMAGE_SIZE_UPLOAD) {
          resolve(true);
        }
        resolve(false);
      };
    };
  });
};

export const deleteBanner = async (id: number) => {
  return await ApiUtils.remove(API_BANNER.DELETE + '/' + id, { isAuth: true });
};

export const toggleBanner = async (id: number) => {
  return await ApiUtils.put(API_BANNER.PUT_BANNER_TOGGLE + '/' + id, { isAuth: true });
};

export const updatePosition = async (listBanner: IUpdatePosition) => {
  return await ApiUtils.put(API_BANNER.UPDATE_POSITION, listBanner, { isAuth: true });
};

export const getListBanner = async () => {
  const response: IResponse = await ApiUtils.get(API_BANNER.GET_ALL);

  return response.data;
};
