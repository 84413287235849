// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-user {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
}

.title-border {
  border-bottom: 4px solid rgba(241, 162, 0, 0.7607843137);
  margin-bottom: 10px;
  width: 60px;
}`, "",{"version":3,"sources":["webpack://./src/components/card-title/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,wDAAA;EACA,mBAAA;EACA,WAAA;AACF","sourcesContent":[".title-user {\n  font-size: 22px;\n  font-weight: 500;\n  text-align: left;\n}\n\n.title-border {\n  border-bottom: 4px solid #f1a200c2;\n  margin-bottom: 10px;\n  width: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
