import { useEffect, DependencyList } from 'react';

export function useDebounceEffect(func: () => void, delay: number, dependencies: DependencyList) {
  useEffect(() => {
    const time = setTimeout(() => {
      func();
    }, delay);

    return () => clearTimeout(time);
  }, dependencies);
}
