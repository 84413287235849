import React, { useRef, useState, SyntheticEvent, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from 'hooks/useDebounceEffect';
import { canvasPreview } from './canvas';
import ButtonCustomer from 'components/button';
import DefaultImage from 'assets/icons/default-image.svg';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

interface Props {
  onSave: (file: File) => void;
  preImage?: any;
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function CropImage(props: Props) {
  const { onSave, preImage } = props;
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const aspect = 1;

  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [sizePrev, setSizePrev] = useState({ width: 0, height: 0 });
  const [prevImage, setPrevImage] = useState(DefaultImage);

  const [openDialog, setOpenDialog] = useState(false);

  const onClose = () => {
    setOpenDialog(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById('image').value = '';
  };

  useEffect(() => {
    if (preImage) {
      setPrevImage(preImage);
    }
  }, [preImage]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageUrl(reader.result?.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
      setOpenDialog(true);
    }
  };

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };
  const onChangeCanvas = (canvas: HTMLCanvasElement) => {
    setCanvas(canvas);
    setSizePrev({ width: canvas.width, height: canvas.height });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, onChangeCanvas);
      }
    },
    100,
    [completedCrop, openDialog]
  );

  const handleSave = () => {
    if (canvas) {
      canvas.toBlob((blob: any) => {
        const file = new File([blob], 'image.png', { type: 'image/png' });
        onSave(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setPrevImage(reader.result as string);
        };
        setOpenDialog(false);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('image').value = '';
      });
    }
  };

  const onCancel = () => {
    setCrop(undefined);
    setImageUrl(null);
    setOpenDialog(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById('image').value = '';
  };

  return (
    <div>
      <input
        accept="image/*"
        multiple
        type="file"
        name="image"
        id="image"
        className="mb-2"
        onChange={onSelectFile}
        hidden
      />
      <img
        src={prevImage}
        alt="default"
        style={{ width: 300, height: 300 }}
        className="cursor-pointer"
        onClick={() => {
          document.getElementById('image')?.click();
        }}
      />
      <Dialog onClose={onClose} open={openDialog} maxWidth="xl" fullWidth>
        <DialogTitle>クロップ画像</DialogTitle>
        <DialogContent>
          <div className="mx-auto">
            {imageUrl && (
              <div>
                <div className="flex justify-center items-end gap-5 w-full">
                  {!!imageUrl && (
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => {
                        setCrop(percentCrop);
                      }}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={aspect}
                      className="max-w-8/12"
                    >
                      <img
                        ref={imgRef}
                        alt="Crop"
                        src={imageUrl}
                        onLoad={onImageLoad}
                        width={'auto'}
                        height={400}
                        className="mx-auto"
                      />
                    </ReactCrop>
                  )}
                  <div className="w-4/12">
                    {!!completedCrop && (
                      <div>
                        <span>
                          {sizePrev.width} x {sizePrev.height}
                        </span>
                        <canvas ref={previewCanvasRef} className="w-[300px]" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex justify-center gap-2 mt-5">
                  <ButtonCustomer rounded className="bg-red text-white" onClick={onCancel}>
                    キャンセル
                  </ButtonCustomer>

                  <ButtonCustomer rounded className="bg-blue text-white" onClick={handleSave}>
                    保存
                  </ButtonCustomer>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CropImage;
