import React from 'react';
import './index.scss';
interface Props {
  children?: React.ReactNode;
  className?: string;
}

function Card(props: Props) {
  const { className } = props;
  return <div className={`card ${className}`}>{props.children}</div>;
}

export default Card;
