import React from 'react';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { restoreUser, deleteUser } from 'handler/user-management';
import { IUser } from 'handler/user-management/typings';
import ButtonCustomer from 'components/button';
import NoData from 'components/no-data';
import { Edit } from '@mui/icons-material';
import DeleteUserDialog from 'components/dialog-delete-user';
interface Props {
  dataUser: IUser[];
  onRestoreSuccess: () => void;
  onOpenEdit: (id: number, name: string, password: string) => void;
  onOpenUserContent: (id: number) => void;
}

function TableUser(props: Props) {
  const { dataUser, onOpenEdit, onOpenUserContent } = props;
  const [openDialog, setOpen] = React.useState(false);
  const [idUser, setIdUser] = React.useState(0);

  const handleDelete = async (reason: string) => {
    await deleteUser(idUser, reason);
    toast.success('ユーザが正常に削除されました。');
    props.onRestoreSuccess();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickRestore = async (id: number) => {
    await restoreUser(id);
    toast.success('ユーザが正常に復活されました。');
    props.onRestoreSuccess();
  };

  const onClickDelete = async (id: number) => {
    setIdUser(id);
    setOpen(true);
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>子供アカウント名</TableCell>
            <TableCell>メールアドレス</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell align="center" size="small">
              アップロードコンテンツ数
            </TableCell>
            <TableCell align="center" className="w-[200px]">
              編集
            </TableCell>
            <TableCell align="center" className="w-[200px]">
              権限
            </TableCell>
            <TableCell align="center" className="w-[200px]">
              アクション
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataUser.length > 0 &&
            dataUser.map((user: IUser) => (
              <TableRow key={user.id}>
                <TableCell>{user?.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <div>
                    {user?.deleted_at ? <span className="text-red">無効</span> : <span>有効</span>}
                  </div>
                </TableCell>
                <TableCell align="center">{user.contents_count}</TableCell>
                <TableCell align="center">
                  <ButtonCustomer
                    size="small"
                    rounded
                    className="bg-blue text-white"
                    onClick={() => onOpenEdit(user.id, user.name, user.raw_password)}
                    disabled={!!user?.deleted_at}
                  >
                    <Edit fontSize="small" />
                    &nbsp;編集
                  </ButtonCustomer>
                </TableCell>
                <TableCell align="center">
                  <ButtonCustomer
                    size="small"
                    rounded
                    className="bg-green text-white"
                    onClick={() => onOpenUserContent(user.id)}
                    disabled={!!user?.deleted_at}
                  >
                    &nbsp;権限
                  </ButtonCustomer>
                </TableCell>
                <TableCell align="center">
                  <div className="flex justify-center w-[200px]">
                    {!user.deleted_at ? (
                      <ButtonCustomer
                        size="small"
                        rounded={true}
                        className="bg-red text-white"
                        onClick={() => onClickDelete(user.id)}
                      >
                        <DeleteIcon fontSize="small" /> 無効
                      </ButtonCustomer>
                    ) : (
                      <ButtonCustomer
                        size="small"
                        rounded={true}
                        className="bg-blue text-white"
                        onClick={() => onClickRestore(user.id)}
                      >
                        <ReplayIcon fontSize="small" /> 復旧
                      </ButtonCustomer>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {dataUser.length === 0 && (
        <div>
          <NoData />
        </div>
      )}
      <DeleteUserDialog open={openDialog} onClose={handleClose} onHandlerDelete={handleDelete} />
    </div>
  );
}

export default TableUser;
