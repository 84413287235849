import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { IContent } from 'handler/user-management/typings';
import { NavLink } from 'react-router-dom';
import ContentType from 'components/content-type';
import moment from 'moment';
import ButtonCustomer from 'components/button';
import { activeContent, disableContent } from 'handler/content/intex';
import { toast } from 'react-toastify';
import NoData from 'components/no-data';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import MusicFileIcon from 'assets/icons/music-file-icon.svg';

const Status = ({ status }: { status: number }) => {
  switch (status) {
    case 0:
      return <div className="text-blue font-bold">一般公開</div>;
    case 1:
      return <div className="text-blue font-bold">一般公開</div>;
    case 2:
      return <div className="text-red font-bold">限定公開</div>;
    default:
      return <div className="text-[#34A732] font-bold">近日公開</div>;
  }
};

interface Props {
  dataContents: IContent[];
  onDisableSuccess: () => void;
}

const INPUT_MAX_LENGTH = 255;

function TableContents(props: Props) {
  const { dataContents, onDisableSuccess } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [reason, setReason] = useState('');
  const [contentDisable, setContentDisable] = useState<IContent | null>(null);
  const [validateError, setValidateError] = useState('');

  const validationSchema = Yup.string()
    .trim()
    .required()
    .max(INPUT_MAX_LENGTH, '255文字以内を入力してください。');

  const validate = () => {
    try {
      validationSchema.validateSync(reason);
      onSubmit();
    } catch (error) {
      if (error instanceof Yup.ValidationError) setValidateError(error.message);
    }
  };

  const onDeActive = (content: IContent) => {
    setOpenDialog(true);
    setContentDisable(content);
  };

  const onActive = async (content: IContent) => {
    try {
      const data = await activeContent(content.id);
      toast.success('コンテンツの取り扱いがアクティブになりました。');
      onDisableSuccess();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const onClose = () => {
    setOpenDialog(false);
    setReason('');
    setValidateError('');
  };

  const checkCanSubmit = () => {
    return reason.trim().length > 0;
  };

  const onSubmit = async () => {
    await disableContent(contentDisable?.id || 0, reason);
    toast.success('コンテンツの取り扱いが中止されました。');
    onClose();
    onDisableSuccess();
  };

  const handleChangePageViewMusic = (id: number) => {
    window.open(`${process.env.REACT_APP_WEB_DOMAIN}/content/viewer/${id}`, '_blank');
  };
  console.log('conmtent', dataContents);
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>コンテンツタイプ</TableCell>
            <TableCell>表示名</TableCell>
            <TableCell>投稿者名</TableCell>
            <TableCell align="center" size="small">
              コンテンツナンバー
            </TableCell>
            <TableCell align="center">閲覧数</TableCell>
            <TableCell align="center">DL回数</TableCell>
            <TableCell align="center">ステータス</TableCell>
            <TableCell align="center" className="w-[100px]">
              アクション
            </TableCell>
            <TableCell className="w-[100px]"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataContents.length > 0 &&
            dataContents.map((content: IContent) => (
              <TableRow key={content.id}>
                <TableCell>
                  <ContentType type={content.content_type.toString()} />
                </TableCell>
                <TableCell>
                  <NavLink
                    className={'text-blue hover:underline font-bold '}
                    to={`/content-detail/` + content.id}
                  >
                    <Tooltip title={content.copyrights && content.copyrights[0]?.song_name}>
                      <div className="w-32 truncate">
                        {content.display_name}
                      </div>
                    </Tooltip>
                  </NavLink>
                </TableCell>
                <TableCell>
                  <Link to={`/user-management/user-detail/${content?.user?.id}`}>
                    {content?.user?.name}
                  </Link>
                </TableCell>
                <TableCell align="center" size="small">
                  {content?.content_code}
                </TableCell>
                <TableCell align="center">
                  {content.content_view ? content.content_view : 0}
                </TableCell>
                <TableCell align="center">
                  {content.download_count ? content.download_count : 0}
                </TableCell>
                <TableCell align="center">
                  <Status status={content.state} />
                </TableCell>
                <TableCell align="center">
                  <div className="flex justify-center">
                    {content.disabled_at ? (
                      <ButtonCustomer
                        onClick={() => {
                          onActive(content);
                        }}
                        className="bg-blue text-white"
                        size="small"
                        rounded
                      >
                        復旧
                      </ButtonCustomer>
                    ) : (
                      <ButtonCustomer
                        onClick={() => {
                          onDeActive(content);
                        }}
                        className="bg-main_1 text-white"
                        size="small"
                        rounded
                      >
                        無効
                      </ButtonCustomer>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    <img
                      src={MusicFileIcon}
                      alt="icon"
                      className="cursor-pointer pl-6"
                      onClick={() => handleChangePageViewMusic(content.id)}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Dialog open={openDialog} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle className="text-center">公開停止コンテンツ</DialogTitle>
        <DialogContent>
          <div className="my-4">
            <TextField
              label="理由"
              placeholder="理由"
              id="Reason"
              name="Reason"
              multiline
              minRows={6}
              fullWidth
              onChange={(e) => {
                setReason(e.target.value);
              }}
              value={reason}
              error={validateError !== ''}
              helperText={validateError !== '' ? validateError : ''}
            />
            <div className="text-center mt-8 space-x-4">
              <ButtonCustomer
                onClick={onClose}
                className="w-32 border-2 border-gray rounded-3xl"
                rounded
              >
                キャンセル
              </ButtonCustomer>
              <ButtonCustomer
                disabled={!checkCanSubmit()}
                rounded
                onClick={validate}
                className="w-32 rounded-3xl bg-main_1 text-white"
              >
                送信
              </ButtonCustomer>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {dataContents.length === 0 && (
        <div>
          <NoData />
        </div>
      )}
    </div>
  );
}

export default TableContents;
