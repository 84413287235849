import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { ICreateNewsRequest, INews } from 'handler/news/typings';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

import { CKEditor } from '@ckeditor/ckeditor5-react';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createNews, updateNews } from 'handler/news';
import ButtonCustomer from 'components/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { uploadImage } from 'handler/banner';
import ViewCkEditor from 'components/ViewCKEditor';
import CKEditorCommon from 'components/CKEditorCommon';

interface Props {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  dataEdit?: INews;
  onSuccess: () => void;
}

function CreateBanner(props: Props) {
  const { open, handleClose, isEdit, dataEdit, onSuccess } = props;
  const [valueEditor, setValueEditor] = useState('');
  const [isShowPrev, setIsShowPrev] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [publish, setPublish] = useState<Dayjs | null>(dayjs());

  const initialValues = {
    title: '',
    content: '',
    publish: dayjs(),
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .max(255, 'タイトル255文字以内を入力してください。'),
  });

  const handleSubmit = async (value: any) => {
    if (!isEdit) {
      handleCreateNews(value);
      return;
    }
    handleUpdateNews(value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    initialErrors: {},
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (dataEdit) {
      formik.setValues({
        title: dataEdit.title,
        content: dataEdit.content,
        publish: dayjs(dataEdit.publish),
      });
      setValueEditor(dataEdit.content);
      setPublish(dayjs(dataEdit.publish));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const success = () => {
    toast.success(`${isEdit ? 'ニュースが更新されました。' : 'ニュースが作成されました。'}`);
    onSuccess();
  };

  const handleCreateNews = async (value: any) => {
    const data: ICreateNewsRequest = {
      title: value.title,
      content: valueEditor,
      publish: publish?.format('YYYY-MM-DD HH:mm:ss'),
    };
    const response = await createNews(data);

    if (response) {
      success();
    }
  };

  const handleUpdateNews = async (value: any) => {
    const data: ICreateNewsRequest = {
      title: value.title,
      content: valueEditor,
      publish: publish?.format('YYYY-MM-DD HH:mm:ss'),
    };
    const response = await updateNews(data, dataEdit?.id);

    if (response) {
      success();
    }
  };

  const checkCanSubmit = () => {
    const title = formik.values.title;
    const content = valueEditor;

    return title && content;
  };

  const handlePreview = () => {
    setIsShowPrev(!isShowPrev);
  };

  return (
    <div>
      <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle className="text-center">
          {isEdit ? ' ニュース編集' : 'ニュース作成'}
        </DialogTitle>
        <DialogContent>
          {!isShowPrev ? (
            <form onSubmit={formik.handleSubmit} className="space-y-4 py-3">
              <TextField
                label="タイトル"
                id="title"
                name="title"
                className="w-[850px] bg-white"
                onChange={formik.handleChange}
                value={formik.values.title}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />

              <CKEditorCommon
                valueEditor={valueEditor}
                onHandleData={(data) => setValueEditor(data)}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="公開日"
                  className="w-[850px] bg-white"
                  value={publish}
                  ampm={false}
                  onChange={(newDate) => {
                    setPublish(newDate);
                  }}
                  renderInput={(params) => <TextField {...params} name="publish" />}
                />
              </LocalizationProvider>
              <div className="space-x-4 mx-auto mt-8 font-bold text-center">
                <ButtonCustomer
                  onClick={handleClose}
                  className="w-32 border-2 border-gray rounded-3xl"
                  rounded
                >
                  キャンセル
                </ButtonCustomer>
                <ButtonCustomer
                  onClick={handlePreview}
                  className="w-32 border-2 bg-green text-white rounded-3xl border-gray"
                  rounded
                  type="button"
                >
                  プレビュー
                </ButtonCustomer>
                <ButtonCustomer
                  disabled={!checkCanSubmit()}
                  rounded
                  type="submit"
                  className="w-32 rounded-3xl bg-main_1 text-white"
                >
                  保存
                </ButtonCustomer>
              </div>
            </form>
          ) : (
            <div className=" min-h-[50px] min-w-[850px] max-w-[850px] ">
              <ViewCkEditor valueEditor={valueEditor} />
              <div className="flex justify-center w-[100%]">
                <ButtonCustomer
                  onClick={handlePreview}
                  className="w-40 mt-2 border-2 bg-green text-white rounded-3xl border-gray"
                  rounded
                  type="button"
                >
                  プレビューを閉じる
                </ButtonCustomer>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateBanner;
