import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, Switch, TextField, Stack, Button } from '@mui/material';
import Title from 'components/title/title';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';

import { getAllUserInfo } from 'handler/user-management';
import { IUser, IUserPayload } from 'handler/user-management/typings';
import ButtonCustomer from 'components/button';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import Pagination from 'components/pagination';
import Table from './table';
import './index.scss';
import CreateModal from './create-modal';
import ContentModalComponent from './content-modal';

const LIMIT = 10;

const SubAccountManagement = (props: any) => {
  const dispatch = useAppDispatch();
  const [isGetAll, setGetAll] = useState(false);
  const [dataUser, setDataUser] = useState<IUser[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [openUserContentDialog, setOpenUserContentDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState<IUserPayload>();
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  // const [search, setSearch] = useState<string>('');
  const [params, setParams] = useState({
    page: 1,
    search: '',
    isGetAll: false,
    type: 2,
  });

  const getAllUserApi = useCallback(async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await getAllUserInfo(
        params.isGetAll,
        params.page,
        LIMIT,
        params.search,
        params.type
      );
      setDataUser(response?.data?.data);
      setTotal(response?.data?.total);
      setLastPage(response?.data?.last_page);

      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
    }
  }, [dispatch, params.isGetAll, params.page, params.search]);

  useEffect(() => {
    getAllUserApi();
  }, [getAllUserApi, params]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await setPage(newPage);
    // await setSearch(params.search);
    await setParams({ ...params, ...{ page: newPage } });
  };

  const onReload = () => {
    getAllUserApi();
  };

  const onSuccess = () => {
    setOpenDialog(false);
    setIsEdit(false);
    onReload();
    if (currentUserId) setCurrentUserId(0);
  };

  const handleOpenEdit = (userId: number, name: string, password: string) => {
    setDataEdit({ name: name, password: password });
    setCurrentUserId(userId);
    setIsEdit(true);
    setOpenDialog(true);
  };

  const handleOpenUserContent = (userId: number) => {
    setCurrentUserId(userId);
    setOpenUserContentDialog(true);
  };

  // const onChangeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearch(event.target.value);
  // };

  // const onClickSearch = (event?: React.KeyboardEvent<HTMLDivElement>) => {
  //   if (event?.key === 'Enter' || !event) {
  //     setPage(1);
  //     setParams({ ...params, search: search, page: 1 });
  //   }
  // };
  return (
    <div className="w-full h-main bg-white user-container">
      <div className="main-header">
        <Title title="子供アカウント管理" subTitle="子供アカウント管理" />
        <div className="header-actions flex justify-between items-center">
          <Stack direction="row" spacing={0} alignItems="center" className="">
            <Switch
              checked={isGetAll}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setGetAll(event.target.checked);
                setParams({ ...params, ...{ isGetAll: event.target.checked } });
              }}
            />
            <div className="w-full">
              <span>{`全ての子供アカウント (削除された子供アカウント）`}</span>
            </div>
          </Stack>
          <div>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              color="primary"
              className="h-[41px]"
              onClick={() => {
                setOpenDialog(true);
                setDataEdit(undefined);
                setIsEdit(false);
              }}
            >
              新しい作成
            </Button>
          </div>
          {/* <div className="flex items-center gap-2 justify-end">
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              className="w-[300px]"
              onChange={onChangeSearchKey}
              value={search}
              onKeyDown={(e) => onClickSearch(e)}
              placeholder="メール、ユーザーID、ユーザー名など"
              sx={{
                input: {
                  '&::placeholder': {
                    fontSize: 13,
                  },
                },
              }}
            />
            <ButtonCustomer
              name="検索"
              size="medium"
              rounded={true}
              className="bg-blue text-white"
              onClick={() => onClickSearch()}
            />
          </div> */}
        </div>
      </div>
      <div className="main-body">
        <Table
          dataUser={dataUser}
          onRestoreSuccess={() => getAllUserApi()}
          onOpenEdit={handleOpenEdit}
          onOpenUserContent={handleOpenUserContent}
        />

        <div className="pagination">
          <Pagination
            limit={LIMIT}
            total={total}
            onChange={onChangePaginate}
            page={page}
            hidden={dataUser.length === 0}
            lastPage={lastPage}
          />
        </div>
      </div>
      <CreateModal
        userId={currentUserId}
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        isEdit={isEdit}
        dataEdit={dataEdit}
        successAction={onSuccess}
      />
      <ContentModalComponent
        userId={currentUserId}
        open={openUserContentDialog}
        handleClose={() => setOpenUserContentDialog(false)}
      />
    </div>
  );
};

export default SubAccountManagement;
