const Footer = () => {
  return (
    <>
      <footer className="bg-primary text-black py-1 px-4 md:px-8 text-16 fixed bottom-0 w-full">
        <div className="text-center my-4 text-18 text-white">Create by Sotatek Company</div>
      </footer>
    </>
  );
};

export default Footer;
