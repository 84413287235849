import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { IInquiry } from 'handler/inquiry/typings';
import { NavLink } from 'react-router-dom';
import NoData from 'components/no-data';
import moment from 'moment';
import DotIcon from 'assets/icons/dot.svg';
import DotBlueIcon from 'assets/icons/dot-blue.svg';
import { linkify } from 'utils/common';
interface Props {
  dataUser: IInquiry[];
  onRestoreSuccess: () => void;
}
function TableUser(props: Props) {
  const { dataUser } = props;

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>メールアドレス</TableCell>
            <TableCell>日付け</TableCell>
            <TableCell>質問</TableCell>
            <TableCell>ステータス</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataUser.length > 0 &&
            dataUser.map((inquiry: IInquiry, index: number) => (
              <TableRow key={index}>
                <TableCell>
                  <NavLink
                    className={'text-blue hover:underline font-bold flex flex-row items-center'}
                    to={`/inquiry-detail/` + inquiry.id}
                  >
                    <div className="w-[10px] h-[10px]">
                      {inquiry?.read_at ? (
                        <img src={DotIcon} alt="" width={10} />
                      ) : (
                        <img src={DotBlueIcon} alt="" width={10} />
                      )}
                    </div>
                    <span className="flex flex-1 pl-5">{inquiry.email}</span>
                  </NavLink>
                </TableCell>
                <TableCell className="w-40 truncate">
                  {moment(inquiry.created_at).format('YYYY/MM/DD HH:mm')}
                </TableCell>
                <TableCell>
                  <span className="w-96 text-ellipsis overflow-hidden span-cell">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: linkify(inquiry.content),
                      }}
                    ></p>
                  </span>
                </TableCell>
                <TableCell className="w-40 truncate">
                  {inquiry.is_answer === 1 ? '解決済み' : '未解決'}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {dataUser.length === 0 && (
        <div>
          <NoData />
        </div>
      )}
    </div>
  );
}

export default TableUser;
