import CONTENT_API from 'constant/api/content';
import { IResponse } from 'models/common';
import ApiUtils from 'utils/api/api.utils';

export const getContentDetail = async (id: string | undefined) => {
  const response: IResponse = await ApiUtils.get(CONTENT_API.GET_CONTENT + '/' + id);
  return response?.data;
};

export const putUpdateCopyRight = async (id: string | undefined, data: object) => {
  const response: IResponse = await ApiUtils.put(
    CONTENT_API.UPDATE_COPYRIGHT_CONTENT + '/' + id,
    data
  );
  return response?.data;
};

export const postFileCSV = async (data: any) => {
  const response: IResponse = await ApiUtils.postForm(CONTENT_API.POST_FILE_CSV, data);
  return response?.data;
};

export const getListContent = async (
  search_key: string,
  page: number,
  limit: number,
  file_type: number,
  with_disable: number,
  user_id?: number
) => {
  const params = {
    limit,
    page,
    search_key: search_key ? search_key : null,
    with_disable,
    file_type: file_type !== -1 ? file_type : null,
    user_id: user_id,
  };

  const response: IResponse = await ApiUtils.get(CONTENT_API.GET_LIST, params, { isAuth: true });
  return response?.data;
};

export const getListContentForCollection = async (
  search_key: string,
  page: number,
  limit: number,
  file_type: number,
  with_disable: number
) => {
  const params = {
    limit,
    page,
    search_key: search_key ? search_key : null,
    with_disable,
    file_type: file_type !== -1 ? file_type : null,
  };

  const response: IResponse = await ApiUtils.get(CONTENT_API.GET_LIST_FOR_COLLECTION, params, {
    isAuth: true,
  });
  return response?.data;
};

export const disableContent = async (id: number, reason: string) => {
  const data = {
    reason,
  };
  const response: IResponse = await ApiUtils.put(CONTENT_API.DISABLE + '/' + id, data);
  return response?.data;
};

export const activeContent = async (id: number) => {
  const response: IResponse = await ApiUtils.put(CONTENT_API.ENABLE + '/' + id);
  return response?.data;
};

export const editPermission = async (params: {
  set_all?: 0 | 1;
  content_id?: number | string;
  can_edit?: number;
  can_delete?: number;
}) => {
  const response: IResponse = await ApiUtils.put('/admin/content/update-permission', params);
  return response?.data;
};
