import React, { useCallback, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import Pagination from 'components/pagination';
import { getRevenuesClaims } from 'handler/bonus-management';
import { IRevenueClaim } from 'handler/bonus-management/typings';
import NoData from 'components/no-data';
import moment from 'moment';

interface Props {
  userId: number;
}

const LIMIT = 10;

const getStatus = (text: string) => {
  let status = '保留中';
  switch (text) {
    case 'in_progress':
      status = '処理中';
      break;
    case 'completed':
      status = '完了';
      break;
    case 'canceled':
      status = 'キャンセル済み';
      break;
    case 'pending':
      status = '保留中';
      break;
    case 'rejected':
      status = '拒否済み';
      break;
    case 'transferred':
      status = '振込済み';
      break;
    default:
      break;
  }

  return status;
};

function RevenueHistories(props: Props) {
  const { userId } = props;
  const [revenuesClaims, setRevenuesClaims] = useState<IRevenueClaim[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const getAllUserApi = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getRevenuesClaims(page, LIMIT, '', userId);
      setRevenuesClaims(response?.data?.data);
      setTotal(response?.data?.total);
      setLastPage(response?.data?.last_page);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [page, userId]);

  useEffect(() => {
    getAllUserApi();
  }, [getAllUserApi]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  return (
    <div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>順</TableCell>
              <TableCell>日付</TableCell>
              <TableCell>リクエスト収入</TableCell>
              <TableCell>ステータス</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {revenuesClaims.length > 0 &&
              revenuesClaims.map((item: IRevenueClaim) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{moment(item.created_at).format('YYYY/MM/DD HH:mm:ss')}</TableCell>
                  <TableCell>￥{item.amount}</TableCell>
                  <TableCell>{getStatus(item.status)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {revenuesClaims.length === 0 && !loading && <NoData />}
      </div>
      <div className="absolute bottom-2 right-5 bigDesktop:bottom-6">
        <Pagination
          limit={LIMIT}
          total={total}
          onChange={onChangePaginate}
          page={page}
          hidden={revenuesClaims.length === 0}
          lastPage={lastPage}
        />
      </div>
    </div>
  );
}

export default RevenueHistories;
