import React from 'react';
import './index.scss';
interface Props {
  title: string;
}

function CardTitle(props: Props) {
  return (
    <div>
      <p className="title-user">{props.title}</p>
      <div className="title-border" />
    </div>
  );
}

export default CardTitle;
