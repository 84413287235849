import ApiUtils from 'utils/api/api.utils';
import API_BANNER from 'constant/api/banner';
import { IResponse } from 'models/common';
import { API_COLLECTIONS } from 'constant/api/collections';

export const createBanner = async (formData: any) => {
  const response = await ApiUtils.postForm(API_BANNER.CREATE, formData, { isAuth: true });

  return response;
};

export const updateBanner = async (formData: any, id: number) => {
  const response = await ApiUtils.postForm(API_BANNER.UPDATE + '/' + id, formData, {
    isAuth: true,
  });

  return response;
};

export const getListCollections = async (
  sort: number,
  search: string,
  page: number,
  limit: number
) => {
  const params = {
    longterm: sort === -1 ? null : sort,
    search: search,
    page: page,
    limit: limit,
  };
  const response: IResponse = await ApiUtils.get(API_COLLECTIONS.GET_LIST, params, {
    isAuth: true,
  });

  return response.data;
};

export const createCollections = async (formData: any) => {
  const response: IResponse = await ApiUtils.postForm(API_COLLECTIONS.CREATE, formData, {
    isAuth: true,
  });
  return response.data;
};

export const deleteCollections = async (id: number) => {
  const response: IResponse = await ApiUtils.remove(API_COLLECTIONS.DELETE + '/' + id, {
    isAuth: true,
  });
  return response.data;
};

export const toggleCollections = async (id: number) => {
  const response: IResponse = await ApiUtils.put(API_COLLECTIONS.TOGGLE_COLLECTION + '/' + id, {
    isAuth: true,
  });
  return response.data;
};

export const getCollectionDetail = async (id: string) => {
  const response: IResponse = await ApiUtils.get(
    API_COLLECTIONS.GET_DETAIL + '/' + id,
    {},
    {
      isAuth: true,
    }
  );
  return response.data;
};

export const updateCollections = async (formData: any, id: string | undefined) => {
  const response: IResponse = await ApiUtils.postForm(API_COLLECTIONS.UPDATE + '/' + id, formData, {
    isAuth: true,
  });
  return response.data;
};
