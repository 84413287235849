import CKEditorCommon from 'components/CKEditorCommon';
import ButtonCustomer from 'components/button';
import Title from 'components/title/title';
import { getCompanyProfile, updateCompanyProfile } from 'handler/update-info-web';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const CompanyProfile = () => {
  const [dataText, setDataText] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getCompanyProfileApi();
  }, []);

  const onSave = () => {
    updateCompanyProfileApi(dataText);
  };

  const getCompanyProfileApi = async () => {
    try {
      const { data } = await getCompanyProfile();
      setDataText(data?.content || '');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const updateCompanyProfileApi = async (content: string) => {
    try {
      const data = await updateCompanyProfile({ content: content });
      toast.success('正常に更新されました');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <div className="w-full h-main  collections-container">
      <div className="pt-2">
        <Title title="会社概要" />
      </div>
      <div className="pt-10 w-[850px]">
        <CKEditorCommon
          disable={!isEdit}
          valueEditor={dataText}
          onHandleData={(data) => setDataText(data)}
        />
        <div className="pt-10 flex justify-center w-[100%]">
          {isEdit ? (
            <>
              <ButtonCustomer
                rounded
                type="submit"
                className="w-32 rounded-3xl  text-black border-gray border-2 mr-1"
                onClick={() => setIsEdit(false)}
              >
                キャンセル
              </ButtonCustomer>
              <ButtonCustomer
                rounded
                type="submit"
                className="w-32 rounded-3xl bg-main_1 text-white ml-1"
                onClick={() => {
                  setIsEdit(false);
                  onSave();
                }}
              >
                保存
              </ButtonCustomer>
            </>
          ) : (
            <ButtonCustomer
              rounded
              type="submit"
              className="w-32 rounded-3xl bg-green text-white ml-1"
              onClick={() => setIsEdit(true)}
            >
              編集
            </ButtonCustomer>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
