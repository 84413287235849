import { TextField } from '@mui/material';
import CKEditorCommon from 'components/CKEditorCommon';
import ButtonCustomer from 'components/button';
import Title from 'components/title/title';
import { createFAQ, detailFAQ, updateFAQ } from 'handler/update-info-web';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const FAQ = () => {
  const params = useParams();
  const navigator = useNavigate();
  const idFAQ = params?.id;
  const [dataText, setDataText] = useState('');
  const [title, setTitle] = useState(' ');
  const [isEdit, setIsEdit] = useState(idFAQ ? false : true);

  useEffect(() => {
    if (!idFAQ) return;
    detailFAQApi(idFAQ);
  }, [idFAQ]);

  const onSaveCreate = () => {
    if (title.trim().length === 0 || dataText.length === 0) {
      return;
    }
    createFAQApi({
      title: title,
      content: dataText,
    });
  };

  const onUpdate = () => {
    if (title.trim().length === 0 || dataText.length === 0) {
      return;
    }
    updateFAQApi({
      title: title,
      content: dataText,
    });
  };

  const createFAQApi = async (dataCreate: object) => {
    try {
      const data = await createFAQ(dataCreate);
      toast.success('新規作成に成功しました');
      navigator('/update-faq');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const updateFAQApi = async (dataUpdate: object) => {
    if (!idFAQ) return;
    try {
      const data = await updateFAQ(dataUpdate, idFAQ);
      toast.success('新しく更新されました');
      navigator('/update-faq');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const detailFAQApi = async (id: string) => {
    try {
      const { data } = await detailFAQ(id);
      setTitle(data?.title);
      setDataText(data?.content);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <div className="w-full h-main collections-container pl-6 pt-5">
      <div className="pt-2 flex items-center justify-between w-[850px]">
        <Title title="FAQ" />
      </div>

      <div className="pt-5 w-[850px]">
        <div className="flex items-start flex-col pb-5">
          <p>タイトル</p>
          <TextField
            disabled={!isEdit}
            error={!title}
            value={title}
            className="w-full"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            helperText={!title ? 'タイトルを入力する必要があります' : ''}
          />
        </div>

        <CKEditorCommon
          disable={!isEdit}
          valueEditor={dataText}
          onHandleData={(data) => setDataText(data)}
        />
        <div className="pt-10 flex justify-center w-[100%]">
          <ButtonCustomer
            rounded
            type="submit"
            className="w-32 rounded-3xl  text-black border-gray border-2 mr-1"
            onClick={() => {
              navigator('/update-faq');
            }}
          >
            キャンセル
          </ButtonCustomer>

          {isEdit ? (
            <ButtonCustomer
              rounded
              type="submit"
              className="w-32 rounded-3xl bg-main_1 text-white ml-1"
              onClick={() => {
                idFAQ ? (setIsEdit(false), onUpdate()) : (setIsEdit(true), onSaveCreate());
              }}
              disabled={title.trim().length === 0 || dataText.length === 0 ? true : false}
            >
              保存
            </ButtonCustomer>
          ) : (
            <ButtonCustomer
              rounded
              type="submit"
              className="w-32 rounded-3xl bg-green text-white ml-1"
              onClick={() => setIsEdit(true)}
            >
              編集
            </ButtonCustomer>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
