import { IResponse } from 'models/common';
import ApiUtils from 'utils/api/api.utils';
import INFOR_WEB_API from 'constant/api/info-web';

export const getCompanyProfile = async () => {
  const response: IResponse = await ApiUtils.get(INFOR_WEB_API.GET_COMPANY_PROFILE);
  return response.data;
};

export const updateCompanyProfile = async (content: object) => {
  const response: IResponse = await ApiUtils.put(INFOR_WEB_API.UPDATE_COMPANY_PROFILE, content);
  return response.data;
};

export const getPolicy = async () => {
  const response: IResponse = await ApiUtils.get(INFOR_WEB_API.GET_POLICY);
  return response.data;
};

export const updatePolicy = async (content: object) => {
  const response: IResponse = await ApiUtils.put(INFOR_WEB_API.UPDATE_POLICY, content);
  return response.data;
};

export const getTerms = async () => {
  const response: IResponse = await ApiUtils.get(INFOR_WEB_API.GET_TERMS);
  return response.data;
};

export const updateTerms = async (content: object) => {
  const response: IResponse = await ApiUtils.put(INFOR_WEB_API.UPDATE_TERMS, content);
  return response.data;
};

export const createGuide = async (data: object) => {
  const response: IResponse = await ApiUtils.post(INFOR_WEB_API.CREATE_GUIDE, data);
  return response.data;
};

export const updateGuide = async (data: object, id: string) => {
  const response: IResponse = await ApiUtils.put(INFOR_WEB_API.UPDATE_GUIDE + '/' + id, data);
  return response.data;
};

export const detailGuide = async (id: string) => {
  const response: IResponse = await ApiUtils.get(INFOR_WEB_API.DETAIL_GUIDE + '/' + id);
  return response.data;
};

export const deleteGuide = async (id: string) => {
  const response: IResponse = await ApiUtils.remove(INFOR_WEB_API.DELETE_GUIDE + '/' + id);
  return response.data;
};

export const createFAQ = async (data: object) => {
  const response: IResponse = await ApiUtils.post(INFOR_WEB_API.CREATE_FAQ, data);
  return response.data;
};

export const updateFAQ = async (data: object, id: string) => {
  const response: IResponse = await ApiUtils.put(INFOR_WEB_API.UPDATE_FAQ + '/' + id, data);
  return response.data;
};

export const detailFAQ = async (id: string) => {
  const response: IResponse = await ApiUtils.get(INFOR_WEB_API.DETAIL_FAQ + '/' + id);
  return response.data;
};

export const deleteFAQ = async (id: string) => {
  const response: IResponse = await ApiUtils.remove(INFOR_WEB_API.DELETE_FAQ + '/' + id);
  return response.data;
};

export const getListGuide = async (params: object) => {
  const response: IResponse = await ApiUtils.get(INFOR_WEB_API.GET_LIST_GUIDE, params);
  return response.data;
};

export const getListFAQ = async (params: object) => {
  const response: IResponse = await ApiUtils.get(INFOR_WEB_API.GET_LIST_FAQ, params);
  return response.data;
};
