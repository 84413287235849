const BANNER_API = {
  CREATE: '/admin/banner/create',
  UPDATE: '/admin/banner/update',
  DELETE: '/admin/banner/delete',
  PUT_BANNER_TOGGLE: '/admin/banner/toggle',
  GET_ALL: 'admin/banner',
  GET_BY_ID: '/admin/banner/detail',
  UPDATE_POSITION: '/admin/banner/update-position',
  UPLOAD_FILE: '/admin/upload-file',
};

export default BANNER_API;
