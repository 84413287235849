import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import UpdateNewemail from 'pages/update-new-email';
import React from 'react';
import s from './style.module.scss';
import ChangePassword from 'pages/change-password';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Setting = () => {
  const [valueTab, setValueTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  return (
    <div className={s.container}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={valueTab} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="メール変更" {...a11yProps(0)} />
            <Tab label="パスワード変更" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={valueTab} index={0}>
          <UpdateNewemail />
        </CustomTabPanel>
        <CustomTabPanel value={valueTab} index={1}>
          <ChangePassword />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default Setting;
