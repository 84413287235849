import React from 'react';

const ContentType = (props: { type: string }) => {
  const { type } = props;

  let newType = type;

  if (typeof type === 'number') {
    newType = newType.toString();
  }

  const typeContent = () => {
    switch (newType) {
      case '0':
        return 'text-main_1 bg-dark_yellow';

      case '1':
        return 'text-green bg-dark_green';

      case '2':
        return 'text-blue bg-dark_blue';

      case '3':
        return 'text-red bg-plum';

      default:
        return 'text-black bg-white';
    }
  };
  const typeName = () => {
    switch (newType) {
      case '0':
        return 'PDF';
      case '1':
        return 'MIDI';

      case '2':
        return 'AUDIO';

      case '3':
        return 'VIDEO';

      default:
        return 'No data';
    }
  };
  return (
    <div className={`p-1 w-16 text-center uppercase font-bold ${typeContent()}`}>{typeName()}</div>
  );
};
export default ContentType;
