import Pagination from 'components/pagination';
import Title from 'components/title/title';
import { getRevenuesSystemAds } from 'handler/bonus-management';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const HistoryAdsRevenueSetting = () => {
  const [dataAds, setDataAds] = useState<any>(null);

  useEffect(() => {
    callApiList(1);
  }, []);

  const callApiList = async (page: number) => {
    try {
      const { data } = await getRevenuesSystemAds({ page: page });
      setDataAds(data);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    await callApiList(newPage);
  };

  return (
    <div className="w-full h-main bg-white user-container">
      <div className="main-header">
        <Title title="広告収益設定履歴" />
      </div>
      <div className="main-body">
        <div className="flex flex-col w-[600px] ">
          <div className="flex border-b-yellow border-t-0 border-l-0 border-r-0 border-[1px] py-3 mb-3">
            <div className="min-w-[300px] text-left font-bold text-[20px] border-r-yellow ">
              年月
            </div>
            <div className=" text-left font-bold text-[20px]">収入額</div>
          </div>
        </div>
        <div className="w-[600px]">
          <ul>
            {dataAds?.data?.length !== 0
              ? dataAds?.data.map((item: any) => {
                  return (
                    <>
                      <li className="flex">
                        <div className="min-w-[300px] text-left">
                          {item?.date.slice(0, 7).replace('-', '/')}
                        </div>
                        <div className="min-w-[300px] text-left">{item?.system_ads_revenue}円</div>
                      </li>
                      <div className="border-b-yellow border-t-0 border-l-0 border-r-0 mb-3 border-[1px] py-1"></div>
                    </>
                  );
                })
              : 'データなし'}
          </ul>
          <div className="pagination">
            <Pagination
              limit={10}
              total={dataAds?.total}
              onChange={onChangePaginate}
              page={dataAds?.current_page}
              hidden={dataAds?.data.length === 0}
              lastPage={dataAds?.last_page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryAdsRevenueSetting;
