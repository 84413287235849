export interface IUser {
  id: number;
  name: string;
  email: string;
  locale: string;
  phone_number: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  avatar: string;
  contents_count: number;
  status: boolean;
}

export interface IRequestRevenue {
  original_amount: number;
  amount: number;
  amount_after_fee: number;
  cancel_reason: null | string;
  created_at: string;
  id: number;
  revenue_ids: string;
  status: RequestRevenueStatus;
  updated_at: string;
  user: IUser;
  user_id: number;
}

export enum RequestRevenueStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}
