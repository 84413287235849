import { API_USER } from 'constant/api/user';
import { IResponse } from 'models/common';
import ApiUtils from 'utils/api/api.utils';
import { IUserPayload } from './typings';

export const getAllUserInfo = async (
  isDeleteUser: boolean,
  page: number,
  limit: number,
  searchKey: string,
  type?: number
) => {
  const params = {
    page: page,
    limit: limit,
    search: searchKey,
    all: isDeleteUser ? true : null,
    type: type || 1,
  };
  const response: IResponse = await ApiUtils.get(API_USER.GET_ALL, params, {
    isAuth: true,
  });

  return response?.data;
};

export const restoreUser = async (id: number) => {
  const data = {
    id: id,
  };
  const response: IResponse = await ApiUtils.post(API_USER.RESTORE_USER, data, { isAuth: true });
  return response?.data;
};

export const getUserDetail = async (id: string | undefined) => {
  const response: IResponse = await ApiUtils.get(API_USER.GET_DETAIL + '/' + id, null, {
    isAuth: true,
  });
  return response?.data;
};

export const getUserContentById = async (
  id: string | undefined,
  page: number,
  limit: number,
  searchKey: string,
  file_type: number
) => {
  const params = {
    page: page,
    limit: limit,
    search: searchKey,
    file_type: file_type !== -1 ? file_type : null,
  };
  const response: IResponse = await ApiUtils.get(
    API_USER.GET_CONTENT + '/' + id + '/contents',
    params,
    {
      isAuth: true,
    }
  );
  return response?.data;
};

export const deleteUser = async (id: number, reason: string) => {
  const response: IResponse = await ApiUtils.remove(
    API_USER.DELETE_USER + `/${id}`,
    {
      reason_delete: reason,
    },
    {
      isAuth: true,
    }
  );
  return response?.data;
};

export const changeEmailRequest = async (data: any) => {
  const response: IResponse = await ApiUtils.post(API_USER.CHANGE_EMAIL_REQUEST, data, {
    isAuth: true,
  });
  return response?.data;
};

export const changeEmailVerify = async (data: any) => {
  const response: IResponse = await ApiUtils.post(API_USER.CHANGE_EMAIL_VERIFY, data, {
    isAuth: true,
  });
  return response?.data;
};

export const changeEmailResendCode = async (data: any) => {
  const response: IResponse = await ApiUtils.post(API_USER.CHANGE_EMAIL_RESEND_CODE, data, {
    isAuth: true,
  });
  return response?.data;
};

export const changePassword = async (data: any) => {
  const response: IResponse = await ApiUtils.post(API_USER.CHANGE_PASSWORD, data, {
    isAuth: true,
  });
  return response?.data;
};

export const changeEmailCancel = async (data: any) => {
  const response: IResponse = await ApiUtils.post(API_USER.CHANGE_EMAIL_CANCEL, data, {
    isAuth: true,
  });
  return response?.data;
};

export const createSubAccount = async (params: IUserPayload) => {
  const response: IResponse = await ApiUtils.post(API_USER.CREATE_MOD, params, {
    isAuth: true,
  });
  return response?.data;
};

export const updateSubAccount = async (id: number, payload: IUserPayload) => {
  const response: IResponse = await ApiUtils.put(API_USER.EDIT_MOD + `/${id}`, payload, {
    isAuth: true,
  });
  return response?.data;
};
