import React from 'react';
import './index.scss';
interface Props {
  name?: string;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  color?: string;
  icon?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  rounded?: boolean;
  type?: 'button' | 'submit' | 'reset';
}
function ButtonCustomer(props: Props) {
  const { name, onClick, size, disabled, color, className, children, rounded, type } = props;

  let stringSize = '';
  switch (size) {
    case 'small':
      stringSize = 'px-2 py-1 h-[36px] w-[90px]';
      break;
    case 'medium':
      stringSize = 'px-4 py-2 h-[41px] w-[120]';
      break;
    case 'large':
      stringSize = 'px-8 py-4 font-bold';
      break;
    default:
      stringSize = 'px-4 py-2';
  }
  return (
    <button
      className={`text-14 font-bold whitespace-nowrap ${color} ${stringSize} ${
        rounded ? 'rounded-md' : ''
      } ${className} button-customer  ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      <span>{children}</span>
      <span>{name}</span>
    </button>
  );
}

export default ButtonCustomer;
