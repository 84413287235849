import React, { useEffect, useState } from 'react';
import s from './style.module.scss';
import Title from 'components/title/title';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'stores/hooks';
import { SET_LOADING } from 'stores/const/app';
import { postFileCSV } from 'handler/content/intex';
import { SubmitHandler, useForm } from 'react-hook-form';

const UploadContentManage = () => {
  const [fileUpload, setFileUpload] = useState<any>();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit: SubmitHandler<any> = async (data) => {
    toast.dismiss();
    try {
      dispatch({ type: SET_LOADING, payload: true });

      const formData = new FormData();
      formData.append('file', fileUpload);
      formData.append('email', data?.email);
      const response = await postFileCSV(formData);
      toast.success(response?.data?.message);
      setFileUpload(undefined);
      setValue('email', '');

      dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      setFileUpload(undefined);
    }
  };

  const onUploadFile = (e: any) => {
    toast.dismiss();
    const file = e.target.files;
    if (file[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      toast.error('XLSX ファイルのみをアップロードしてください!');
      return;
    }
    setFileUpload(file[0]);
    e.target.value = '';
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="h-[100%]">
      <div className={s.container}>
        <Title title="コンテンツをアップロード" />
        <br />
        <TextField
          variant="filled"
          label="メールアドレス"
          size="small"
          id="email"
          type="email"
          error={errors.email ? true : false}
          helperText={errors.email ? 'メールアドレスを入力してください' : ''}
          {...register('email', { required: true })}
        />
        <div className={s.boxUploadFile}>
          <TextField
            size="small"
            id="inputName"
            disabled
            value={fileUpload ? fileUpload?.name : ''}
          />
          <label htmlFor="uploadFile" className={s.btnUpload}>
            添付
          </label>
          <input
            onChange={onUploadFile}
            id="uploadFile"
            type="file"
            style={{ display: 'none' }}
            accept=".xls,.xlsx"
          />
        </div>
        <span>※ XLSX ファイルのみを受け入れます</span>
        <Button
          type="submit"
          disabled={fileUpload ? false : true}
          variant="contained"
          className={s.btnSave}
        >
          Upload
        </Button>
      </div>
    </form>
  );
};

export default UploadContentManage;
