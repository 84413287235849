const AUTH_API = {
  LOGIN: '/admin/login',
  TOW_FACTOR: {
    RE_SEND: '/two-factor/resend-code',
    REVIFY: '/two-factor/verify',
  },
  CHANGE_PASSWORD: '/change-password',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
};

export default AUTH_API;
