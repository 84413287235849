import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';
import ButtonCustomer from 'components/button';
import { useFormik } from 'formik';
import { createSubAccount, updateSubAccount } from 'handler/user-management';
import { IUserPayload } from 'handler/user-management/typings';
import { toast } from 'react-toastify';
import {
  REGEX_EMAIL,
  REGEX_ONLY_SPACES,
  REGEX_PASSWORD,
  REGEX_STRING_NORMALLY,
} from 'utils/common';

interface Props {
  userId?: number;
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  dataEdit?: IUserPayload;
  successAction: () => void;
}

const passwordErrorText =
  'パスワードに使用できる文字は次の通りです。\n半角大英字、小英字、数字、記号の４種類以上組み合わせ、6文字以上20文字以内で入力してください。';

function CreateModal(props: Props) {
  const { open, handleClose, isEdit, dataEdit, successAction, userId } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = isEdit
    ? {
        name: '',
        password: '',
      }
    : {
        name: '',
        email: '',
        password: '',
      };

  const validationSchema = Yup.object().shape(
    isEdit
      ? {
          name: Yup.string()
            .matches(
              REGEX_ONLY_SPACES,
              'スペースのみが入力されています。\n絵文字以外、30文字以内で入力してください。'
            )
            .required(
              'ユーザー名が入力されていません。\n絵文字以外、30文字以内で入力してください。'
            )
            .trim()
            .min(
              1,
              'ユーザー名に使用できる文字は次の通りです。\n絵文字以外、30文字以内で入力してください。'
            )
            .max(
              30,
              'ユーザー名に使用できる文字は次の通りです。\n絵文字以外、30文字以内で入力してください。'
            )
            .matches(
              REGEX_STRING_NORMALLY,
              'ユーザー名に使用できる文字は次の通りです。\n絵文字以外、30文字以内で入力してください。'
            ),
        }
      : {
          name: Yup.string()
            .matches(
              REGEX_ONLY_SPACES,
              'スペースのみが入力されています。\n絵文字以外、30文字以内で入力してください。'
            )
            .required(
              'ユーザー名が入力されていません。\n絵文字以外、30文字以内で入力してください。'
            )
            .trim()
            .min(
              1,
              'ユーザー名に使用できる文字は次の通りです。\n絵文字以外、30文字以内で入力してください。'
            )
            .max(
              30,
              'ユーザー名に使用できる文字は次の通りです。\n絵文字以外、30文字以内で入力してください。'
            )
            .matches(
              REGEX_STRING_NORMALLY,
              'ユーザー名に使用できる文字は次の通りです。\n絵文字以外、30文字以内で入力してください。'
            ),
          password: Yup.string()
            .matches(
              REGEX_ONLY_SPACES,
              'スペースのみ又はスペースを含んだパスワードを入力しました。\n半角英数字6文字以上20文字以内で入力してください。'
            )
            .required('パスワードは必須項目です。')
            .min(6, passwordErrorText)
            .max(20, passwordErrorText)
            .matches(REGEX_PASSWORD, passwordErrorText),
          email: Yup.string()
            .matches(
              REGEX_ONLY_SPACES,
              'スペースのみが入力されています。\n有効なメールアドレスを入力してください。'
            )
            .trim()
            .required('メールアドレスは必須項目です。')
            .email('メール形式が正しくありません。有効なメールアドレスを入力してください。')
            .matches(
              REGEX_EMAIL,
              'メール形式が正しくありません。有効なメールアドレスを入力してください。'
            ),
        }
  );

  const handleSuccess = () => {
    toast.success(`${isEdit ? '編集が成功しました。' : '作成が成功しました。'}`);
    successAction();
    formik.resetForm();
  };

  const handleCreateSubAccount = (value: IUserPayload) => {
    createSubAccount(value).then((data) => {
      handleSuccess();
    });
  };

  const handleUpdateSubAccount = (value: IUserPayload) => {
    if (!userId) return;
    updateSubAccount(userId, value).then((data) => {
      handleSuccess();
    });
  };

  const handleSubmit = async (value: any) => {
    if (!isEdit) {
      handleCreateSubAccount(value);
      return;
    }
    handleUpdateSubAccount(value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    initialErrors: {},
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onClose = () => {
    setShowPassword(false);
    formik.resetForm();
    handleClose();
  };

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  useEffect(() => {
    if (dataEdit) {
      formik.setValues({
        name: dataEdit.name,
        password: dataEdit.password,
      });
    }
  }, [dataEdit]);

  return (
    <Dialog className="p-4" open={openDialog} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="text-center">{isEdit ? 'ユーザー編集' : 'ユーザー登録'}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent className="flex flex-col justify-center items-center gap-5">
          {!dataEdit && (
            <TextField
              className="w-full"
              required
              label="メールアドレス"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={!!formik.errors.email}
              helperText={formik.errors.email}
              autoComplete="one-time-code"
            />
          )}
          <TextField
            className="w-full"
            required
            label="ユーザー名"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={!!formik.errors.name}
            helperText={formik.errors.name}
            autoComplete="one-time-code"
          />
          <TextField
            className="w-full"
            type={showPassword ? 'text' : 'password'}
            required
            label="パスワード"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={!!formik.errors.password}
            helperText={formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment className="cursor-pointer" position="end">
                  <div onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </div>
                </InputAdornment>
              ),
            }}
            autoComplete="one-time-code"
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>
          <ButtonCustomer
            type="reset"
            onClick={onClose}
            className="w-32 border-2 border-gray rounded-3xl"
          >
            キャンセル
          </ButtonCustomer>
          <ButtonCustomer type="submit" className="w-32  rounded-3xl bg-main_1 text-white">
            保存
          </ButtonCustomer>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateModal;
