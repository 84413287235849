import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import SideBar from 'layout/sidebar';
import Container from 'layout/container';
import { useAppSelector } from 'stores/hooks';
import { RootState } from 'stores/store';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const userInfo = useAppSelector((state: RootState) => state.authSaga);
  useEffect(() => {
    if (!userInfo.auth.access_token) {
      setIsAuthenticated(false);
    }
  }, [userInfo]);

  return isAuthenticated ? (
    <React.Fragment>
      <div className="flex justify-center gap-4">
        <SideBar />
        <Container>
          <Component />
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
