import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

import NoData from 'components/no-data';
import { IContent } from 'handler/user-management/typings';
import { useCallback, useEffect, useState } from 'react';
import ButtonCustomer from 'components/button';
import { IUserRevenue } from 'handler/bonus-management/typings';
import {
  getDownloadContent,
  getDownloadContentHistories,
  getUserRevenues,
} from 'handler/bonus-management';
import FadeLoader from 'react-spinners/FadeLoader';
import moment from 'moment';
import Pagination from 'components/pagination';

interface Props {
  userId: number;
}

interface DetailProps {
  dataUserRevenue: IUserRevenue[];
  setDetail: any;
  setDate: any;
  setLoading?: any;
  loading?: boolean;
}

interface DownloadContentProps {
  dataContent: IContent[];
  date: string;
  setDetail: any;
  setContent: any;
  setLoading?: any;
  loading?: boolean;
}

interface DetailDateProps {
  dataContentHistories: IContent[];
  userId: number;
  content: any;
  date: string;
  setDetail: any;
  setLoading?: any;
  loading?: boolean;
}

const LIMIT = 10;

function UserRevenues(props: DetailProps) {
  const { dataUserRevenue, setDetail, setDate, loading } = props;

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>日付</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>広告収入</TableCell>
            <TableCell>ダウンロード収入</TableCell>
            <TableCell>総収入</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataUserRevenue.length > 0 &&
            dataUserRevenue.map((user: IUserRevenue) => (
              <TableRow key={user.id}>
                <TableCell>{moment(user.date).format('YYYY/MM/DD')}</TableCell>
                <TableCell>{user.is_claimed ? 'Claimed' : ''}</TableCell>
                <TableCell>￥{user.user_ads_revenue}</TableCell>
                <TableCell>￥{user.user_download_revenue}</TableCell>
                <TableCell>￥{user.total_revenue}</TableCell>
                <TableCell
                  onClick={() => {
                    setDate(moment(user.date).format('YYYY/MM/DD'));
                    setDetail(1);
                  }}
                >
                  <ArrowForwardIos
                    style={{ width: 12, height: 12, color: '#F1A200', cursor: 'pointer' }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {dataUserRevenue.length === 0 && !loading && <NoData />}
    </div>
  );
}

function DownloadContent(props: DownloadContentProps) {
  const { dataContent, date, setDetail, setContent, loading } = props;

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>日付</TableCell>
            <TableCell>作品コード</TableCell>
            <TableCell>コンテンツタイトル</TableCell>
            <TableCell>価格</TableCell>
            <TableCell>ダウンロード数</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataContent.map((content: IContent) => (
            <TableRow key={content.id}>
              <TableCell>{date}</TableCell>
              <TableCell>{content.content_code}</TableCell>
              <TableCell>{content.copyrights && content.copyrights[0]?.song_name}</TableCell>
              <TableCell>{content?.price}</TableCell>
              <TableCell>{content.download_count}</TableCell>
              <TableCell
                onClick={() => {
                  setContent({
                    id: content.id,
                    name: content.copyrights && content.copyrights[0]?.song_name,
                  });
                  setDetail(2);
                }}
              >
                <ArrowForwardIos
                  style={{ width: 12, height: 12, color: '#F1A200', cursor: 'pointer' }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {dataContent.length === 0 && !loading && <NoData />}
      <ButtonCustomer
        name="Back"
        size="medium"
        rounded={true}
        className="text-white absolute bottom-2 rounded-full px-10 bg-main_1"
        onClick={() => setDetail(0)}
      />
    </div>
  );
}

function DownloadContentHistories(props: DetailDateProps) {
  const { dataContentHistories, date, content, setDetail, loading } = props;

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>日付</TableCell>
            <TableCell>コンテンツタイトル</TableCell>
            <TableCell>購入日時</TableCell>
            <TableCell>価格</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataContentHistories.length > 0 &&
            dataContentHistories.map((item: IContent) => (
              <TableRow key={item.id}>
                <TableCell>{date}</TableCell>
                <TableCell>{content?.name}</TableCell>
                <TableCell>{moment(item.created_at).format('YYYY/MM/DD HH:mm:ss')}</TableCell>
                <TableCell>￥{item.price}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {dataContentHistories.length === 0 && !loading && <NoData />}
      <ButtonCustomer
        name="Back"
        size="medium"
        rounded={true}
        className="text-white absolute bottom-2 rounded-full px-10 bg-main_1"
        onClick={() => setDetail(1)}
      />
    </div>
  );
}

function RevenueDetails(props: Props) {
  const { userId } = props;
  const [date, setDate] = useState('');
  const [content, setContent] = useState({
    id: 0,
    name: '',
  });
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);

  const [dataUserRevenue, setDataUserRevenue] = useState<IUserRevenue[]>([]);
  const [dataContent, setDataContent] = useState<IContent[]>([]);
  const [dataContentHistories, setDataContentHistories] = useState<IContent[]>([]);

  const getData = useCallback(async () => {
    let response;
    setDataUserRevenue([]);
    setDataContent([]);
    setDataContentHistories([]);
    try {
      setLoading(true);
      switch (type) {
        case 0:
          response = await getUserRevenues(page, LIMIT, '', userId);
          setDataUserRevenue(response?.data?.data);
          break;
        case 1:
          response = await getDownloadContent(page, LIMIT, '', date, userId);
          setDataContent(response?.data);
          break;
        case 2:
          response = await getDownloadContentHistories(page, LIMIT, '', content?.id, date);
          setDataContentHistories(response?.data);
          break;
      }
      if (response) {
        setTotal(response?.data?.total);
        setLastPage(response?.data?.last_page);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [content?.id, date, page, type, userId]);

  const onChangePaginate = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>
      {type === 1 && (
        <DownloadContent
          dataContent={dataContent}
          date={date}
          setDetail={setType}
          setContent={setContent}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {type === 2 && (
        <DownloadContentHistories
          dataContentHistories={dataContentHistories}
          date={date}
          userId={userId}
          content={content}
          setDetail={setType}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {type === 0 && (
        <UserRevenues
          dataUserRevenue={dataUserRevenue}
          setDetail={setType}
          setDate={setDate}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      <div className="absolute bottom-2 right-5 bigDesktop:bottom-6">
        <Pagination
          limit={LIMIT}
          total={total}
          onChange={onChangePaginate}
          page={page}
          hidden={
            dataUserRevenue.length === 0 &&
            dataContent.length === 0 &&
            dataContentHistories.length === 0
          }
          lastPage={lastPage}
        />
      </div>

      <div className="z-50 text-red fixed top-1/2 left-1/2 right-1/2">
        <FadeLoader
          color={'#2E253D'}
          loading={loading}
          cssOverride={{
            display: 'block',
            margin: '0 auto',
            borderColor: 'red',
          }}
        />
      </div>
    </div>
  );
}

export default RevenueDetails;
