const CONST_DATA = {
  instrument_main_categories: [
    {
      id: 1,
      ja_name: 'ピアノ',
      en_name: 'ピアノ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 2,
      ja_name: 'オルガン',
      en_name: 'オルガン',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 3,
      ja_name: 'その他の鍵盤楽器',
      en_name: 'その他の鍵盤楽器',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 4,
      ja_name: '歌詞＆コード',
      en_name: '歌詞＆コード',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 5,
      ja_name: 'メロディ',
      en_name: 'メロディ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 6,
      ja_name: 'バンド',
      en_name: 'バンド',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 7,
      ja_name: 'ギター',
      en_name: 'ギター',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 8,
      ja_name: 'ウクレレ',
      en_name: 'ウクレレ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 9,
      ja_name: '歌／合唱',
      en_name: '歌／合唱',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 10,
      ja_name: '管楽器',
      en_name: '管楽器',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 11,
      ja_name: '弦楽器',
      en_name: '弦楽器',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 12,
      ja_name: '吹奏楽',
      en_name: '吹奏楽',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 13,
      ja_name: '打楽器',
      en_name: '打楽器',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 14,
      ja_name: 'アンサンブル',
      en_name: 'アンサンブル',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 15,
      ja_name: 'オーケストラ',
      en_name: 'オーケストラ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 16,
      ja_name: '和楽器',
      en_name: '和楽器',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 17,
      ja_name: '民族楽器',
      en_name: '民族楽器',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 18,
      ja_name: 'その他の楽器',
      en_name: 'その他の楽器',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 19,
      ja_name: '教則・音楽理論',
      en_name: '教則・音楽理論',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
  ],
  instrument_sub_categories: [
    {
      id: 1,
      main_category_id: 1,
      ja_name: 'ソロ',
      en_name: 'ソロ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 2,
      main_category_id: 1,
      ja_name: '弾き語り（ピアノ＆ボーカル）',
      en_name: '弾き語り（ピアノ＆ボーカル）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 3,
      main_category_id: 1,
      ja_name: '連弾',
      en_name: '連弾',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 4,
      main_category_id: 1,
      ja_name: '2台4手',
      en_name: '2台4手',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 5,
      main_category_id: 1,
      ja_name: '合奏・伴奏',
      en_name: '合奏・伴奏',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 6,
      main_category_id: 1,
      ja_name: 'その他',
      en_name: 'その他',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 7,
      main_category_id: 2,
      ja_name: 'ソロ',
      en_name: 'ソロ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 8,
      main_category_id: 2,
      ja_name: 'アンサンブル',
      en_name: 'アンサンブル',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 9,
      main_category_id: 2,
      ja_name: 'オルガン＆ピアノ',
      en_name: 'オルガン＆ピアノ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 10,
      main_category_id: 2,
      ja_name: '弾き語り',
      en_name: '弾き語り',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 11,
      main_category_id: 3,
      ja_name: 'チェンバロ／ハープシコード',
      en_name: 'チェンバロ／ハープシコード',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 12,
      main_category_id: 3,
      ja_name: 'チェレスタ',
      en_name: 'チェレスタ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 13,
      main_category_id: 4,
      ja_name: '歌詞＆コード',
      en_name: '歌詞＆コード',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 14,
      main_category_id: 5,
      ja_name: 'メロディ（リードシート）',
      en_name: 'メロディ（リードシート）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 15,
      main_category_id: 6,
      ja_name: 'バンドスコア',
      en_name: 'バンドスコア',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 16,
      main_category_id: 6,
      ja_name: 'ギター',
      en_name: 'ギター',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 17,
      main_category_id: 6,
      ja_name: 'ベース',
      en_name: 'ベース',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 18,
      main_category_id: 6,
      ja_name: 'キーボード／シンセサイザー',
      en_name: 'キーボード／シンセサイザー',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 19,
      main_category_id: 6,
      ja_name: 'ドラムス／パーカッション',
      en_name: 'ドラムス／パーカッション',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 20,
      main_category_id: 7,
      ja_name: 'ソロ',
      en_name: 'ソロ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 21,
      main_category_id: 7,
      ja_name: '弾き語り',
      en_name: '弾き語り',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 22,
      main_category_id: 7,
      ja_name: 'コード譜',
      en_name: 'コード譜',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 23,
      main_category_id: 7,
      ja_name: 'アンサンブル',
      en_name: 'アンサンブル',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 24,
      main_category_id: 7,
      ja_name: 'その他',
      en_name: 'その他',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 25,
      main_category_id: 8,
      ja_name: 'ソロ',
      en_name: 'ソロ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 26,
      main_category_id: 8,
      ja_name: '弾き語り',
      en_name: '弾き語り',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 27,
      main_category_id: 8,
      ja_name: 'コード譜',
      en_name: 'コード譜',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 28,
      main_category_id: 8,
      ja_name: 'アンサンブル',
      en_name: 'アンサンブル',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 29,
      main_category_id: 8,
      ja_name: 'その他',
      en_name: 'その他',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 30,
      main_category_id: 9,
      ja_name: '合唱（児童合唱）',
      en_name: '合唱（児童合唱）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 31,
      main_category_id: 9,
      ja_name: '合唱（女声3部）',
      en_name: '合唱（女声3部）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 32,
      main_category_id: 9,
      ja_name: '合唱（女声4部）',
      en_name: '合唱（女声4部）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 33,
      main_category_id: 9,
      ja_name: '合唱（男童合唱）',
      en_name: '合唱（男童合唱）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 34,
      main_category_id: 9,
      ja_name: '合唱（男声4部）',
      en_name: '合唱（男声4部）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 35,
      main_category_id: 9,
      ja_name: '合唱（同声3部）',
      en_name: '合唱（同声3部）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 36,
      main_category_id: 9,
      ja_name: '合唱（同声2部）',
      en_name: '合唱（同声2部）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 37,
      main_category_id: 9,
      ja_name: '合唱（混声3部）',
      en_name: '合唱（混声3部）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 38,
      main_category_id: 9,
      ja_name: '合唱（混声4部）',
      en_name: '合唱（混声4部）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 39,
      main_category_id: 9,
      ja_name: '合唱（混声6部）',
      en_name: '合唱（混声6部）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 40,
      main_category_id: 9,
      ja_name: '合唱（その他）',
      en_name: '合唱（その他）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 41,
      main_category_id: 9,
      ja_name: '独唱',
      en_name: '独唱',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 42,
      main_category_id: 9,
      ja_name: 'アカペラ',
      en_name: 'アカペラ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 43,
      main_category_id: 9,
      ja_name: 'ゴスペル',
      en_name: 'ゴスペル',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 44,
      main_category_id: 10,
      ja_name: 'フルート',
      en_name: 'フルート',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 45,
      main_category_id: 10,
      ja_name: 'オーボエ',
      en_name: 'オーボエ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 46,
      main_category_id: 10,
      ja_name: 'ファゴット',
      en_name: 'ファゴット',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 47,
      main_category_id: 10,
      ja_name: 'クラリネット',
      en_name: 'クラリネット',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 48,
      main_category_id: 10,
      ja_name: 'アルトサックス',
      en_name: 'アルトサックス',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 49,
      main_category_id: 10,
      ja_name: 'テナーサックス',
      en_name: 'テナーサックス',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 50,
      main_category_id: 10,
      ja_name: 'ソプラノサックス',
      en_name: 'ソプラノサックス',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 51,
      main_category_id: 10,
      ja_name: 'バリトンサックス',
      en_name: 'バリトンサックス',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 52,
      main_category_id: 10,
      ja_name: 'ホルン',
      en_name: 'ホルン',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 53,
      main_category_id: 10,
      ja_name: 'トランペット',
      en_name: 'トランペット',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 54,
      main_category_id: 10,
      ja_name: 'トランボーン',
      en_name: 'トロンボーン',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 55,
      main_category_id: 10,
      ja_name: 'チューバ',
      en_name: 'チューバ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 56,
      main_category_id: 10,
      ja_name: 'ユーフォニアム',
      en_name: 'ユーフォニアム',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 57,
      main_category_id: 10,
      ja_name: 'その他の管楽器',
      en_name: 'その他の管楽器',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 58,
      main_category_id: 11,
      ja_name: 'バイオリン',
      en_name: 'バイオリン',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 59,
      main_category_id: 11,
      ja_name: 'チェロ',
      en_name: 'チェロ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 60,
      main_category_id: 11,
      ja_name: 'ヴィオラ',
      en_name: 'ヴィオラ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 61,
      main_category_id: 11,
      ja_name: 'コントラブバス',
      en_name: 'コントラブバス',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 62,
      main_category_id: 11,
      ja_name: '弦楽アンサンブル',
      en_name: '弦楽アンサンブル',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 63,
      main_category_id: 11,
      ja_name: 'ハープ',
      en_name: 'ハープ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 64,
      main_category_id: 12,
      ja_name: 'フルスコア＋パート譜',
      en_name: 'フルスコア＋パート譜',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 65,
      main_category_id: 12,
      ja_name: 'フルスコア',
      en_name: 'フルスコア',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 66,
      main_category_id: 12,
      ja_name: 'パート譜',
      en_name: 'パート譜',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 67,
      main_category_id: 12,
      ja_name: 'ビッグバンド（スコア＋パート譜）',
      en_name: 'ビッグバンド（スコア＋パート譜）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 68,
      main_category_id: 12,
      ja_name: 'ビッグバンド（スコア）',
      en_name: 'ビッグバンド（スコア）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 69,
      main_category_id: 12,
      ja_name: 'ビッグバンド（パート譜）',
      en_name: 'ビッグバンド（パート譜）',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 70,
      main_category_id: 13,
      ja_name: 'マリンバ／シロフォン',
      en_name: 'マリンバ／シロフォン',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 71,
      main_category_id: 13,
      ja_name: 'ヴィブラフォン／グロッケンシュピール',
      en_name: 'ヴィブラフォン／グロッケンシュピール',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 72,
      main_category_id: 13,
      ja_name: 'その他のオーケストラルパーカッション',
      en_name: 'その他のオーケストラルパーカッション',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 73,
      main_category_id: 14,
      ja_name: 'フルスコア＋パート譜',
      en_name: 'フルスコア＋パート譜',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 74,
      main_category_id: 14,
      ja_name: 'フルスコア',
      en_name: 'フルスコア',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 75,
      main_category_id: 14,
      ja_name: 'パート譜',
      en_name: 'パート譜',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 76,
      main_category_id: 14,
      ja_name: '器楽合奏',
      en_name: '器楽合奏',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 77,
      main_category_id: 15,
      ja_name: 'フルスコア＋パート譜',
      en_name: 'フルスコア＋パート譜',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 78,
      main_category_id: 15,
      ja_name: 'フルスコア',
      en_name: 'フルスコア',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 79,
      main_category_id: 15,
      ja_name: 'パート譜',
      en_name: 'パート譜',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 80,
      main_category_id: 16,
      ja_name: '琴',
      en_name: '琴',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 81,
      main_category_id: 16,
      ja_name: '三味線',
      en_name: '三味線',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 82,
      main_category_id: 16,
      ja_name: '尺八',
      en_name: '尺八',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 83,
      main_category_id: 16,
      ja_name: '篠笛',
      en_name: '篠笛',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 84,
      main_category_id: 16,
      ja_name: 'その他鳴り物',
      en_name: 'その他鳴り物',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 85,
      main_category_id: 16,
      ja_name: '大正琴',
      en_name: '大正琴',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 86,
      main_category_id: 17,
      ja_name: 'ウクレレ',
      en_name: 'ウクレレ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 87,
      main_category_id: 17,
      ja_name: 'マンドリン',
      en_name: 'マンドリン',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 88,
      main_category_id: 17,
      ja_name: '三線',
      en_name: '三線',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 89,
      main_category_id: 17,
      ja_name: '二胡',
      en_name: '二胡',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 90,
      main_category_id: 17,
      ja_name: 'その他',
      en_name: 'その他',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 91,
      main_category_id: 18,
      ja_name: 'オカリナ',
      en_name: 'オカリナ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 92,
      main_category_id: 18,
      ja_name: 'リコーダー',
      en_name: 'リコーダー',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 93,
      main_category_id: 18,
      ja_name: 'ピアニカ',
      en_name: 'ピアニカ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 94,
      main_category_id: 18,
      ja_name: 'ハーモニカ／ブルースハープ',
      en_name: 'ハーモニカ／ブルースハープ',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 95,
      main_category_id: 18,
      ja_name: 'ミュージックベル',
      en_name: 'ミュージックベル',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 96,
      main_category_id: 18,
      ja_name: 'その他',
      en_name: 'その他',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 97,
      main_category_id: 19,
      ja_name: '教則・音楽理論',
      en_name: '教則・音楽理論',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
  ],
  music_genre_main_categories: [
    {
      id: 1,
      ja_name: '国内楽曲',
      en_name: '国内楽曲',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
    {
      id: 2,
      ja_name: '外国楽曲',
      en_name: '外国楽曲',
      created_at: '2022-11-16 16:50:00',
      updated_at: '2022-11-16 16:50:00',
    },
  ],
  music_genre_sub_categories: [
    {
      id: 1,
      main_category_id: 1,
      ja_name: 'クラシック',
      en_name: 'クラシック',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 2,
      main_category_id: 1,
      ja_name: 'ジャズ・フュージョン',
      en_name: 'ジャズ・フュージョン',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 3,
      main_category_id: 1,
      ja_name: 'J-POP・J-ROCK',
      en_name: 'J-POP・J-ROCK',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 4,
      main_category_id: 1,
      ja_name: '歌謡曲',
      en_name: '歌謡曲',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 5,
      main_category_id: 1,
      ja_name: '演歌・民謡',
      en_name: '演歌・民謡',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 6,
      main_category_id: 1,
      ja_name: '童謡・唱歌',
      en_name: '童謡・唱歌',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 7,
      main_category_id: 1,
      ja_name: '軍歌',
      en_name: '軍歌',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 8,
      main_category_id: 1,
      ja_name: '邦楽',
      en_name: '邦楽',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 9,
      main_category_id: 1,
      ja_name: '映画・TV・CM等',
      en_name: '映画・TV・CM等',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 10,
      main_category_id: 1,
      ja_name: 'その他',
      en_name: 'その他',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 11,
      main_category_id: 2,
      ja_name: 'クラシック',
      en_name: 'クラシック',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 12,
      main_category_id: 2,
      ja_name: 'ジャズ・フュージョン',
      en_name: 'ジャズ・フュージョン',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 13,
      main_category_id: 2,
      ja_name: 'POPS・ROCK',
      en_name: 'POPS・ROCK',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 14,
      main_category_id: 2,
      ja_name: 'K-POP・アジア',
      en_name: 'K-POP・アジア',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 15,
      main_category_id: 2,
      ja_name: 'ラテン・ワールド　',
      en_name: 'ラテン・ワールド　',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 16,
      main_category_id: 2,
      ja_name: '宗教・ゴスペル',
      en_name: '宗教・ゴスペル',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 17,
      main_category_id: 2,
      ja_name: '映画・TV・CM等',
      en_name: '映画・TV・CM等',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 18,
      main_category_id: 2,
      ja_name: 'その他',
      en_name: 'その他',
      created_at: '2022-11-16 16:50:01',
      updated_at: '2022-11-16 16:50:01',
    },
  ],
  ivt_types: [
    {
      id: 1,
      ja_name: '曲のみ利用',
      en_name: '曲のみ利用',
      created_at: '2022-11-09 16:18:16',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 2,
      ja_name: '詞曲とも利用',
      en_name: '詞曲とも利用',
      created_at: '2022-11-09 16:18:16',
      updated_at: '2022-11-16 16:50:01',
    },
    {
      id: 3,
      ja_name: '詞のみ利用',
      en_name: '詞のみ利用',
      created_at: '2022-11-09 16:18:16',
      updated_at: '2022-11-16 16:50:01',
    },
  ],
  translation_types: [
    {
      id: 1,
      ja_name: '原詞利用',
      en_name: '原詞利用',
      created_at: '2022-11-09 16:18:16',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 2,
      ja_name: '訳詞利用',
      en_name: '訳詞利用',
      created_at: '2022-11-09 16:18:16',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 3,
      ja_name: '不明',
      en_name: '不明',
      created_at: '2022-11-09 16:18:16',
      updated_at: '2022-11-16 16:50:02',
    },
  ],
  search_tags: [
    {
      id: 1,
      ja_name: '2台４手',
      en_name: '2台４手',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 2,
      ja_name: 'J-POP・J-ROCK',
      en_name: 'J-POP・J-ROCK',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 3,
      ja_name: 'K-POP・アジア',
      en_name: 'K-POP・アジア',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 4,
      ja_name: 'POPS・ROCK',
      en_name: 'POPS・ROCK',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 5,
      ja_name: 'TAB',
      en_name: 'TAB',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 6,
      ja_name: 'アカペラ',
      en_name: 'アカペラ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 7,
      ja_name: 'アコースティック',
      en_name: 'アコースティック',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 8,
      ja_name: 'アルトサックス',
      en_name: 'アルトサックス',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 9,
      ja_name: 'アンサンブル',
      en_name: 'アンサンブル',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 10,
      ja_name: 'ヴィオラ',
      en_name: 'ヴィオラ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 11,
      ja_name: 'ヴィブラフォン／グロッケンシュピール',
      en_name: 'ヴィブラフォン／グロッケンシュピール',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 12,
      ja_name: 'オーボエ',
      en_name: 'オーボエ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 13,
      ja_name: 'オカリナ',
      en_name: 'オカリナ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 14,
      ja_name: 'おとなの',
      en_name: 'おとなの',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 15,
      ja_name: 'オルガン＆ピアノ',
      en_name: 'オルガン＆ピアノ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 16,
      ja_name: 'キーボード／シンセサイザー',
      en_name: 'キーボード／シンセサイザー',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 17,
      ja_name: 'ギター',
      en_name: 'ギター',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 18,
      ja_name: 'ギター伴奏',
      en_name: 'ギター伴奏',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 19,
      ja_name: 'クラシック',
      en_name: 'クラシック',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 20,
      ja_name: 'クラリネット',
      en_name: 'クラリネット',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 21,
      ja_name: 'コード譜',
      en_name: 'コード譜',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 22,
      ja_name: 'ゴスペル',
      en_name: 'ゴスペル',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 23,
      ja_name: 'こどもの',
      en_name: 'こどもの',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 24,
      ja_name: 'コントラバス',
      en_name: 'コントラバス',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 25,
      ja_name: 'ジャズ・フュージョン',
      en_name: 'ジャズ・フュージョン',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 26,
      ja_name: 'その他',
      en_name: 'その他',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 27,
      ja_name: 'その他のオーケストラルパーカッション',
      en_name: 'その他のオーケストラルパーカッション',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 28,
      ja_name: 'その他の管楽器',
      en_name: 'その他の管楽器',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 29,
      ja_name: 'その他鳴り物',
      en_name: 'その他鳴り物',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 30,
      ja_name: 'ソプラノサックス',
      en_name: 'ソプラノサックス',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 31,
      ja_name: 'ソロ',
      en_name: 'ソロ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 32,
      ja_name: 'チェレスタ',
      en_name: 'チェレスタ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 33,
      ja_name: 'チェロ',
      en_name: 'チェロ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 34,
      ja_name: 'チェンバロ／ハープシコード',
      en_name: 'チェンバロ／ハープシコード',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 35,
      ja_name: 'チューバ',
      en_name: 'チューバ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 36,
      ja_name: 'テナーサックス',
      en_name: 'テナーサックス',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 37,
      ja_name: 'ドラムス／パーカッション',
      en_name: 'ドラムス／パーカッション',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 38,
      ja_name: 'トランペット',
      en_name: 'トランペット',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 39,
      ja_name: 'トロンボーン',
      en_name: 'トロンボーン',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 40,
      ja_name: 'パート譜',
      en_name: 'パート譜',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 41,
      ja_name: 'ハープ',
      en_name: 'ハープ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 42,
      ja_name: 'ハーモニカ／ブルースハープ',
      en_name: 'ハーモニカ／ブルースハープ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 43,
      ja_name: 'バイオリン',
      en_name: 'バイオリン',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 44,
      ja_name: 'はじめての',
      en_name: 'はじめての',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 45,
      ja_name: 'バリトンサックス',
      en_name: 'バリトンサックス',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 46,
      ja_name: 'バンドスコア',
      en_name: 'バンドスコア',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 47,
      ja_name: 'ピアニカ',
      en_name: 'ピアニカ',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 48,
      ja_name: 'ピアノ伴奏',
      en_name: 'ピアノ伴奏',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 49,
      ja_name: 'ビッグバンド（スコア）',
      en_name: 'ビッグバンド（スコア）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 50,
      ja_name: 'ビッグバンド（スコア＋パート譜）',
      en_name: 'ビッグバンド（スコア＋パート譜）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 51,
      ja_name: 'ビッグバンド（パート譜）',
      en_name: 'ビッグバンド（パート譜）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 52,
      ja_name: 'ファゴット',
      en_name: 'ファゴット',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 53,
      ja_name: 'フルート',
      en_name: 'フルート',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 54,
      ja_name: 'フルスコア',
      en_name: 'フルスコア',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 55,
      ja_name: 'フルスコア＋パート譜',
      en_name: 'フルスコア＋パート譜',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 56,
      ja_name: 'ベース',
      en_name: 'ベース',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 57,
      ja_name: 'ホルン',
      en_name: 'ホルン',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 58,
      ja_name: 'マリンバ／シロフォン',
      en_name: 'マリンバ／シロフォン',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 59,
      ja_name: 'ミュージックベル',
      en_name: 'ミュージックベル',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 60,
      ja_name: 'メロディ（リードシート）',
      en_name: 'メロディ（リードシート）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 61,
      ja_name: 'やさしい',
      en_name: 'やさしい',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 62,
      ja_name: 'ユーフォニアム',
      en_name: 'ユーフォニアム',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 63,
      ja_name: 'ラテン・ワールド',
      en_name: 'ラテン・ワールド',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 64,
      ja_name: 'リコーダー',
      en_name: 'リコーダー',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 65,
      ja_name: '映画・TV・CM等',
      en_name: '映画・TV・CM等',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 66,
      ja_name: '演歌・民謡',
      en_name: '演歌・民謡',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 67,
      ja_name: '歌詞＆コード',
      en_name: '歌詞＆コード',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 68,
      ja_name: '歌謡曲',
      en_name: '歌謡曲',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 69,
      ja_name: '外国楽曲',
      en_name: '外国楽曲',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 70,
      ja_name: '器楽合奏',
      en_name: '器楽合奏',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 71,
      ja_name: '教則・音楽理論',
      en_name: '教則・音楽理論',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 72,
      ja_name: '琴',
      en_name: '琴',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 73,
      ja_name: '軍歌',
      en_name: '軍歌',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 74,
      ja_name: '弦楽アンサンブル',
      en_name: '弦楽アンサンブル',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 75,
      ja_name: '合唱（その他）',
      en_name: '合唱（その他）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 76,
      ja_name: '合唱（混声3部）',
      en_name: '合唱（混声3部）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 77,
      ja_name: '合唱（混声4部）',
      en_name: '合唱（混声4部）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 78,
      ja_name: '合唱（混声6部）',
      en_name: '合唱（混声6部）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 79,
      ja_name: '合唱（児童合唱）',
      en_name: '合唱（児童合唱）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 80,
      ja_name: '合唱（女声3部）',
      en_name: '合唱（女声3部）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 81,
      ja_name: '合唱（女声4部）',
      en_name: '合唱（女声4部）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 82,
      ja_name: '合唱（男声4部）',
      en_name: '合唱（男声4部）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 83,
      ja_name: '合唱（男声合唱）',
      en_name: '合唱（男声合唱）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 84,
      ja_name: '合唱（同声2部）',
      en_name: '合唱（同声2部）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 85,
      ja_name: '合唱（同声3部）',
      en_name: '合唱（同声3部）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 86,
      ja_name: '合奏・伴奏',
      en_name: '合奏・伴奏',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 87,
      ja_name: '国内楽曲',
      en_name: '国内楽曲',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 88,
      ja_name: '三重奏（トリオ）',
      en_name: '三重奏（トリオ）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 89,
      ja_name: '三線',
      en_name: '三線',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 90,
      ja_name: '三味線',
      en_name: '三味線',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 91,
      ja_name: '四重奏（カルテット）',
      en_name: '四重奏（カルテット）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 92,
      ja_name: '篠笛',
      en_name: '篠笛',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 93,
      ja_name: '尺八',
      en_name: '尺八',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 94,
      ja_name: '宗教・ゴスペル',
      en_name: '宗教・ゴスペル',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 95,
      ja_name: '初～中級',
      en_name: '初～中級',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 96,
      ja_name: '初級',
      en_name: '初級',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 97,
      ja_name: '初心者',
      en_name: '初心者',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 98,
      ja_name: '上級',
      en_name: '上級',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 99,
      ja_name: '大正琴',
      en_name: '大正琴',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 100,
      ja_name: '弾き語り',
      en_name: '弾き語り',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 101,
      ja_name: '弾き語り（ピアノ＆ボーカル）',
      en_name: '弾き語り（ピアノ＆ボーカル）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 102,
      ja_name: '中～上級',
      en_name: '中～上級',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 103,
      ja_name: '中級',
      en_name: '中級',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 104,
      ja_name: '童謡・唱歌',
      en_name: '童謡・唱歌',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 105,
      ja_name: '独唱',
      en_name: '独唱',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 106,
      ja_name: '二胡',
      en_name: '二胡',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 107,
      ja_name: '二重奏（デュオ）',
      en_name: '二重奏（デュオ）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 108,
      ja_name: '入門',
      en_name: '入門',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 109,
      ja_name: '入門～初級',
      en_name: '入門～初級',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 110,
      ja_name: '邦楽',
      en_name: '邦楽',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 111,
      ja_name: '連弾',
      en_name: '連弾',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:02',
    },
    {
      id: 112,
      ja_name: 'なし',
      en_name: 'なし',
      created_at: '2022-11-16 16:50:02',
      updated_at: '2022-11-16 16:50:02',
    },
  ],
  copyright_managements: [
    {
      id: 1,
      ja_name: 'public domain（著作権無し）',
      en_name: 'public domain（著作権無し）',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 2,
      ja_name: '自己管理',
      en_name: '自己管理',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 3,
      ja_name: 'JASRAC',
      en_name: 'JASRAC',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 4,
      ja_name: 'NexTone',
      en_name: 'NexTone',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 5,
      ja_name: 'JASRAC及びNexTone',
      en_name: 'JASRAC及びNexTone',
      created_at: '2022-11-09 16:18:17',
      updated_at: '2022-11-16 16:50:03',
    },
  ],
  difficulty_levels: [
    {
      id: 1,
      ja_name: '入門',
      en_name: '入門',
      created_at: '2022-11-16 16:50:03',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 2,
      ja_name: '入門～初級',
      en_name: '入門～初級',
      created_at: '2022-11-16 16:50:03',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 3,
      ja_name: '初級',
      en_name: '初級',
      created_at: '2022-11-16 16:50:03',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 4,
      ja_name: '初～中級',
      en_name: '初～中級',
      created_at: '2022-11-16 16:50:03',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 5,
      ja_name: '中級',
      en_name: '中級',
      created_at: '2022-11-16 16:50:03',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 6,
      ja_name: '中～上級',
      en_name: '中～上級',
      created_at: '2022-11-16 16:50:03',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 7,
      ja_name: '上級',
      en_name: '上級',
      created_at: '2022-11-16 16:50:03',
      updated_at: '2022-11-16 16:50:03',
    },
    {
      id: 8,
      ja_name: 'なし',
      en_name: 'なし',
      created_at: '2022-11-16 16:50:03',
      updated_at: '2022-11-16 16:50:03',
    },
  ],
  content_types: {
    PDF: 0,
    MIDI: 1,
    AUDIO: 2,
    VIDEO: 3,
  },
  content_states: {
    PRIVATE: 0,
    PUBLISH: 1,
    LIMIT: 2,
  },
  license_code: {
    JASRAC_PDF_VIEW: '9026755001Y38026',
    JASRAC_PDF_DOWNLOAD: '9026755003Y37019',
    JASRAC_OTHER_TYPES_VIEW: '9026755002Y31015',
    NEXTONE_PDF_VIEW: 'ID000006880',
    NEXTONE_PDF_DOWNLOAD: 'ID000008859',
  }
};

export default CONST_DATA;
